<template>
  <div class="home">
    <MultiSkillReport :org="org"/>
  </div>
</template>

<script>
// @ is an alias to /src
import MultiSkillReport from '@/components/MultiSkillReport.vue'

export default {
  name: 'MultiSkillView',
  props: {
    org: {
      type: String,
      default: undefined
    }
  },
  components: {
    MultiSkillReport
  }
}
</script>
