<template>
  <div>
    <div style="position: fixed; clear: both; margin: 0; left: 0; top: 0; z-index: 999; font-size: 10px">Update 1.29.4</div>
    <div v-if="reportSelected!=null && flattenedArrayExport!==null && flattenedArrayExport.length>0">
      <div style="width:100%; background-color:#FFF; position: fixed; clear: both; margin: 0; left: 0; top: 0; z-index: 998;">
        <select style="font-size: 11px; padding: 2px" v-model="reportSelected">
          <option value="">Selecciona una fecha</option>
          <option v-for="reportes in reports" :key="reportes.id" >{{reportes}}</option>
          <!-- Agrega más opciones aquí para otros atributos -->
        </select>
        <button style="font-size: 11px; padding: 2px" id="exportButton" @click="exportAllReport()">Exportar Todo</button>
        <!--<button style="font-size: 11px; padding: 2px" id="exportButton" @click="exportAllReport2()">Exportar Todo 2</button>-->
        <!--<select style="font-size: 11px; padding: 2px" v-model="filterAttribute">
          <option value="">Selecciona un filtro</option>
          <option value="queue">Cola/Skill/Split</option>
          <option value="name">Nombre de Usuario</option>
          <option value="logid">Usuario NLSA</option>
        </select>

        <input style="font-size: 11px; padding: 2px" v-model="filterValue" placeholder="Valor de filtro">
        <button style="font-size: 11px; padding: 2px" id="prevPageBtn" @click="currentPage--" :disabled="currentPage <= 0">Anterior</button>
        <button style="font-size: 11px; padding: 2px" id="nextPageBtn" @click="currentPage++" :disabled="currentPage >= (filterDataMultiSkills.length / rowsPerPage) - 1">Siguiente</button>
        -->
      </div>
      
    </div>
    <div v-else>
      <h4 class="pt-4">{{msg}}</h4>
    <div v-if="reportSelected==null || reportSelected==''">
      <select style="font-size: 11px; padding: 2px" v-model="reportSelected">
          <option value="">Selecciona una fecha</option>
          <option v-for="reportes in reports" :key="reportes.id" >{{reportes}}</option>
          <!-- Agrega más opciones aquí para otros atributos -->
        </select>

    </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
//import moment, { max } from 'moment'
export default {
  name: 'MultiSkillReport',
  props: {
    org: {
      type: String,
      default: undefined
    }
  },
  data(){
    return {
      //route: 'https://bsglobalservices.com/',
      route: 'https://connector.esmtcx.solutions/',
      //route: 'http://connector.esmtcx.solutions.test:81/',
      routeSub: 'multiskillsConsult.php?',
      msg:'Seleccionar una fuente para proceder con la carga...',
      msgOrg:'',
      filterAttribute: '',
      filterValue: '',
      bpi: null,
      allPresences:null,
      reports:[],
      reportSelected:null,
      allUsers:null,
      allUsersWithAuditEvent :null,
      allQueueWithMembers:null,
      allUserPresences:null,
      allUserPresencesOriginal: null,
      allUserAggregatesPresences:null,
      allUserConversationsAggregates:null,
      allUserConversations:null,
      allAuditAddMembers:null,
      allAuditRemoveMembers:null, 
      flattenedArray: [],
      flattenedArrayExport: [],
      currentPage: 0,
      rowsPerPage: 500,
      maxPages: 0,
      sourceGenerals:'',
      sourceConversations:'',
      sourceUserPresences:'',
      sourceConversationAggregates:''
    }
  },
  computed:{
    filteredData() {
      let data = this.flattenedArray;

      // Filtrar los datos si se ha seleccionado un atributo y un valor de filtro
      if (this.filterAttribute && this.filterValue) {
        const filterValueLowercase = this.filterValue.toLowerCase();
        data = data.filter(item => String(item[this.filterAttribute]).toLowerCase().includes(filterValueLowercase));
      }

      // Paginar los datos
      const start = this.currentPage * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return data.slice(start, end);
    },

    calculateAdjustedTime(metrics, presenceId, interceptedTimes) {
      // Calcular el tiempo total sumando todas las métricas de tOrganizationPresence para el presenceId dado
      const totalTime = metrics.reduce((total, metric) => {
        const sumAuxTime = metric.metric === 'tOrganizationPresence' && metric.qualifier === presenceId ? metric.stats?.sum || 0 : 0;
        return total + sumAuxTime;
      }, 0);

      // Buscar el tiempo interceptado correspondiente a este presenceId en interceptedTimes
      const interceptedTimeObject = interceptedTimes.find(item => item.organizationPresenceId === presenceId);
      const interceptedTime = interceptedTimeObject ? interceptedTimeObject.totalIntersectedTime : 0;

      // Restar el tiempo interceptado del tiempo total y convertir a segundos
      const adjustedTime = (totalTime - interceptedTime) / 1000;

      // Asegurarse de que el tiempo ajustado no sea negativo
      const positiveAdjustedTime = Math.max(0, adjustedTime);

      // Retornar el tiempo ajustado en formato de número con tres decimales
      return positiveAdjustedTime.toFixed(3);
    },

    filterDataMultiSkills(){
      return this.flattenedArray
    },
    /*
    {{
              //parseFloat(presenciasDeUsuario.countAllAuxtime).toFixed(3)
              addDataToExport(index, 'ti_auxtime', parseFloat(presenciasDeUsuario.countAllAuxtime-Math.abs(presenciasDeUsuario.z_available_negative_time)).toFixed(3))

            }}
          </small>
          <small v-else-if="presenciasDeUsuario.queue=='' && (presenciasDeUsuario.z_available_negative_time<0)">
            {{
              //parseFloat(presenciasDeUsuario.countAllAuxtime-Math.abs(presenciasDeUsuario.z_available_negative_time)).toFixed(3)
              addDataToExport(index, 'ti_auxtime', parseFloat(presenciasDeUsuario.countAllAuxtime-Math.abs(presenciasDeUsuario.z_available_negative_time)).toFixed(3))
            }}
    */
    /*formattedAuxTime() {
      // Devuelve una función
      return (presencia, index) => {
        let auxTime = parseFloat(presencia.countAllAuxtime).toFixed(3);
        this.addDataToExport(index, 'ti_auxtime', auxTime);
        return auxTime;
      }
    },

    formattedAuxTime2() {
      // Devuelve una función
      return (presencia, index) => {
        let auxTime = parseFloat(presencia.countAllAuxtime-Math.abs(presencia.z_available_negative_time)).toFixed(3);
        this.addDataToExport(index, 'ti_auxtime', auxTime);
        return auxTime;
      }
    },*/

    /*iAvailableTimeComputed(){
      return (presencia, index) => {
        let iAvailableTime = parseFloat(
                    (presencia.countAllAvailableTime)-
                    (presencia.i_acdtime || 0)-
                    (presencia.i_acwtime || 0)-
                    (presencia.ringtime || 0)-
                    (presencia.holdtime || 0)-
                    (presencia.i_othertime_acdtime+
                      presencia.i_othertime_acwtime+
                      presencia.i_othertime_ringtime+
                      presencia.i_othertime_holdtime) >= 0 ?
                    (presencia.countAllAvailableTime)-
                    (presencia.i_acdtime || 0)-
                    (presencia.i_acwtime || 0)-
                    (presencia.ringtime || 0)-
                    (presencia.holdtime || 0)-
                    (presencia.i_othertime_acdtime+
                      presencia.i_othertime_acwtime+
                      presencia.i_othertime_ringtime+
                      presencia.i_othertime_holdtime) : 0
                      ).toFixed(3)

        this.addDataToExport(index, 'i_availtime', iAvailableTime);
        return iAvailableTime;
      }
    },*/

    TiAvailableTimeComputed(){
      return (presencia, index) =>{
          let TiAvailableTime = parseFloat(
                    this.getMinAvailableTime(presencia.userId, presencia.interval) < 0 ? 
                    0 : 
                    this.getMinAvailableTime(presencia.userId, presencia.interval)
          ).toFixed(3)

          this.addDataToExport(index, 'ti_availtime', TiAvailableTime);
          return TiAvailableTime;
      }
    }
  },
  watch: {
    /*flattenedArray: {
      handler: function (val) {
        if (val && val.length > 0) {
          this.getAllUserConversations()
        }
      },
      deep: true
    }*/
    allUserConversationsAggregates: {
      handler: function() {
        if (this.allUserConversationsAggregates) {
          
          this.getAllUserActivityPresences();
        }
      },
      deep: true
    },

    reportSelected: {
      handler: function() {
        if (this.reportSelected!=null && this.reportSelected!=='') {
          console.log('Buscando reporte del dia: '+this.reportSelected+'...')
          this.msg = 'Cargando reporte de '+this.msgOrg+' - Fecha: '+this.reportSelected+'.'
          this.allUsers = null
          this.allQueueWithMembers = null
          this.allUserPresences = null
          this.allUserPresencesOriginal = null
          this.allUserAggregatesPresences = null
          this.allUserConversationsAggregates = null
          this.allUserConversations = null
          this.allAuditAddMembers = null
          this.allAuditRemoveMembers = null
          this.allAuditUpdateMembers = null
          this.flattenedArray =  []
          this.flattenedArrayExport =  []
          if(this.org==='cc'){
            this.sourceGenerals= this.route+this.routeSub+'id=n170m4dtbh6agv28&file=general&pref2=CC&pref=cc&range='+this.reportSelected
            this.sourceConversations= this.route+this.routeSub+'id=x3450tjged8hkmry&file=conversations&pref2=CC&pref=cc&range='+this.reportSelected
            this.sourceUserPresences= this.route+this.routeSub+'id=pwe6z90iacnlvqhr&file=presencias&pref2=CC&pref=cc&range='+this.reportSelected
            this.sourceConversationAggregates= this.route+this.routeSub+'id=jktru2idpxcy9n15&file=conversationAggregates&pref2=CC&pref=cc&range='+this.reportSelected
          }else if(this.org==='ac'){
            this.sourceGenerals= this.route+this.routeSub+'id=n8mgef60h4vcksji&file=general&pref2=AC&pref=ac&range='+this.reportSelected
            this.sourceConversations= this.route+this.routeSub+'id=jibulposd96tf32y&file=conversations&pref2=AC&pref=ac&range='+this.reportSelected
            this.sourceUserPresences= this.route+this.routeSub+'id=3phv840l51fsudnq&file=presencias&pref2=AC&pref=ac&range='+this.reportSelected
            this.sourceConversationAggregates= this.route+this.routeSub+'id=laiqe2o5w1npducv&file=conversationAggregates&pref2=AC&pref=ac&range='+this.reportSelected
          }
          
          this.getAllGenerals();
        }
      },
      deep: true
    }
  },
  methods: {
    addDataToExport(index, metric, value){
      this.flattenedArrayExport[index][metric] = value;
    },
    exportAllReport(){
      console.log('entramos vista')
      // Crear el encabezado del CSV a partir de las claves del primer objeto
      const replacer = (key, value) => value === null ? '' : value;
      const header = Object.keys(this.flattenedArrayExport[0]);
      let csv = this.flattenedArrayExport.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
      csv.unshift(header.join(','));
      let csvArray = csv.join('\r\n');

      // Crear un blob a partir del string CSV
      var blob = new Blob([csvArray], {type: 'text/csv'});

      // Crear un enlace para descargar el blob
      var a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = this.org+'_'+this.reportSelected+'.csv';

      // Añadir el enlace al DOM y hacer clic en él, luego eliminarlo
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    exportAllReport2(){
      console.log('entramos vista')
      // Crear el encabezado del CSV a partir de las claves del primer objeto
      const replacer = (key, value) => value === null ? '' : value;
      const header = Object.keys(this.flattenedArray[0]);
      let csv = this.flattenedArray.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
      csv.unshift(header.join(','));
      let csvArray = csv.join('\r\n');

      // Crear un blob a partir del string CSV
      var blob = new Blob([csvArray], {type: 'text/csv'});

      // Crear un enlace para descargar el blob
      var a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = 'export.csv';

      // Añadir el enlace al DOM y hacer clic en él, luego eliminarlo
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    customRound(number) {
        // Obtener la parte decimal del número
        let decimalPart = number - Math.floor(number);

        // Verificar si la parte decimal es menor que 0.5
        if (decimalPart < 0.5) {
            // Redondear hacia abajo
            return Math.floor(number);
        } else {
            // Redondear hacia arriba
            return Math.ceil(number);
        }
    },

    sayHello() {
      alert('Hello!')
    },

    returnUserInfo(userId){
      let user = this.allUsers.find(user => user.id === userId)
      return user
    },

    /*findUserInQueues(userId) {
      return this.allQueueWithMembers.map(queue => {
        const user = queue.dataMembers.find(member => member.id === userId);
        if (user) {
          return { queueId: queue.id, queueName: queue.name, division: queue.division.name };
        }
      }).filter(Boolean); // Filtrar los elementos undefined
    },*/

    findUserInQueues(userId) {
      return this.allQueueWithMembers.reduce((acc, queue) => {
        // Filtrar todos los miembros que coincidan con el userId
        const users = queue.dataMembers.filter(member => member.id === userId || member.user?.id === userId);

        if (users.length > 0) {
          // Consolidar todos los auditEvents en un solo array
          const consolidatedAuditEvents = users.reduce((eventsAcc, user) => {
            return eventsAcc.concat(user.auditEvents || []); // Agregar los auditEvents de cada aparición
          }, []);

          // Añadir el resultado consolidado al acumulador
          acc.push({
            queueId: queue.id,
            queueName: queue.name,
            division: queue.division?.name || '', // Manejar posibles undefined en division
            auditEvents: consolidatedAuditEvents, // AuditEvents consolidados
            member: users[0] // Asumimos que tomamos el primer miembro como referencia, ya que son el mismo usuario
          });
        }

        return acc;
      }, []); // Inicializar el acumulador como un array vacío
    },

    addAuditEventsInQueues(userId) {
      return this.allQueueWithMembers.map(queue => {
        const user = queue.dataMembers.find(member => member.id === userId);
        if (user) {
          return { queueId: queue.id, queueName: queue.name, division: queue.division.name };
        }
      }).filter(Boolean); // Filtrar los elementos undefined
    },

    async getAllUsersPresences() {
        try {
          const response = await axios.get(this.sourceUserPresences);
          //console.log('//All General');
          //console.log(response.data.data[0].data.userDetails);
          // Aplanar el array basándose en primaryPresence
          
          this.allUserPresencesOriginal = response.data.data[0].data.userDetails
          this.allUserPresences = this.allUserPresencesOriginal.flatMap(userDetail => 
            (userDetail.primaryPresence || []).map(presence => ({ // Asegurarse de que primaryPresence es un array
              ...presence,
              userId: userDetail.userId, // Asumiendo que quieres mantener una referencia al userId en cada presencia
              userName: userDetail.userName, // Asumiendo que quieres mantener una referencia al userId en cada presencia
              userEmail: userDetail.userEmail, // Asumiendo que quieres mantener una referencia al userId en cada presencia
              orgId: userDetail.orgId, // Asumiendo que quieres mantener una referencia al userId en cada presencia
              userDivisionId: userDetail.userDivisionId,// Asumiendo que quieres mantener una referencia al userId en cada presencia
              userDivisionName: userDetail.userDivisionName // Asumiendo que quieres mantener una referencia al userId en cada presencia
            }))
          );
        } catch (error) {
          console.error(error);
        }
    },


    async getAllGenerals() {
  try {
    // Asegurarse de que this.getAllUsersPresences() se ejecute y complete antes de continuar
    await this.getAllUsersPresences();

    const response = await axios.get(this.sourceGenerals);


    console.log('Recuperando información general');
    console.log(response.data);
    console.log('Recuperando información general');

    this.allPresences = response.data.data[0].presences;
    this.allUsers = response.data.data[0].users;
    this.allQueueWithMembers = response.data.data[0].queues;
    this.allUserAggregatesPresences = response.data.data[0].data;
    this.allAuditAddMembers = response.data.data[0].auditAdd;
    this.allAuditRemoveMembers = response.data.data[0].auditRemove;
    this.allAuditUpdateMembers = response.data.data[0].auditUpdate;

    /*this.allAuditAddMembers.entities = this.allAuditAddMembers.entities?.map(entity => {
      const eventDate = new Date(entity?.eventDate);
      const minutes = eventDate.getMinutes();
      const segmentMinutes = Math.floor(minutes / 30) * 30;
      const eventDateSegment = new Date(eventDate.setMinutes(segmentMinutes)).toISOString();

      return { ...entity, eventDateSegment };
    });

    this.allAuditRemoveMembers.entities = this.allAuditRemoveMembers.entities?.map(entity => {
      const eventDate = new Date(entity?.eventDate);
      const minutes = eventDate.getMinutes();
      const segmentMinutes = Math.floor(minutes / 30) * 30;
      const eventDateSegment = new Date(eventDate.setMinutes(segmentMinutes)).toISOString();

      return { ...entity, eventDateSegment };
    });

    this.allAuditUpdateMembers.entities = this.allAuditUpdateMembers.entities?.map(entity => {
      const eventDate = new Date(entity?.eventDate);
      const minutes = eventDate.getMinutes();
      const segmentMinutes = Math.floor(minutes / 30) * 30;
      const eventDateSegment = new Date(eventDate.setMinutes(segmentMinutes)).toISOString();

      return { ...entity, eventDateSegment };
    });


    this.allUsers = this.allUsers.map(user=>{
        const memberAdd = this.allAuditAddMembers?.entities?.filter(entity => entity?.user?.id === user?.id)
          .map(({ eventDate, eventDateSegment, context, user, action, entity }) => ({ eventDate, eventDateSegment, context, user, action, entity }));

        const memberRemove = this.allAuditRemoveMembers?.entities?.filter(entity => entity?.user?.id === user?.id)
          .map(({ eventDate, eventDateSegment, context, user, action, entity }) => ({ eventDate, eventDateSegment, context, user, action, entity }));

        const memberUpdate = this.allAuditRemoveUpdate?.entities?.filter(entity => entity?.user?.id === user?.id)
          .map(({ eventDate, eventDateSegment, context, user, action, entity }) => ({ eventDate, eventDateSegment, context, user, action, entity }));

        return { ...user, memberAdd, memberRemove, memberUpdate };
    })

    this.allUsersWithAuditEvent = this.allUsers.filter(user=>user.memberAdd.length>0 || user.memberRemove.length>0)*/

    /*this.allQueueWithMembers = this.allQueueWithMembers.map(queue => {
      queue.dataMembers = queue.dataMembers?.map(member => {
        const memberAdd = this.allAuditAddMembers?.entities?.filter(entity => entity?.entity?.id === queue.id && (entity?.user?.id === entity?.user?.id === member['id']))
          .map(({ eventDate, eventDateSegment, context, user, action, entity }) => ({ eventDate, eventDateSegment, context, user, action, entity }));

        const memberRemove = this.allAuditRemoveMembers?.entities?.filter(entity => entity?.entity?.id === queue.id && entity?.user?.id === member['id'])
          .map(({ eventDate, eventDateSegment, context, user, action, entity }) => ({ eventDate, eventDateSegment, context, user, action, entity }));

        return { ...member, memberAdd, memberRemove };
      });

      return queue;
    });*/

    this.getAllUserConversations();
  } catch (error) {
    console.error(error);
  }
},

    /*splitInto30MinSegments(presence) {
      const start = moment(presence.startTime);
      const end = moment(presence.endTime);
      const timeSegments = {};

      while(start < end) {
        const segmentStart = start.clone().startOf('hour').add(Math.floor(start.minute() / 30) * 30, 'minutes');
        const segmentEnd = segmentStart.clone().add(30, 'minutes');
        const overlapStart = moment.max(segmentStart, start);
        const overlapEnd = moment.min(segmentEnd, end);
        const duration = moment.duration(overlapEnd.diff(overlapStart));
        const key = `${segmentStart.format('HHmm')}_${segmentEnd.format('HHmm')}`;

        if (timeSegments[key]) {
          timeSegments[key].add(duration);
        } else {
          timeSegments[key] = duration;
        }

        start.add(30, 'minutes');
      }

      // Convert durations to strings
      for (const key in timeSegments) {
        timeSegments[key] = `${Math.floor(timeSegments[key].asHours())}:${timeSegments[key].minutes().toString().padStart(2, '0')}`;
      }

      return timeSegments;
    },*/

    async getAllUserConversations(){
      //https://connector.esmtcx.solutions/custom/c/8z0ibacqv57t26js/8z0ibacqv57t26js_data.json
      console.log('Buscando conversaciones de los agentes')
      try {
        const response = await axios.get(this.sourceConversations)
        //const response = await axios.get('https://connector.esmtcx.solutions/custom/c/8z0ibacqv57t26js/?init=2024-05-16&end=2024-05-16')
        //console.log(response.data.data[0].data)
        this.allUserConversations = response.data.data[0].data

        this.getAllUserConversationsAggregates()

        
      } catch (error) {
        console.error(error)
      }

      //console.log(this.flattenedArray)
    },

    /*
    Old version
    getConversationsPerUsersQueueAndInterval(userId, queueId=null, interval, direction=null){

      
      ////// Busqueda por usuario e intervalo unicamente
      if(userId!==null && interval!==null && queueId==null && direction==null){
        const filteredConversations = this.allUserConversationsAggregates.filter(conversation => 
          conversation.userId === userId && conversation.interval == interval
        )

        const conversationsWithDetails = filteredConversations.map(conversation => {
            const details = this.getConversationDetail(conversation.conversationId);
            return { ...conversation, details };
        });

        return conversationsWithDetails;
      }

      ////// Busqueda por usuario, cola e intervalo
      if(userId!==null && interval!==null && queueId!==null && direction==null){
        const filteredConversations = this.allUserConversationsAggregates.filter(conversation => 
          conversation.userId === userId && conversation.queueId == queueId && conversation.interval == interval
        )

        const conversationsWithDetails = filteredConversations.map(conversation => {
            const details = this.getConversationDetail(conversation.conversationId);
            return { ...conversation, details };
        });

        return conversationsWithDetails;
      }

      ////// Busqueda por usuario, intervalo  y direccion
      if(userId!==null && interval!==null && queueId==null && direction!==null){
        const filteredConversations = this.allUserConversationsAggregates.filter(conversation => 
          conversation.userId === userId && conversation.direction == direction && conversation.interval == interval
        )

        const conversationsWithDetails = filteredConversations.map(conversation => {
            const details = this.getConversationDetail(conversation.conversationId);
            return { ...conversation, details };
        });

        return conversationsWithDetails;
      }

      ////// Busqueda por todo
      const filteredConversations = this.allUserConversationsAggregates.filter(conversation => 
        conversation.userId === userId && conversation.queueId == queueId && conversation.interval == interval && conversation.direction == direction
      )

      const conversationsWithDetails = filteredConversations.map(conversation => {
            const details = this.getConversationDetail(conversation.conversationId);
            return { ...conversation, details };
        });

        return conversationsWithDetails;

    },*/
    /*getConversationsPerUsersQueueAndIntervalOTHER(userId, queueId, interval){
      const filteredConversations = this.allUserConversationsAggregates.filter(conversation => {
        return (
          conversation.userId === userId &&
          conversation.interval === interval &&
          conversation.queueId !== undefined && conversation.queueId !== null && conversation.queueId !== queueId
        );
      });

      const presenceDetails = this.getPresenceDetailsForInterval(userId, interval);

      // Calcular el tiempo de intersección para cada presencia
      presenceDetails.forEach(presence => {
        let totalIntersectedTime = 0;

        filteredConversations.forEach(conversation => {
          const details = this.getConversationDetail(conversation.conversationId);
          const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
          const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];

          if (lastSession) {
            lastSession.segments.forEach(segment => {
              const segmentStart = new Date(segment.segmentStart);
              const segmentEnd = new Date(segment.segmentEnd);
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);

              // Calcular la intersección entre el segmento y la presencia
              const maxStart = new Date(Math.max(segmentStart.getTime(), presenceStart.getTime()));
              const minEnd = new Date(Math.min(segmentEnd.getTime(), presenceEnd.getTime()));

              if (minEnd > maxStart) {
                totalIntersectedTime += minEnd - maxStart;
              }
            });
          }
        });

        // Agregar la métrica de tiempo total interceptado en milisegundos
        presence.intersectedTimeWithConversations = totalIntersectedTime;
      });

      const conversationsWithDetails = filteredConversations.map(conversation => {
        const details = this.getConversationDetail(conversation.conversationId);
        const metricsCopy = [...conversation.metrics];
        
        const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
        const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];
        const interactSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'interact');
        const wrapUpSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'wrapup');
        const alertSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'alert');
        const holdSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'hold');

        const calculateSegmentTimeInPresence = (segments, presenceDetails) => {
          let totalOnQueueTime = 0;
          let totalAuxTime = 0;

          segments?.forEach(segment => {
            const segmentStart = new Date(segment.segmentStart);
            const segmentEnd = new Date(segment.segmentEnd);
            const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
            const maxStartSegment = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
            const minEndSegment = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));

            presenceDetails.forEach(presence => {
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);
              const maxStartPresence = new Date(Math.max(presenceStart.getTime(), maxStartSegment.getTime()));
              const minEndPresence = new Date(Math.min(presenceEnd.getTime(), minEndSegment.getTime()));

              if (minEndPresence > maxStartPresence) {
                const intersectTime = minEndPresence - maxStartPresence;
                if (presence.systemPresence === 'ON_QUEUE') {
                  totalOnQueueTime += intersectTime;
                } else {
                  totalAuxTime += intersectTime;
                }
              }
            });
          });

          return { totalOnQueueTime, totalAuxTime };
        };

        let totalDuration = 0;
        let totalAcw = 0;
        let totalAlert = 0;
        let totalHold = 0;

        const interactTimes = calculateSegmentTimeInPresence(interactSegments, presenceDetails);
        totalDuration += interactSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const wrapUpTimes = calculateSegmentTimeInPresence(wrapUpSegments, presenceDetails);
        totalAcw += wrapUpSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const alertTimes = calculateSegmentTimeInPresence(alertSegments, presenceDetails);
        totalAlert += alertSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const holdTimes = calculateSegmentTimeInPresence(holdSegments, presenceDetails);
        totalHold += holdSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        // Añadir nuevas métricas calculadas a metricsCopy
        if (totalDuration > 0) {
          metricsCopy.push({ metric: 'tTalk', correct: true, stats: { max: totalDuration, min: totalDuration, count: 1, sum: totalDuration }, onQueue: interactTimes.totalOnQueueTime, aux: interactTimes.totalAuxTime });
        }
        if (totalAcw > 0) {
          metricsCopy.push({ metric: 'tAcw', correct: true, stats: { max: totalAcw, min: totalAcw, count: 1, sum: totalAcw }, onQueue: wrapUpTimes.totalOnQueueTime, aux: wrapUpTimes.totalAuxTime });
        }
        if (totalAlert > 0) {
          metricsCopy.push({ metric: 'tAlert', correct: true, stats: { max: totalAlert, min: totalAlert, count: 1, sum: totalAlert }, onQueue: alertTimes.totalOnQueueTime, aux: alertTimes.totalAuxTime });
        }
        if (totalHold > 0) {
          metricsCopy.push({ metric: 'tHold', correct: true, stats: { max: totalHold, min: totalHold, count: 1, sum: totalHold }, onQueue: holdTimes.totalOnQueueTime, aux: holdTimes.totalAuxTime });
        }

        // Gestión de la métrica endedConversacion
        const lastSegment = lastSession?.segments?.[lastSession?.segments?.length - 1];
        if (lastSegment) {
          const segmentEnd = new Date(lastSegment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));

          // Validar si segmentEnd del último segmento se encuentra dentro del intervalo de consulta
          if (segmentEnd >= intervalStart && segmentEnd <= intervalEnd) {
            // Crear la nueva métrica endedConversacion con el valor de segmentEnd
            metricsCopy.push({ metric: 'endedConversacion', correct: true, value: lastSegment.segmentEnd });
          }
        }

        // Asegurarse de devolver la conversación con la copia modificada de metrics
        return { ...conversation, metrics: metricsCopy, details, userPresences: presenceDetails };
      });

      return conversationsWithDetails;
    },

    getConversationsPerUsersQueueAndIntervalOTHER(userId, queueId, interval){
      const filteredConversations = this.allUserConversationsAggregates.filter(conversation => {
        return (
          conversation.userId === userId &&
          conversation.interval === interval &&
          conversation.queueId !== undefined && conversation.queueId !== null && conversation.queueId !== queueId
        );
      });

      const presenceDetails = this.getPresenceDetailsForInterval(userId, interval);

      // Calcular el tiempo de intersección para cada presencia
      presenceDetails.forEach(presence => {
        let totalIntersectedTime = 0;

        filteredConversations.forEach(conversation => {
          const details = this.getConversationDetail(conversation.conversationId);
          const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
          const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];

          if (lastSession) {
            lastSession.segments.forEach(segment => {
              const segmentStart = new Date(segment.segmentStart);
              const segmentEnd = new Date(segment.segmentEnd);
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);

              // Calcular la intersección entre el segmento y la presencia
              const maxStart = new Date(Math.max(segmentStart.getTime(), presenceStart.getTime()));
              const minEnd = new Date(Math.min(segmentEnd.getTime(), presenceEnd.getTime()));

              if (minEnd > maxStart) {
                totalIntersectedTime += minEnd - maxStart;
              }
            });
          }
        });

        // Agregar la métrica de tiempo total interceptado en milisegundos
        presence.intersectedTimeWithConversations = totalIntersectedTime;
      });

      const conversationsWithDetails = filteredConversations.map(conversation => {
        const details = this.getConversationDetail(conversation.conversationId);
        const metricsCopy = [...conversation.metrics];
        
        const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
        const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];
        const interactSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'interact');
        const wrapUpSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'wrapup');
        const alertSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'alert');
        const holdSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'hold');

        const calculateSegmentTimeInPresence = (segments, presenceDetails) => {
          let totalOnQueueTime = 0;
          let totalAuxTime = 0;

          segments?.forEach(segment => {
            const segmentStart = new Date(segment.segmentStart);
            const segmentEnd = new Date(segment.segmentEnd);
            const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
            const maxStartSegment = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
            const minEndSegment = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));

            presenceDetails.forEach(presence => {
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);
              const maxStartPresence = new Date(Math.max(presenceStart.getTime(), maxStartSegment.getTime()));
              const minEndPresence = new Date(Math.min(presenceEnd.getTime(), minEndSegment.getTime()));

              if (minEndPresence > maxStartPresence) {
                const intersectTime = minEndPresence - maxStartPresence;
                if (presence.systemPresence === 'ON_QUEUE') {
                  totalOnQueueTime += intersectTime;
                } else {
                  totalAuxTime += intersectTime;
                }
              }
            });
          });

          return { totalOnQueueTime, totalAuxTime };
        };

        let totalDuration = 0;
        let totalAcw = 0;
        let totalAlert = 0;
        let totalHold = 0;

        const interactTimes = calculateSegmentTimeInPresence(interactSegments, presenceDetails);
        totalDuration += interactSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const wrapUpTimes = calculateSegmentTimeInPresence(wrapUpSegments, presenceDetails);
        totalAcw += wrapUpSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const alertTimes = calculateSegmentTimeInPresence(alertSegments, presenceDetails);
        totalAlert += alertSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const holdTimes = calculateSegmentTimeInPresence(holdSegments, presenceDetails);
        totalHold += holdSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        // Añadir nuevas métricas calculadas a metricsCopy
        if (totalDuration > 0) {
          metricsCopy.push({ metric: 'tTalk', correct: true, stats: { max: totalDuration, min: totalDuration, count: 1, sum: totalDuration }, onQueue: interactTimes.totalOnQueueTime, aux: interactTimes.totalAuxTime });
        }
        if (totalAcw > 0) {
          metricsCopy.push({ metric: 'tAcw', correct: true, stats: { max: totalAcw, min: totalAcw, count: 1, sum: totalAcw }, onQueue: wrapUpTimes.totalOnQueueTime, aux: wrapUpTimes.totalAuxTime });
        }
        if (totalAlert > 0) {
          metricsCopy.push({ metric: 'tAlert', correct: true, stats: { max: totalAlert, min: totalAlert, count: 1, sum: totalAlert }, onQueue: alertTimes.totalOnQueueTime, aux: alertTimes.totalAuxTime });
        }
        if (totalHold > 0) {
          metricsCopy.push({ metric: 'tHold', correct: true, stats: { max: totalHold, min: totalHold, count: 1, sum: totalHold }, onQueue: holdTimes.totalOnQueueTime, aux: holdTimes.totalAuxTime });
        }

        // Gestión de la métrica endedConversacion
        const lastSegment = lastSession?.segments?.[lastSession?.segments?.length - 1];
        if (lastSegment) {
          const segmentEnd = new Date(lastSegment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));

          // Validar si segmentEnd del último segmento se encuentra dentro del intervalo de consulta
          if (segmentEnd >= intervalStart && segmentEnd <= intervalEnd) {
            // Calcular los tiempos totales de los segmentos sin considerar la intersección con el intervalo
            const totalInteractTime = interactSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);
            const totalWrapUpTime = wrapUpSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);
            const totalAlertTime = alertSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);
            const totalHoldTime = holdSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);

            // Crear la nueva métrica endedConversacion con el valor de segmentEnd y los tiempos totales de cada métrica
            metricsCopy.push({
              metric: 'endedConversacion',
              correct: true,
              value: lastSegment.segmentEnd,
              totals: {
                tTalk: totalInteractTime,
                tAcw: totalWrapUpTime,
                tAlert: totalAlertTime,
                tHold: totalHoldTime
              }
            });
          }
        }

        // Asegurarse de devolver la conversación con la copia modificada de metrics
        return { ...conversation, metrics: metricsCopy, details, userPresences: presenceDetails };
      });

      return conversationsWithDetails;
    },*/

    getConversationsPerUsersQueueAndIntervalOTHER(userId, queueId, interval){
      const filteredConversations = this.allUserConversationsAggregates.filter(conversation => {
        return (
          conversation.userId === userId &&
          conversation.interval === interval &&
          conversation.queueId !== undefined && conversation.queueId !== null && conversation.queueId !== queueId
        );
      });

      const presenceDetails = this.getPresenceDetailsForInterval(userId, interval);

      // Calcular el tiempo de intersección para cada presencia
      presenceDetails.forEach(presence => {
          let totalIntersectedTime = 0;

          filteredConversations.forEach(conversation => {
              const details = this.getConversationDetail(conversation.conversationId);
              const participants = details?.participants?.filter(participant => participant.purpose === 'agent' && participant.userId === userId);

              participants?.forEach(agentParticipant => {
                  const lastSession = agentParticipant?.sessions[agentParticipant?.sessions.length - 1];

                  if (lastSession) {
                      lastSession.segments.forEach(segment => {
                          const segmentStart = new Date(segment.segmentStart);
                          const segmentEnd = new Date(segment.segmentEnd);
                          const presenceStart = new Date(presence.startTime);
                          const presenceEnd = new Date(presence.endTime);

                          // Calcular la intersección entre el segmento y la presencia
                          const maxStart = new Date(Math.max(segmentStart.getTime(), presenceStart.getTime()));
                          const minEnd = new Date(Math.min(segmentEnd.getTime(), presenceEnd.getTime()));

                          if (minEnd > maxStart) {
                              totalIntersectedTime += minEnd - maxStart;
                          }
                      });
                  }
              });
          });

          // Agregar la métrica de tiempo total interceptado en milisegundos
          presence.intersectedTimeWithConversations = totalIntersectedTime;
      });

      const conversationsWithDetails = filteredConversations.map(conversation => {
        const details = this.getConversationDetail(conversation.conversationId);
        const metricsCopy = [...conversation.metrics];
        
        const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
        
        const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];
        const interactSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'interact');
        const wrapUpSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'wrapup');
        const alertSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'alert');
        const holdSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'hold');

        const calculateSegmentTimeInPresence = (segments, presenceDetails, interval) => {
            let totalOnQueueTime = 0;
            let totalAuxTime = 0;

            // Verificar si interval está definido y tiene el formato esperado
            if (interval && typeof interval === 'string' && interval.includes('/')) {
                const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));

                segments?.forEach(segment => {
                    const segmentStart = new Date(segment.segmentStart);
                    const segmentEnd = new Date(segment.segmentEnd);

                    // Intersección entre el segmento de tiempo de la conversación y el intervalo global
                    const maxStartSegment = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
                    const minEndSegment = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));

                    // Si no hay intersección entre el segmento y el intervalo global, omitir
                    if (minEndSegment <= maxStartSegment) {
                        return;
                    }

                    presenceDetails.forEach(presence => {
                        const presenceStart = new Date(presence.startTime);
                        const presenceEnd = new Date(presence.endTime);

                        // Intersección entre el tiempo de presencia y el segmento de la conversación
                        const maxStartPresence = new Date(Math.max(presenceStart.getTime(), maxStartSegment.getTime()));
                        const minEndPresence = new Date(Math.min(presenceEnd.getTime(), minEndSegment.getTime()));

                        if (minEndPresence > maxStartPresence) {
                            const intersectTime = minEndPresence - maxStartPresence;

                            if (presence.systemPresence === 'ON_QUEUE') {
                                totalOnQueueTime += intersectTime;
                            } else {
                                totalAuxTime += intersectTime;
                            }
                        }
                    });
                });
            } else {
                //console.error('El valor de interval es inválido o indefinido:', interval);
                // Aquí puedes decidir si lanzar un error, devolver un valor por defecto o manejarlo de otra forma.
                return { totalOnQueueTime: 0, totalAuxTime: 0 };
            }

            return { totalOnQueueTime, totalAuxTime };
        };


        let totalDuration = 0;
        let totalAcw = 0;
        let totalAlert = 0;
        let totalHold = 0;

        const interactTimes = calculateSegmentTimeInPresence(interactSegments, presenceDetails, interval);
        totalDuration += interactSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const wrapUpTimes = calculateSegmentTimeInPresence(wrapUpSegments, presenceDetails, interval);
        totalAcw += wrapUpSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const alertTimes = calculateSegmentTimeInPresence(alertSegments, presenceDetails, interval);
        totalAlert += alertSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const holdTimes = calculateSegmentTimeInPresence(holdSegments, presenceDetails, interval);
        totalHold += holdSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        // Añadir nuevas métricas calculadas a metricsCopy
        /*if (totalDuration > 0) {
          metricsCopy.push({ metric: 'tTalk', correct: true, stats: { max: totalDuration, min: totalDuration, count: 1, sum: totalDuration }, onQueue: interactTimes.totalOnQueueTime, aux: interactTimes.totalAuxTime });
        }
        if (totalAcw > 0) {
          metricsCopy.push({ metric: 'tAcw', correct: true, stats: { max: totalAcw, min: totalAcw, count: 1, sum: totalAcw }, onQueue: wrapUpTimes.totalOnQueueTime, aux: wrapUpTimes.totalAuxTime });
        }
        if (totalAlert > 0) {
          metricsCopy.push({ metric: 'tAlert', correct: true, stats: { max: totalAlert, min: totalAlert, count: 1, sum: totalAlert }, onQueue: alertTimes.totalOnQueueTime, aux: alertTimes.totalAuxTime });
        }
        if (totalHold > 0) {
          metricsCopy.push({ metric: 'tHold', correct: true, stats: { max: totalHold, min: totalHold, count: 1, sum: totalHold }, onQueue: holdTimes.totalOnQueueTime, aux: holdTimes.totalAuxTime });
        }*/
        
        
        if (totalDuration > 0) {
          metricsCopy.push({ metric: 'tTalk', correct: true, stats: { max: totalDuration, min: totalDuration, count: 1, sum: totalDuration }, onQueue: interactTimes.totalOnQueueTime, auxO: interactTimes.totalAuxTime, aux: totalDuration-interactTimes.totalOnQueueTime>0?totalDuration-interactTimes.totalOnQueueTime:0 });
        }
        if (totalAcw > 0) {
          metricsCopy.push({ metric: 'tAcw', correct: true, stats: { max: totalAcw, min: totalAcw, count: 1, sum: totalAcw }, onQueue: wrapUpTimes.totalOnQueueTime, auxO: wrapUpTimes.totalAuxTime, aux: totalAcw-wrapUpTimes.totalOnQueueTime>0?totalAcw-wrapUpTimes.totalOnQueueTime:0 });
        }
        if (totalAlert > 0) {
          metricsCopy.push({ metric: 'tAlert', correct: true, stats: { max: totalAlert, min: totalAlert, count: 1, sum: totalAlert }, onQueue: alertTimes.totalOnQueueTime, auxO: alertTimes.totalAuxTime, aux: totalAlert-alertTimes.totalOnQueueTime>0?totalAlert-alertTimes.totalOnQueueTime:0 });
        }
        if (totalHold > 0) {
          metricsCopy.push({ metric: 'tHold', correct: true, stats: { max: totalHold, min: totalHold, count: 1, sum: totalHold }, onQueue: holdTimes.totalOnQueueTime, auxO: holdTimes.totalAuxTime, aux: totalHold-holdTimes.totalOnQueueTime>0?totalHold-holdTimes.totalOnQueueTime:0 });
        }
        

        // Gestión de la métrica endedConversacion
        //const lastSegment = lastSession?.segments?.[lastSession?.segments?.length - 1];
        //const lastSession = lastAgentParticipant.sessions[lastAgentParticipant.sessions.length - 1];
        const lastSegment = lastSession?.segments?.reverse().find(segment => segment.segmentType === 'wrapup');
        if (lastSegment) {
          const segmentEnd = new Date(lastSegment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));

          // Validar si segmentEnd del último segmento se encuentra dentro del intervalo de consulta
          const isSegmentEndInInterval = segmentEnd >= intervalStart && segmentEnd <= intervalEnd;

          const hasErrorCode = typeof lastSegment.errorCode === 'string' && lastSegment.errorCode.trim() !== '';
          //|| hasErrorCode
          if (isSegmentEndInInterval) {
            // Calcular los tiempos totales de los segmentos sin considerar la intersección con el intervalo
            const totalInteractTime = interactSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);
            const totalWrapUpTime = wrapUpSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);
            const totalAlertTime = alertSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);
            const totalHoldTime = holdSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);

            // Crear la nueva métrica endedConversacion con el valor de segmentEnd y los tiempos totales de cada métrica
            if(isSegmentEndInInterval){
              metricsCopy.push({
                metric: 'endedConversacion',
                correct: true,
                value: lastSegment.segmentEnd,
                totals: {
                  tTalk: totalInteractTime,
                  tAcw: totalWrapUpTime,
                  tAlert: totalAlertTime,
                  tHold: totalHoldTime
                }
              });
            }else if(hasErrorCode){
              /// aqui vas a añadir la logica de validacion de error de 5 segundos en el intervalo de salida
              const isSegmentEndInIntervalWithMargin = hasErrorCode && segmentEnd <= new Date(intervalEnd.getTime() + 5000); // 5 segundos de margen solo si hasErrorCode es verdadero
              
              if(isSegmentEndInIntervalWithMargin){
                metricsCopy.push({
                  metric: 'endedConversacion',
                  correct: true,
                  value: lastSegment.segmentEnd,
                  totals: {
                    tTalk: totalInteractTime,
                    tAcw: totalWrapUpTime,
                    tAlert: totalAlertTime,
                    tHold: totalHoldTime
                  }
                });
              }

            }

            
          }
        }


        // Asegurarse de devolver la conversación con la copia modificada de metrics
        return { ...conversation, metrics: metricsCopy, details, userPresences: presenceDetails };
      });

      return conversationsWithDetails;
    },

    getConversationsManualPerUsersIntervalOTHERWithDirection(userId, queueId, interval, direction){
      const filteredConversations = this.allUserConversationsAggregates.filter(conversation => {
        return (
          conversation.userId === userId &&
          conversation.interval === interval &&
          (conversation.queueId !== undefined && conversation.queueId !== null && conversation.queueId !== queueId) &&
          conversation.direction === direction
        );
      });

      const presenceDetails = this.getPresenceDetailsForInterval(userId, interval);

      // Calcular el tiempo de intersección para cada presencia
      presenceDetails.forEach(presence => {
        let totalIntersectedTime = 0;

        filteredConversations.forEach(conversation => {
          const details = this.getConversationDetail(conversation.conversationId);
          const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
          const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];

          if (lastSession) {
            lastSession.segments.forEach(segment => {
              const segmentStart = new Date(segment.segmentStart);
              const segmentEnd = new Date(segment.segmentEnd);
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);

              // Calcular la intersección entre el segmento y la presencia
              const maxStart = new Date(Math.max(segmentStart.getTime(), presenceStart.getTime()));
              const minEnd = new Date(Math.min(segmentEnd.getTime(), presenceEnd.getTime()));

              if (minEnd > maxStart) {
                totalIntersectedTime += minEnd - maxStart;
              }
            });
          }
        });

        // Agregar la métrica de tiempo total interceptado en milisegundos
        presence.intersectedTimeWithConversations = totalIntersectedTime;
      });

      const conversationsWithDetails = filteredConversations.map(conversation => {
        const details = this.getConversationDetail(conversation.conversationId);
        const metricsCopy = [...conversation.metrics];
        
        const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
        const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];
        const interactSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'interact');
        const wrapUpSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'wrapup');
        const alertSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'alert');
        const holdSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'hold');

        const calculateSegmentTimeInPresence = (segments, presenceDetails) => {
          let totalOnQueueTime = 0;
          let totalAuxTime = 0;

          segments?.forEach(segment => {
            const segmentStart = new Date(segment.segmentStart);
            const segmentEnd = new Date(segment.segmentEnd);
            const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
            const maxStartSegment = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
            const minEndSegment = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));

            presenceDetails.forEach(presence => {
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);
              const maxStartPresence = new Date(Math.max(presenceStart.getTime(), maxStartSegment.getTime()));
              const minEndPresence = new Date(Math.min(presenceEnd.getTime(), minEndSegment.getTime()));

              if (minEndPresence > maxStartPresence) {
                const intersectTime = minEndPresence - maxStartPresence;
                if (presence.systemPresence === 'ON_QUEUE') {
                  totalOnQueueTime += intersectTime;
                } else {
                  totalAuxTime += intersectTime;
                }
              }
            });
          });

          return { totalOnQueueTime, totalAuxTime };
        };

        let totalDuration = 0;
        let totalAcw = 0;
        let totalAlert = 0;
        let totalHold = 0;

        const interactTimes = calculateSegmentTimeInPresence(interactSegments, presenceDetails);
        totalDuration += interactSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const wrapUpTimes = calculateSegmentTimeInPresence(wrapUpSegments, presenceDetails);
        totalAcw += wrapUpSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const alertTimes = calculateSegmentTimeInPresence(alertSegments, presenceDetails);
        totalAlert += alertSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const holdTimes = calculateSegmentTimeInPresence(holdSegments, presenceDetails);
        totalHold += holdSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        // Añadir nuevas métricas calculadas a metricsCopy
        if (totalDuration > 0) {
          metricsCopy.push({ metric: 'tTalk', correct: true, stats: { max: totalDuration, min: totalDuration, count: 1, sum: totalDuration }, onQueue: interactTimes.totalOnQueueTime, aux: interactTimes.totalAuxTime });
        }
        if (totalAcw > 0) {
          metricsCopy.push({ metric: 'tAcw', correct: true, stats: { max: totalAcw, min: totalAcw, count: 1, sum: totalAcw }, onQueue: wrapUpTimes.totalOnQueueTime, aux: wrapUpTimes.totalAuxTime });
        }
        if (totalAlert > 0) {
          metricsCopy.push({ metric: 'tAlert', correct: true, stats: { max: totalAlert, min: totalAlert, count: 1, sum: totalAlert }, onQueue: alertTimes.totalOnQueueTime, aux: alertTimes.totalAuxTime });
        }
        if (totalHold > 0) {
          metricsCopy.push({ metric: 'tHold', correct: true, stats: { max: totalHold, min: totalHold, count: 1, sum: totalHold }, onQueue: holdTimes.totalOnQueueTime, aux: holdTimes.totalAuxTime });
        }

        // Gestión de la métrica endedConversacion
        const lastSegment = lastSession?.segments?.[lastSession?.segments?.length - 1];
        if (lastSegment) {
          const segmentEnd = new Date(lastSegment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));

          // Validar si segmentEnd del último segmento se encuentra dentro del intervalo de consulta
          if (segmentEnd >= intervalStart && segmentEnd <= intervalEnd) {
            // Crear la nueva métrica endedConversacion con el valor de segmentEnd
            metricsCopy.push({ metric: 'endedConversacion', correct: true, value: lastSegment.segmentEnd });
          }
        }

        // Asegurarse de devolver la conversación con la copia modificada de metrics
        return { ...conversation, metrics: metricsCopy, details, userPresences: presenceDetails };
      });

      return conversationsWithDetails;
    },

    /*getConversationsPerUsersQueueAndIntervalTI(userId, interval) {
      const filteredConversations = this.allUserConversationsAggregates.filter(conversation => {
        return (
          conversation.userId === userId &&
          conversation.interval === interval //&&
          //(conversation.queueId === null || conversation.queueId === undefined)
        );
      });

      const presenceDetails = this.getPresenceDetailsForInterval(userId, interval);

      // Calcular el tiempo de intersección para cada presencia
      presenceDetails.forEach(presence => {
          let totalIntersectedTime = 0;

          filteredConversations.forEach(conversation => {
              const details = this.getConversationDetail(conversation.conversationId);
              const participants = details?.participants?.filter(participant => participant.purpose === 'agent' && participant.userId === userId);

              participants?.forEach(agentParticipant => {
                  const lastSession = agentParticipant?.sessions[agentParticipant?.sessions.length - 1];

                  if (lastSession) {
                      lastSession.segments.forEach(segment => {
                          const segmentStart = new Date(segment.segmentStart);
                          const segmentEnd = new Date(segment.segmentEnd);
                          const presenceStart = new Date(presence.startTime);
                          const presenceEnd = new Date(presence.endTime);

                          // Calcular la intersección entre el segmento y la presencia
                          const maxStart = new Date(Math.max(segmentStart.getTime(), presenceStart.getTime()));
                          const minEnd = new Date(Math.min(segmentEnd.getTime(), presenceEnd.getTime()));

                          if (minEnd > maxStart) {
                              totalIntersectedTime += minEnd - maxStart;
                          }
                      });
                  }
              });
          });

          // Agregar la métrica de tiempo total interceptado en milisegundos
          presence.intersectedTimeWithConversations = totalIntersectedTime;
      });

      const conversationsWithDetails = filteredConversations.map(conversation => {
        const details = this.getConversationDetail(conversation.conversationId);
        const metricsCopy = [...conversation.metrics];
        
        const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
        
        const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];
        const interactSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'interact');
        const wrapUpSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'wrapup');
        const alertSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'alert');
        const holdSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'hold');

        const calculateSegmentTimeInPresence = (segments, presenceDetails, interval) => {
            let totalOnQueueTime = 0;
            let totalAuxTime = 0;

            // Verificar si interval está definido y tiene el formato esperado
            if (interval && typeof interval === 'string' && interval.includes('/')) {
                const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));

                segments?.forEach(segment => {
                    const segmentStart = new Date(segment.segmentStart);
                    const segmentEnd = new Date(segment.segmentEnd);

                    // Intersección entre el segmento de tiempo de la conversación y el intervalo global
                    const maxStartSegment = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
                    const minEndSegment = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));

                    // Si no hay intersección entre el segmento y el intervalo global, omitir
                    if (minEndSegment <= maxStartSegment) {
                        return;
                    }

                    presenceDetails.forEach(presence => {
                        const presenceStart = new Date(presence.startTime);
                        const presenceEnd = new Date(presence.endTime);

                        // Intersección entre el tiempo de presencia y el segmento de la conversación
                        const maxStartPresence = new Date(Math.max(presenceStart.getTime(), maxStartSegment.getTime()));
                        const minEndPresence = new Date(Math.min(presenceEnd.getTime(), minEndSegment.getTime()));

                        if (minEndPresence > maxStartPresence) {
                            const intersectTime = minEndPresence - maxStartPresence;

                            if (presence.systemPresence === 'ON_QUEUE') {
                                totalOnQueueTime += intersectTime;
                            } else {
                                totalAuxTime += intersectTime;
                            }
                        }
                    });
                });
            } else {
                //console.error('El valor de interval es inválido o indefinido:', interval);
                // Aquí puedes decidir si lanzar un error, devolver un valor por defecto o manejarlo de otra forma.
                return { totalOnQueueTime: 0, totalAuxTime: 0 };
            }

            return { totalOnQueueTime, totalAuxTime };
        };


        let totalDuration = 0;
        let totalAcw = 0;
        let totalAlert = 0;
        let totalHold = 0;

        const interactTimes = calculateSegmentTimeInPresence(interactSegments, presenceDetails, interval);
        totalDuration += interactSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const wrapUpTimes = calculateSegmentTimeInPresence(wrapUpSegments, presenceDetails, interval);
        totalAcw += wrapUpSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const alertTimes = calculateSegmentTimeInPresence(alertSegments, presenceDetails, interval);
        totalAlert += alertSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const holdTimes = calculateSegmentTimeInPresence(holdSegments, presenceDetails, interval);
        totalHold += holdSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        // Añadir nuevas métricas calculadas a metricsCopy
        if (totalDuration > 0) {
          metricsCopy.push({ metric: 'tTalk', correct: true, stats: { max: totalDuration, min: totalDuration, count: 1, sum: totalDuration }, onQueue: interactTimes.totalOnQueueTime, aux: interactTimes.totalAuxTime });
        }
        if (totalAcw > 0) {
          metricsCopy.push({ metric: 'tAcw', correct: true, stats: { max: totalAcw, min: totalAcw, count: 1, sum: totalAcw }, onQueue: wrapUpTimes.totalOnQueueTime, aux: wrapUpTimes.totalAuxTime });
        }
        if (totalAlert > 0) {
          metricsCopy.push({ metric: 'tAlert', correct: true, stats: { max: totalAlert, min: totalAlert, count: 1, sum: totalAlert }, onQueue: alertTimes.totalOnQueueTime, aux: alertTimes.totalAuxTime });
        }
        if (totalHold > 0) {
          metricsCopy.push({ metric: 'tHold', correct: true, stats: { max: totalHold, min: totalHold, count: 1, sum: totalHold }, onQueue: holdTimes.totalOnQueueTime, aux: holdTimes.totalAuxTime });
        }

        // Gestión de la métrica endedConversacion
        //const lastSegment = lastSession?.segments?.[lastSession?.segments?.length - 1];
        //const lastSession = lastAgentParticipant.sessions[lastAgentParticipant.sessions.length - 1];
        const lastSegment = lastSession?.segments?.reverse().find(segment => segment.segmentType === 'wrapup');
        if (lastSegment) {
          const segmentEnd = new Date(lastSegment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));

          // Validar si segmentEnd del último segmento se encuentra dentro del intervalo de consulta
          const isSegmentEndInInterval = segmentEnd >= intervalStart && segmentEnd <= intervalEnd;

          const hasErrorCode = typeof lastSegment.errorCode === 'string' && lastSegment.errorCode.trim() !== '';
          //|| hasErrorCode
          if (isSegmentEndInInterval) {
            // Calcular los tiempos totales de los segmentos sin considerar la intersección con el intervalo
            const totalInteractTime = interactSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);
            const totalWrapUpTime = wrapUpSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);
            const totalAlertTime = alertSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);
            const totalHoldTime = holdSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);

            // Crear la nueva métrica endedConversacion con el valor de segmentEnd y los tiempos totales de cada métrica
            if(isSegmentEndInInterval){
              metricsCopy.push({
                metric: 'endedConversacion',
                correct: true,
                value: lastSegment.segmentEnd,
                totals: {
                  tTalk: totalInteractTime,
                  tAcw: totalWrapUpTime,
                  tAlert: totalAlertTime,
                  tHold: totalHoldTime
                }
              });
            }else if(hasErrorCode){
              /// aqui vas a añadir la logica de validacion de error de 5 segundos en el intervalo de salida
              const isSegmentEndInIntervalWithMargin = hasErrorCode && segmentEnd <= new Date(intervalEnd.getTime() + 5000); // 5 segundos de margen solo si hasErrorCode es verdadero
              
              if(isSegmentEndInIntervalWithMargin){
                metricsCopy.push({
                  metric: 'endedConversacion',
                  correct: true,
                  value: lastSegment.segmentEnd,
                  totals: {
                    tTalk: totalInteractTime,
                    tAcw: totalWrapUpTime,
                    tAlert: totalAlertTime,
                    tHold: totalHoldTime
                  }
                });
              }

            }

            
          }
        }


        // Asegurarse de devolver la conversación con la copia modificada de metrics
        return { ...conversation, metrics: metricsCopy, details, userPresences: presenceDetails };
      });

      return conversationsWithDetails;
    },*/

    getConversationsPerUsersQueueAndIntervalTI(userId, interval) {
      const filteredConversations = this.allUserConversationsAggregates.filter(conversation => {
        return (
          conversation.userId === userId &&
          conversation.interval === interval //&&
          //(conversation.queueId === null || conversation.queueId === undefined)
        );
      });

      const presenceDetails = this.getPresenceDetailsForInterval(userId, interval);

      // Calcular el tiempo de intersección para cada presencia
      presenceDetails.forEach(presence => {
          let totalIntersectedTime = 0;

          filteredConversations.forEach(conversation => {
              const details = this.getConversationDetail(conversation.conversationId);
              const participants = details?.participants?.filter(participant => participant.purpose === 'agent' && participant.userId === userId);

              participants?.forEach(agentParticipant => {
                  const lastSession = agentParticipant?.sessions[agentParticipant?.sessions.length - 1];

                  if (lastSession) {
                      lastSession.segments.forEach(segment => {
                          const segmentStart = new Date(segment.segmentStart);
                          const segmentEnd = new Date(segment.segmentEnd);
                          const presenceStart = new Date(presence.startTime);
                          const presenceEnd = new Date(presence.endTime);

                          // Calcular la intersección entre el segmento y la presencia
                          const maxStart = new Date(Math.max(segmentStart.getTime(), presenceStart.getTime()));
                          const minEnd = new Date(Math.min(segmentEnd.getTime(), presenceEnd.getTime()));

                          if (minEnd > maxStart) {
                              totalIntersectedTime += minEnd - maxStart;
                          }
                      });
                  }
              });
          });

          // Agregar la métrica de tiempo total interceptado en milisegundos
          presence.intersectedTimeWithConversations = totalIntersectedTime;
      });

      const conversationsWithDetails = filteredConversations.map(conversation => {
        const details = this.getConversationDetail(conversation.conversationId);
        const metricsCopy = [...conversation.metrics];
        
        const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
        
        const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];
        const interactSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'interact');
        const wrapUpSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'wrapup');
        const alertSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'alert');
        const holdSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'hold');

        const calculateSegmentTimeInPresence = (segments, presenceDetails, interval) => {
            let totalOnQueueTime = 0;
            let totalAuxTime = 0;
            let totalTotalTime = 0; // Nueva métrica para el tiempo total de todos los segmentos

            // Verificar si interval está definido y tiene el formato esperado
            if (interval && typeof interval === 'string' && interval.includes('/')) {
                const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));

                segments?.forEach(segment => {
                    const segmentStart = new Date(segment.segmentStart);
                    const segmentEnd = new Date(segment.segmentEnd);

                    // Intersección entre el segmento de tiempo de la conversación y el intervalo global
                    const maxStartSegment = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
                    const minEndSegment = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));

                    // Sumar el tiempo del segmento a totalTotalTime
                    totalTotalTime += segmentEnd - segmentStart;

                    // Si no hay intersección entre el segmento y el intervalo global, omitir
                    if (minEndSegment <= maxStartSegment) {
                        return;
                    }

                    presenceDetails.forEach(presence => {
                        const presenceStart = new Date(presence.startTime);
                        const presenceEnd = new Date(presence.endTime);

                        // Intersección entre el tiempo de presencia y el segmento de la conversación
                        const maxStartPresence = new Date(Math.max(presenceStart.getTime(), maxStartSegment.getTime()));
                        const minEndPresence = new Date(Math.min(presenceEnd.getTime(), minEndSegment.getTime()));

                        if (minEndPresence > maxStartPresence) {
                            const intersectTime = minEndPresence - maxStartPresence;

                            if (presence.systemPresence === 'ON_QUEUE') {
                                totalOnQueueTime += intersectTime;
                            } else {
                                totalAuxTime += intersectTime;
                            }
                        }
                    });
                });
            } else {
                //console.error('El valor de interval es inválido o indefinido:', interval);
                // Aquí puedes decidir si lanzar un error, devolver un valor por defecto o manejarlo de otra forma.
                return { totalOnQueueTime: 0, totalAuxTime: 0, totalTotalTime: 0 };
            }

            return { totalOnQueueTime, totalAuxTime, totalTotalTime };
        };


        let totalDuration = 0;
        let totalAcw = 0;
        let totalAlert = 0;
        let totalHold = 0;

        const interactTimes = calculateSegmentTimeInPresence(interactSegments, presenceDetails, interval);
        totalDuration += interactSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const wrapUpTimes = calculateSegmentTimeInPresence(wrapUpSegments, presenceDetails, interval);
        totalAcw += wrapUpSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const alertTimes = calculateSegmentTimeInPresence(alertSegments, presenceDetails, interval);
        totalAlert += alertSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const holdTimes = calculateSegmentTimeInPresence(holdSegments, presenceDetails, interval);
        totalHold += holdSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        // Añadir nuevas métricas calculadas a metricsCopy
        /*if (totalDuration > 0) {
          metricsCopy.push({ metric: 'tTalk', correct: true, stats: { max: totalDuration, min: totalDuration, count: 1, sum: totalDuration }, onQueue: interactTimes.totalOnQueueTime, aux: interactTimes.totalAuxTime });
        }
        if (totalAcw > 0) {
          metricsCopy.push({ metric: 'tAcw', correct: true, stats: { max: totalAcw, min: totalAcw, count: 1, sum: totalAcw }, onQueue: wrapUpTimes.totalOnQueueTime, aux: wrapUpTimes.totalAuxTime });
        }
        if (totalAlert > 0) {
          metricsCopy.push({ metric: 'tAlert', correct: true, stats: { max: totalAlert, min: totalAlert, count: 1, sum: totalAlert }, onQueue: alertTimes.totalOnQueueTime, aux: alertTimes.totalAuxTime });
        }
        if (totalHold > 0) {
          metricsCopy.push({ metric: 'tHold', correct: true, stats: { max: totalHold, min: totalHold, count: 1, sum: totalHold }, onQueue: holdTimes.totalOnQueueTime, aux: holdTimes.totalAuxTime });
        }*/
        
        
        if (totalDuration > 0) {
          metricsCopy.push({ metric: 'tTalk', correct: true, stats: { max: totalDuration, min: totalDuration, count: 1, sum: totalDuration }, complete:interactTimes.totalTotalTime, onQueue: interactTimes.totalOnQueueTime, auxO:interactTimes.totalAuxTime, aux: totalDuration-interactTimes.totalOnQueueTime>0?totalDuration-interactTimes.totalOnQueueTime:0 });
        }
        if (totalAcw > 0) {
          metricsCopy.push({ metric: 'tAcw', correct: true, stats: { max: totalAcw, min: totalAcw, count: 1, sum: totalAcw }, complete:wrapUpTimes.totalTotalTime, onQueue: wrapUpTimes.totalOnQueueTime, auxO:wrapUpTimes.totalAuxTime, aux: totalAcw-wrapUpTimes.totalOnQueueTime>0?totalAcw-wrapUpTimes.totalOnQueueTime:0 });
        }
        if (totalAlert > 0) {
          metricsCopy.push({ metric: 'tAlert', correct: true, stats: { max: totalAlert, min: totalAlert, count: 1, sum: totalAlert }, complete:alertTimes.totalTotalTime, onQueue: alertTimes.totalOnQueueTime, auxO:alertTimes.totalAuxTime, aux: totalAlert-alertTimes.totalOnQueueTime>0?totalAlert-alertTimes.totalOnQueueTime:0 });
        }
        if (totalHold > 0) {
          metricsCopy.push({ metric: 'tHold', correct: true, stats: { max: totalHold, min: totalHold, count: 1, sum: totalHold }, complete:holdTimes.totalTotalTime,  onQueue: holdTimes.totalOnQueueTime, auxO:holdTimes.totalAuxTime,  aux: totalHold-holdTimes.totalOnQueueTime>0?totalHold-holdTimes.totalOnQueueTime:0 });
        }
        

        // Gestión de la métrica endedConversacion
        //const lastSegment = lastSession?.segments?.[lastSession?.segments?.length - 1];
        //const lastSession = lastAgentParticipant.sessions[lastAgentParticipant.sessions.length - 1];
        const lastSegment = lastSession?.segments?.reverse().find(segment => segment.segmentType === 'wrapup');
        if (lastSegment) {
          const segmentEnd = new Date(lastSegment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));

          // Validar si segmentEnd del último segmento se encuentra dentro del intervalo de consulta
          const isSegmentEndInInterval = segmentEnd >= intervalStart && segmentEnd <= intervalEnd;

          const hasErrorCode = typeof lastSegment.errorCode === 'string' && lastSegment.errorCode.trim() !== '';
          //|| hasErrorCode
          if (isSegmentEndInInterval) {
            // Calcular los tiempos totales de los segmentos sin considerar la intersección con el intervalo
            const totalInteractTime = interactSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);
            const totalWrapUpTime = wrapUpSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);
            const totalAlertTime = alertSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);
            const totalHoldTime = holdSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);

            // Crear la nueva métrica endedConversacion con el valor de segmentEnd y los tiempos totales de cada métrica
            if(isSegmentEndInInterval){
              metricsCopy.push({
                metric: 'endedConversacion',
                correct: true,
                value: lastSegment.segmentEnd,
                totals: {
                  tTalk: totalInteractTime,
                  tAcw: totalWrapUpTime,
                  tAlert: totalAlertTime,
                  tHold: totalHoldTime
                }
              });
            }else if(hasErrorCode){
              /// aqui vas a añadir la logica de validacion de error de 5 segundos en el intervalo de salida
              const isSegmentEndInIntervalWithMargin = hasErrorCode && segmentEnd <= new Date(intervalEnd.getTime() + 5000); // 5 segundos de margen solo si hasErrorCode es verdadero
              
              if(isSegmentEndInIntervalWithMargin){
                metricsCopy.push({
                  metric: 'endedConversacion',
                  correct: true,
                  value: lastSegment.segmentEnd,
                  totals: {
                    tTalk: totalInteractTime,
                    tAcw: totalWrapUpTime,
                    tAlert: totalAlertTime,
                    tHold: totalHoldTime
                  }
                });
              }

            }

            
          }
        }


        // Asegurarse de devolver la conversación con la copia modificada de metrics
        return { ...conversation, metrics: metricsCopy, details, userPresences: presenceDetails };
      });

      return conversationsWithDetails;
    },

    /*getConversationsPerUsersQueueAndIntervalTI(userId, interval) {
      const filteredConversations = this.allUserConversationsAggregates.filter(conversation => {
        return (
          conversation.userId === userId &&
          conversation.interval === interval &&
          (conversation.queueId === null || conversation.queueId === undefined)
        );
      });

      const presenceDetails = this.getPresenceDetailsForInterval(userId, interval);

      // Calcular el tiempo de intersección para cada presencia
      presenceDetails.forEach(presence => {
        let totalIntersectedTime = 0;

        filteredConversations.forEach(conversation => {
          const details = this.getConversationDetail(conversation.conversationId);
          const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
          const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];

          if (lastSession) {
            lastSession.segments.forEach(segment => {
              const segmentStart = new Date(segment.segmentStart);
              const segmentEnd = new Date(segment.segmentEnd);
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);

              // Calcular la intersección entre el segmento y la presencia
              const maxStart = new Date(Math.max(segmentStart.getTime(), presenceStart.getTime()));
              const minEnd = new Date(Math.min(segmentEnd.getTime(), presenceEnd.getTime()));

              if (minEnd > maxStart) {
                totalIntersectedTime += minEnd - maxStart;
              }
            });
          }
        });

        // Agregar la métrica de tiempo total interceptado en milisegundos
        presence.intersectedTimeWithConversations = totalIntersectedTime;
      });

      const conversationsWithDetails = filteredConversations.map(conversation => {
        const details = this.getConversationDetail(conversation.conversationId);
        const metricsCopy = [...conversation.metrics];
        
        const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
        const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];
        const interactSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'interact');
        //const dialingSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'dialing');
        //const contactingSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'contacting');
        const wrapUpSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'wrapup');
        const alertSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'alert');
        const holdSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'hold');

        const calculateSegmentTimeInPresence = (segments, presenceDetails) => {
          let totalOnQueueTime = 0;
          let totalAuxTime = 0;

          segments?.forEach(segment => {
            const segmentStart = new Date(segment.segmentStart);
            const segmentEnd = new Date(segment.segmentEnd);
            const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
            const maxStartSegment = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
            const minEndSegment = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));

            presenceDetails.forEach(presence => {
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);
              const maxStartPresence = new Date(Math.max(presenceStart.getTime(), maxStartSegment.getTime()));
              const minEndPresence = new Date(Math.min(presenceEnd.getTime(), minEndSegment.getTime()));

              if (minEndPresence > maxStartPresence) {
                const intersectTime = minEndPresence - maxStartPresence;
                if (presence.systemPresence === 'ON_QUEUE') {
                  totalOnQueueTime += intersectTime;
                } else {
                  totalAuxTime += intersectTime;
                }
              }
            });
          });

          return { totalOnQueueTime, totalAuxTime };
        };

        let totalDuration = 0;
        let totalAcw = 0;
        let totalAlert = 0;
        let totalHold = 0;
        //let totalDialing = 0;
        //let totalContacting = 0;

        const interactTimes = calculateSegmentTimeInPresence(interactSegments, presenceDetails);
        totalDuration += interactSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const wrapUpTimes = calculateSegmentTimeInPresence(wrapUpSegments, presenceDetails);
        totalAcw += wrapUpSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const alertTimes = calculateSegmentTimeInPresence(alertSegments, presenceDetails);
        totalAlert += alertSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const holdTimes = calculateSegmentTimeInPresence(holdSegments, presenceDetails);
        totalHold += holdSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        //const dialingTimes = calculateSegmentTimeInPresence(dialingSegments, presenceDetails);
        //totalDialing += holdSegments?.reduce((acc, segment) => {
          //const segmentStart = new Date(segment.segmentStart);
          //const segmentEnd = new Date(segment.segmentEnd);
          //const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          //const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          //const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          //return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        //}, 0);

        //const contactingTimes = calculateSegmentTimeInPresence(contactingSegments, presenceDetails);
        //totalDialing += holdSegments?.reduce((acc, segment) => {
          //const segmentStart = new Date(segment.segmentStart);
          //const segmentEnd = new Date(segment.segmentEnd);
          //const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          //const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          //const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        //}, 0);

        // Añadir nuevas métricas calculadas a metricsCopy
        if (totalDuration > 0) {
          metricsCopy.push({ metric: 'tTalk', correct: true, stats: { max: totalDuration, min: totalDuration, count: 1, sum: totalDuration }, onQueue: interactTimes.totalOnQueueTime, aux: interactTimes.totalAuxTime });
        }
        if (totalAcw > 0) {
          metricsCopy.push({ metric: 'tAcw', correct: true, stats: { max: totalAcw, min: totalAcw, count: 1, sum: totalAcw }, onQueue: wrapUpTimes.totalOnQueueTime, aux: wrapUpTimes.totalAuxTime });
        }
        if (totalAlert > 0) {
          metricsCopy.push({ metric: 'tAlert', correct: true, stats: { max: totalAlert, min: totalAlert, count: 1, sum: totalAlert }, onQueue: alertTimes.totalOnQueueTime, aux: alertTimes.totalAuxTime });
        }
        if (totalHold > 0) {
          metricsCopy.push({ metric: 'tHold', correct: true, stats: { max: totalHold, min: totalHold, count: 1, sum: totalHold }, onQueue: holdTimes.totalOnQueueTime, aux: holdTimes.totalAuxTime });
        }

        //if (totalDialing > 0) {
          //metricsCopy.push({ metric: 'tDialing', correct: true, stats: { max: totalDialing, min: totalDialing, count: 1, sum: totalDialing }, onQueue: dialingTimes.totalOnQueueTime, aux: dialingTimes.totalAuxTime });
        //}

        //if (totalContacting > 0) {
          //metricsCopy.push({ metric: 'tContacting', correct: true, stats: { max: totalContacting, min: totalContacting, count: 1, sum: totalContacting }, onQueue: contactingTimes.totalOnQueueTime, aux: contactingTimes.totalAuxTime });
        //}

        // Gestión de la métrica endedConversacion
        const lastSegment = lastSession?.segments?.[lastSession?.segments?.length - 1];
        if (lastSegment) {
          const segmentEnd = new Date(lastSegment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));

          // Validar si segmentEnd del último segmento se encuentra dentro del intervalo de consulta
          if (segmentEnd >= intervalStart && segmentEnd <= intervalEnd) {
            // Crear la nueva métrica endedConversacion con el valor de segmentEnd
            metricsCopy.push({ metric: 'endedConversacion', correct: true, value: lastSegment.segmentEnd });
          }
        }

        // Asegurarse de devolver la conversación con la copia modificada de metrics
        return { ...conversation, metrics: metricsCopy, details, userPresences: presenceDetails };
      });

      return conversationsWithDetails;
    },*/


    /*getConversationsPerUsersQueueAndIntervalALLNew(userId, queueId, interval) {
      const filteredConversations = this.allUserConversationsAggregates.filter(conversation => {
        return (
          conversation.userId === userId && conversation.queueId == queueId && conversation.interval == interval
        );
      });

      const presenceDetails = this.getPresenceDetailsForInterval(userId, interval);

      // Calcular el tiempo de intersección para cada presencia
      presenceDetails.forEach(presence => {
        let totalIntersectedTime = 0;

        filteredConversations.forEach(conversation => {
          const details = this.getConversationDetail(conversation.conversationId);
          const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
          const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];

          if (lastSession) {
            lastSession.segments.forEach(segment => {
              const segmentStart = new Date(segment.segmentStart);
              const segmentEnd = new Date(segment.segmentEnd);
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);

              // Calcular la intersección entre el segmento y la presencia
              const maxStart = new Date(Math.max(segmentStart.getTime(), presenceStart.getTime()));
              const minEnd = new Date(Math.min(segmentEnd.getTime(), presenceEnd.getTime()));

              if (minEnd > maxStart) {
                totalIntersectedTime += minEnd - maxStart;
              }
            });
          }
        });

        // Agregar la métrica de tiempo total interceptado en milisegundos
        presence.intersectedTimeWithConversations = totalIntersectedTime;
      });

      const conversationsWithDetails = filteredConversations.map(conversation => {
        const details = this.getConversationDetail(conversation.conversationId);
        const metricsCopy = [...conversation.metrics];
        
        const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
        const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];
        const interactSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'interact');
        const wrapUpSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'wrapup');
        const alertSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'alert');
        const holdSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'hold');

        const calculateSegmentTimeInPresence = (segments, presenceDetails) => {
          let totalOnQueueTime = 0;
          let totalAuxTime = 0;

          segments?.forEach(segment => {
            const segmentStart = new Date(segment.segmentStart);
            const segmentEnd = new Date(segment.segmentEnd);
            const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
            const maxStartSegment = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
            const minEndSegment = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));

            presenceDetails.forEach(presence => {
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);
              const maxStartPresence = new Date(Math.max(presenceStart.getTime(), maxStartSegment.getTime()));
              const minEndPresence = new Date(Math.min(presenceEnd.getTime(), minEndSegment.getTime()));

              if (minEndPresence > maxStartPresence) {
                const intersectTime = minEndPresence - maxStartPresence;
                if (presence.systemPresence === 'ON_QUEUE') {
                  totalOnQueueTime += intersectTime;
                } else {
                  totalAuxTime += intersectTime;
                }
              }
            });
          });

          return { totalOnQueueTime, totalAuxTime };
        };

        let totalDuration = 0;
        let totalAcw = 0;
        let totalAlert = 0;
        let totalHold = 0;

        const interactTimes = calculateSegmentTimeInPresence(interactSegments, presenceDetails);
        totalDuration += interactSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const wrapUpTimes = calculateSegmentTimeInPresence(wrapUpSegments, presenceDetails);
        totalAcw += wrapUpSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const alertTimes = calculateSegmentTimeInPresence(alertSegments, presenceDetails);
        totalAlert += alertSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const holdTimes = calculateSegmentTimeInPresence(holdSegments, presenceDetails);
        totalHold += holdSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        // Añadir nuevas métricas calculadas a metricsCopy
        if (totalDuration > 0) {
          metricsCopy.push({ metric: 'tTalk', correct: true, stats: { max: totalDuration, min: totalDuration, count: 1, sum: totalDuration }, onQueue: interactTimes.totalOnQueueTime, aux: interactTimes.totalAuxTime });
        }
        if (totalAcw > 0) {
          metricsCopy.push({ metric: 'tAcw', correct: true, stats: { max: totalAcw, min: totalAcw, count: 1, sum: totalAcw }, onQueue: wrapUpTimes.totalOnQueueTime, aux: wrapUpTimes.totalAuxTime });
        }
        if (totalAlert > 0) {
          metricsCopy.push({ metric: 'tAlert', correct: true, stats: { max: totalAlert, min: totalAlert, count: 1, sum: totalAlert }, onQueue: alertTimes.totalOnQueueTime, aux: alertTimes.totalAuxTime });
        }
        if (totalHold > 0) {
          metricsCopy.push({ metric: 'tHold', correct: true, stats: { max: totalHold, min: totalHold, count: 1, sum: totalHold }, onQueue: holdTimes.totalOnQueueTime, aux: holdTimes.totalAuxTime });
        }

        // Gestión de la métrica endedConversacion
        const lastSegment = lastSession?.segments?.[lastSession?.segments?.length - 1];
        if (lastSegment) {
          const segmentEnd = new Date(lastSegment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));

          // Validar si segmentEnd del último segmento se encuentra dentro del intervalo de consulta
          if (segmentEnd >= intervalStart && segmentEnd <= intervalEnd) {
            // Crear la nueva métrica endedConversacion con el valor de segmentEnd
            metricsCopy.push({ metric: 'endedConversacion', correct: true, value: lastSegment.segmentEnd });
          }
        }

        // Asegurarse de devolver la conversación con la copia modificada de metrics
        return { ...conversation, metrics: metricsCopy, details, userPresences: presenceDetails };
      });

      return conversationsWithDetails;
    },*/

    hasAnyConversationExist(userId, interval) {
      const exists = this.allUserConversationsAggregates.some(conversation => {
        return (
          conversation.userId === userId && conversation.interval == interval  && (conversation.queueId !== null && conversation.queueId !== undefined)
        );
      });

      return exists ? 1 : 0;
    },

    getConversationsPerUsersQueueAndIntervalALLNew(userId, queueId, interval) {
      const filteredConversations = this.allUserConversationsAggregates.filter(conversation => {
        return (
          conversation.userId === userId && conversation.queueId == queueId && conversation.interval == interval
        );
      });

      const presenceDetails = this.getPresenceDetailsForInterval(userId, interval);

      // Calcular el tiempo de intersección para cada presencia
      presenceDetails.forEach(presence => {
          let totalIntersectedTime = 0;

          filteredConversations.forEach(conversation => {
              const details = this.getConversationDetail(conversation.conversationId);
              const participants = details?.participants?.filter(participant => participant.purpose === 'agent' && participant.userId === userId);

              participants?.forEach(agentParticipant => {
                  const lastSession = agentParticipant?.sessions[agentParticipant?.sessions.length - 1];

                  if (lastSession) {
                      lastSession.segments.forEach(segment => {
                          const segmentStart = new Date(segment.segmentStart);
                          const segmentEnd = new Date(segment.segmentEnd);
                          const presenceStart = new Date(presence.startTime);
                          const presenceEnd = new Date(presence.endTime);

                          // Calcular la intersección entre el segmento y la presencia
                          const maxStart = new Date(Math.max(segmentStart.getTime(), presenceStart.getTime()));
                          const minEnd = new Date(Math.min(segmentEnd.getTime(), presenceEnd.getTime()));

                          if (minEnd > maxStart) {
                              totalIntersectedTime += minEnd - maxStart;
                          }
                      });
                  }
              });
          });

          // Agregar la métrica de tiempo total interceptado en milisegundos
          presence.intersectedTimeWithConversations = totalIntersectedTime;
      });

      const conversationsWithDetails = filteredConversations.map(conversation => {
        const details = this.getConversationDetail(conversation.conversationId);
        const metricsCopy = [...conversation.metrics];
        
        const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
        
        const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];
        const interactSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'interact');
        const wrapUpSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'wrapup');
        const alertSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'alert');
        const holdSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'hold');

        let offlineAcd = 0;
    let offlineAcw = 0;
    let offlineAlert = 0;
    let offlineHold = 0;

    const calculateSegmentTimeInPresence = (segments, presenceDetails, interval, metric) => {
      let totalOnQueueTime = 0;
      let totalAuxTime = 0;
      let totalTotalTime = 0; // Nueva métrica para el tiempo total de todos los segmentos

      // Verificar si interval está definido y tiene el formato esperado
      if (interval && typeof interval === 'string' && interval.includes('/')) {
        const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));

        segments?.forEach(segment => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);

          // Intersección entre el segmento de tiempo de la conversación y el intervalo global
          const maxStartSegment = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEndSegment = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));

          // Sumar el tiempo del segmento a totalTotalTime
          totalTotalTime += segmentEnd - segmentStart;

          // Si no hay intersección entre el segmento y el intervalo global, omitir
          if (minEndSegment <= maxStartSegment) {
            return;
          }

          presenceDetails.forEach(presence => {
            const presenceStart = new Date(presence.startTime);
            const presenceEnd = new Date(presence.endTime);

            // Intersección entre el tiempo de presencia y el segmento de la conversación
            const maxStartPresence = new Date(Math.max(presenceStart.getTime(), maxStartSegment.getTime()));
            const minEndPresence = new Date(Math.min(presenceEnd.getTime(), minEndSegment.getTime()));

            if (minEndPresence > maxStartPresence) {
              const intersectTime = minEndPresence - maxStartPresence;

              // Si el estado es OFFLINE, se cuentan los tiempos en las métricas correspondientes
              
                
              if (presence.systemPresence === 'ON_QUEUE') {
                totalOnQueueTime += intersectTime;
              } else {
                if (presence.systemPresence === 'OFFLINE') {
                  if (metric === 'tTalk') offlineAcd += intersectTime;
                  if (metric === 'tAcw') offlineAcw += intersectTime;
                  if (metric === 'tAlert') offlineAlert += intersectTime;
                  if (metric === 'tHold') offlineHold += intersectTime;
                }
                totalAuxTime += intersectTime;
              }
            }
          });
        });
      } else {
        return { totalOnQueueTime: 0, totalAuxTime: 0, totalTotalTime: 0 };
      }

      return { totalOnQueueTime, totalAuxTime, totalTotalTime };
    };

    let totalDuration = 0;
    let totalAcw = 0;
    let totalAlert = 0;
    let totalHold = 0;

    const interactTimes = calculateSegmentTimeInPresence(interactSegments, presenceDetails, interval, 'tTalk');
    totalDuration += interactSegments?.reduce((acc, segment) => {
      const segmentStart = new Date(segment.segmentStart);
      const segmentEnd = new Date(segment.segmentEnd);
      const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
      const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
      const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
      return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
    }, 0);

    const wrapUpTimes = calculateSegmentTimeInPresence(wrapUpSegments, presenceDetails, interval, 'tAcw');
    totalAcw += wrapUpSegments?.reduce((acc, segment) => {
      const segmentStart = new Date(segment.segmentStart);
      const segmentEnd = new Date(segment.segmentEnd);
      const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
      const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
      const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
      return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
    }, 0);

    const alertTimes = calculateSegmentTimeInPresence(alertSegments, presenceDetails, interval, 'tAlert');
    totalAlert += alertSegments?.reduce((acc, segment) => {
      const segmentStart = new Date(segment.segmentStart);
      const segmentEnd = new Date(segment.segmentEnd);
      const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
      const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
      const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
      return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
    }, 0);

    const holdTimes = calculateSegmentTimeInPresence(holdSegments, presenceDetails, interval, 'tHold');
    totalHold += holdSegments?.reduce((acc, segment) => {
      const segmentStart = new Date(segment.segmentStart);
      const segmentEnd = new Date(segment.segmentEnd);
      const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
      const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
      const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
      return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
    }, 0);

        // Añadir nuevas métricas calculadas a metricsCopy
        /*if (totalDuration > 0) {
          metricsCopy.push({ metric: 'tTalk', correct: true, stats: { max: totalDuration, min: totalDuration, count: 1, sum: totalDuration }, onQueue: interactTimes.totalOnQueueTime, aux: interactTimes.totalAuxTime });
        }
        if (totalAcw > 0) {
          metricsCopy.push({ metric: 'tAcw', correct: true, stats: { max: totalAcw, min: totalAcw, count: 1, sum: totalAcw }, onQueue: wrapUpTimes.totalOnQueueTime, aux: wrapUpTimes.totalAuxTime });
        }
        if (totalAlert > 0) {
          metricsCopy.push({ metric: 'tAlert', correct: true, stats: { max: totalAlert, min: totalAlert, count: 1, sum: totalAlert }, onQueue: alertTimes.totalOnQueueTime, aux: alertTimes.totalAuxTime });
        }
        if (totalHold > 0) {
          metricsCopy.push({ metric: 'tHold', correct: true, stats: { max: totalHold, min: totalHold, count: 1, sum: totalHold }, onQueue: holdTimes.totalOnQueueTime, aux: holdTimes.totalAuxTime });
        }*/
        
        
        if (totalDuration > 0) {
          metricsCopy.push({ offlineAcd, metric: 'tTalk', correct: true, stats: { max: totalDuration, min: totalDuration, count: 1, sum: totalDuration }, complete:interactTimes.totalTotalTime, onQueue: interactTimes.totalOnQueueTime, auxO:interactTimes.totalAuxTime, aux: totalDuration-interactTimes.totalOnQueueTime>0?totalDuration-interactTimes.totalOnQueueTime:0 });
        }
        if (totalAcw > 0) {
          metricsCopy.push({ offlineAcw, metric: 'tAcw', correct: true, stats: { max: totalAcw, min: totalAcw, count: 1, sum: totalAcw }, complete:wrapUpTimes.totalTotalTime, onQueue: wrapUpTimes.totalOnQueueTime, auxO:wrapUpTimes.totalAuxTime, aux: totalAcw-wrapUpTimes.totalOnQueueTime>0?totalAcw-wrapUpTimes.totalOnQueueTime:0 });
        }
        if (totalAlert > 0) {
          metricsCopy.push({ offlineAlert, metric: 'tAlert', correct: true, stats: { max: totalAlert, min: totalAlert, count: 1, sum: totalAlert }, complete:alertTimes.totalTotalTime, onQueue: alertTimes.totalOnQueueTime, auxO:alertTimes.totalAuxTime, aux: totalAlert-alertTimes.totalOnQueueTime>0?totalAlert-alertTimes.totalOnQueueTime:0 });
        }
        if (totalHold > 0) {
          metricsCopy.push({ offlineHold, metric: 'tHold', correct: true, stats: { max: totalHold, min: totalHold, count: 1, sum: totalHold }, complete:holdTimes.totalTotalTime,  onQueue: holdTimes.totalOnQueueTime, auxO:holdTimes.totalAuxTime,  aux: totalHold-holdTimes.totalOnQueueTime>0?totalHold-holdTimes.totalOnQueueTime:0 });
        }
        

        // Gestión de la métrica endedConversacion
        //const lastSegment = lastSession?.segments?.[lastSession?.segments?.length - 1];
        //const lastSession = lastAgentParticipant.sessions[lastAgentParticipant.sessions.length - 1];
        const lastSegment = lastSession?.segments?.reverse().find(segment => segment.segmentType === 'wrapup');
        if (lastSegment) {
          const segmentEnd = new Date(lastSegment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));

          // Validar si segmentEnd del último segmento se encuentra dentro del intervalo de consulta
          const isSegmentEndInInterval = segmentEnd >= intervalStart && segmentEnd <= intervalEnd;

          const hasErrorCode = typeof lastSegment.errorCode === 'string' && lastSegment.errorCode.trim() !== '';
          //|| hasErrorCode
          if (isSegmentEndInInterval) {
            // Calcular los tiempos totales de los segmentos sin considerar la intersección con el intervalo
            const totalInteractTime = interactSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);
            const totalWrapUpTime = wrapUpSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);
            const totalAlertTime = alertSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);
            const totalHoldTime = holdSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);

            // Crear la nueva métrica endedConversacion con el valor de segmentEnd y los tiempos totales de cada métrica
            if(isSegmentEndInInterval){
              metricsCopy.push({
                metric: 'endedConversacion',
                correct: true,
                value: lastSegment.segmentEnd,
                totals: {
                  tTalk: totalInteractTime,
                  tAcw: totalWrapUpTime,
                  tAlert: totalAlertTime,
                  tHold: totalHoldTime
                }
              });
            }else if(hasErrorCode){
              /// aqui vas a añadir la logica de validacion de error de 5 segundos en el intervalo de salida
              const isSegmentEndInIntervalWithMargin = hasErrorCode && segmentEnd <= new Date(intervalEnd.getTime() + 5000); // 5 segundos de margen solo si hasErrorCode es verdadero
              
              if(isSegmentEndInIntervalWithMargin){
                metricsCopy.push({
                  metric: 'endedConversacion',
                  correct: true,
                  value: lastSegment.segmentEnd,
                  totals: {
                    tTalk: totalInteractTime,
                    tAcw: totalWrapUpTime,
                    tAlert: totalAlertTime,
                    tHold: totalHoldTime
                  }
                });
              }

            }

            
          }
        }


        // Asegurarse de devolver la conversación con la copia modificada de metrics
        return { ...conversation, metrics: metricsCopy, details, userPresences: presenceDetails };
      });

      return conversationsWithDetails;
    },

    getConversationsPerUsersQueueAndIntervalALLNew2(userId, queueId, interval) {
      const filteredConversations = this.allUserConversationsAggregates.filter(conversation => {
        return (
          conversation.userId === userId && conversation.queueId == queueId && conversation.interval == interval
        );
    });

  const presenceDetails = this.getPresenceDetailsForInterval(userId, interval);

  // Calcular el tiempo de intersección para cada presencia
  presenceDetails.forEach(presence => {
    let totalIntersectedTime = 0;

    filteredConversations.forEach(conversation => {
      const details = this.getConversationDetail(conversation.conversationId);
      const participants = details.participants.filter(participant => participant.purpose === 'agent' && participant.userId === userId);

      participants.forEach(agentParticipant => {
        const lastSession = agentParticipant.sessions[agentParticipant.sessions.length - 1];

        if (lastSession) {
          lastSession.segments.forEach(segment => {
            const segmentStart = new Date(segment.segmentStart);
            const segmentEnd = new Date(segment.segmentEnd);
            const presenceStart = new Date(presence.startTime);
            const presenceEnd = new Date(presence.endTime);

            // Calcular la intersección entre el segmento y la presencia
            const maxStart = new Date(Math.max(segmentStart.getTime(), presenceStart.getTime()));
            const minEnd = new Date(Math.min(segmentEnd.getTime(), presenceEnd.getTime()));

            if (minEnd > maxStart) {
              totalIntersectedTime += minEnd - maxStart;
            }
          });
        }
      });
    });

    // Agregar la métrica de tiempo total interceptado en milisegundos
    presence.intersectedTimeWithConversations = totalIntersectedTime;
  });

  const conversationsWithDetails = filteredConversations.map(conversation => {
    const details = this.getConversationDetail(conversation.conversationId);
    const metricsCopy = [...conversation.metrics];

    const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);

    const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];
    const interactSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'interact');
    const wrapUpSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'wrapup');
    const alertSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'alert');
    const holdSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'hold');

    const calculateSegmentTimeInPresence = (segments, presenceDetails) => {
      let totalOnQueueTime = 0;
      let totalAuxTime = 0;

      segments?.forEach(segment => {
        const segmentStart = new Date(segment.segmentStart);
        const segmentEnd = new Date(segment.segmentEnd);
        const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
        const maxStartSegment = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
        const minEndSegment = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));

        presenceDetails.forEach(presence => {
          const presenceStart = new Date(presence.startTime);
          const presenceEnd = new Date(presence.endTime);
          const maxStartPresence = new Date(Math.max(presenceStart.getTime(), maxStartSegment.getTime()));
          const minEndPresence = new Date(Math.min(presenceEnd.getTime(), minEndSegment.getTime()));

          if (minEndPresence > maxStartPresence) {
            const intersectTime = minEndPresence - maxStartPresence;
            if (presence.systemPresence === 'ON_QUEUE') {
              totalOnQueueTime += intersectTime;
            } else {
              totalAuxTime += intersectTime;
            }
          }
        });
      });

      return { totalOnQueueTime, totalAuxTime };
    };

    let totalDuration = 0;
    let totalAcw = 0;
    let totalAlert = 0;
    let totalHold = 0;

    const interactTimes = calculateSegmentTimeInPresence(interactSegments, presenceDetails);
    totalDuration += interactSegments?.reduce((acc, segment) => {
      const segmentStart = new Date(segment.segmentStart);
      const segmentEnd = new Date(segment.segmentEnd);
      const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
      const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
      const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
      return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
    }, 0);

    const wrapUpTimes = calculateSegmentTimeInPresence(wrapUpSegments, presenceDetails);
    totalAcw += wrapUpSegments?.reduce((acc, segment) => {
      const segmentStart = new Date(segment.segmentStart);
      const segmentEnd = new Date(segment.segmentEnd);
      const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
      const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
      const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
      return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
    }, 0);

    const alertTimes = calculateSegmentTimeInPresence(alertSegments, presenceDetails);
    totalAlert += alertSegments?.reduce((acc, segment) => {
      const segmentStart = new Date(segment.segmentStart);
      const segmentEnd = new Date(segment.segmentEnd);
      const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
      const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
      const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
      return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
    }, 0);

    const holdTimes = calculateSegmentTimeInPresence(holdSegments, presenceDetails);
    totalHold += holdSegments?.reduce((acc, segment) => {
      const segmentStart = new Date(segment.segmentStart);
      const segmentEnd = new Date(segment.segmentEnd);
      const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
      const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
      const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
      return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
    }, 0);

    // Añadir nuevas métricas calculadas a metricsCopy
    if (totalDuration > 0) {
      metricsCopy.push({ metric: 'tTalk', correct: true, stats: { max: totalDuration, min: totalDuration, count: 1, sum: totalDuration }, onQueue: interactTimes.totalOnQueueTime, aux: interactTimes.totalAuxTime });
    }
    if (totalAcw > 0) {
      metricsCopy.push({ metric: 'tAcw', correct: true, stats: { max: totalAcw, min: totalAcw, count: 1, sum: totalAcw }, onQueue: wrapUpTimes.totalOnQueueTime, aux: wrapUpTimes.totalAuxTime });
    }
    if (totalAlert > 0) {
      metricsCopy.push({ metric: 'tAlert', correct: true, stats: { max: totalAlert, min: totalAlert, count: 1, sum: totalAlert }, onQueue: alertTimes.totalOnQueueTime, aux: alertTimes.totalAuxTime });
    }
    if (totalHold > 0) {
      metricsCopy.push({ metric: 'tHold', correct: true, stats: { max: totalHold, min: totalHold, count: 1, sum: totalHold }, onQueue: holdTimes.totalOnQueueTime, aux: holdTimes.totalAuxTime });
    }

    // Gestión de la métrica endedConversacion
    const lastSegment = lastSession?.segments?.[lastSession?.segments?.length - 1];
    if (lastSegment) {
      const segmentEnd = new Date(lastSegment.segmentEnd);
      const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));

      // Validar si segmentEnd del último segmento se encuentra dentro del intervalo de consulta
      const isSegmentEndInInterval = segmentEnd >= intervalStart && segmentEnd <= intervalEnd;
      const isSegmentEndInIntervalWithMargin = segmentEnd >= intervalStart && segmentEnd <= new Date(intervalEnd.getTime() + 5000); // 5 segundos de margen
      const hasErrorCode = typeof lastSegment.errorCode === 'string' && lastSegment.errorCode.trim() !== '';

      if (isSegmentEndInInterval || (isSegmentEndInIntervalWithMargin && hasErrorCode)) {
        // Calcular los tiempos totales de los segmentos sin considerar la intersección con el intervalo
        const totalInteractTime = interactSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);
        const totalWrapUpTime = wrapUpSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);
        const totalAlertTime = alertSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);
        const totalHoldTime = holdSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);

        // Verificar si el conversationId existe en el siguiente intervalo de 30 minutos
        const nextIntervalStart = new Date(intervalEnd);
        const nextIntervalEnd = new Date(nextIntervalStart.getTime() + 30 * 60 * 1000); // 30 minutos después

        const nextIntervalConversations = this.allUserConversationsAggregates.filter(conversation => {
          return (
            conversation.userId === userId &&
            conversation.queueId == queueId &&
            new Date(conversation.interval.split('/')[0]) >= nextIntervalStart &&
            new Date(conversation.interval.split('/')[1]) <= nextIntervalEnd
          );
        });

        const conversationExistsInNextInterval = nextIntervalConversations.some(conversation => conversation.conversationId === conversation.conversationId);

        if (!conversationExistsInNextInterval) {
          // Crear la nueva métrica endedConversacion con el valor de segmentEnd y los tiempos totales de cada métrica
          metricsCopy.push({
            metric: 'endedConversacion',
            correct: true,
            value: lastSegment.segmentEnd,
            totals: {
              tTalk: totalInteractTime,
              tAcw: totalWrapUpTime,
              tAlert: totalAlertTime,
              tHold: totalHoldTime
            }
          });
        }
      }
    }

    // Asegurarse de devolver la conversación con la copia modificada de metrics
    return { ...conversation, metrics: metricsCopy, details, userPresences: presenceDetails };
  });

  return conversationsWithDetails;
},
    /*getConversationsPerUsersQueueAndIntervalALLNew(userId, queueId, interval) {
      const filteredConversations = this.allUserConversationsAggregates.filter(conversation => {
        return (
          conversation.userId === userId && conversation.queueId == queueId && conversation.interval == interval
        );
      });

      const presenceDetails = this.getPresenceDetailsForInterval(userId, interval);

      // Calcular el tiempo de intersección para cada presencia
      presenceDetails.forEach(presence => {
        let totalIntersectedTime = 0;

        filteredConversations.forEach(conversation => {
          const details = this.getConversationDetail(conversation.conversationId);
          const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
          const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];

          if (lastSession) {
            lastSession.segments.forEach(segment => {
              const segmentStart = new Date(segment.segmentStart);
              const segmentEnd = new Date(segment.segmentEnd);
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);

              // Calcular la intersección entre el segmento y la presencia
              const maxStart = new Date(Math.max(segmentStart.getTime(), presenceStart.getTime()));
              const minEnd = new Date(Math.min(segmentEnd.getTime(), presenceEnd.getTime()));

              if (minEnd > maxStart) {
                totalIntersectedTime += minEnd - maxStart;
              }
            });
          }
        });

        // Agregar la métrica de tiempo total interceptado en milisegundos
        presence.intersectedTimeWithConversations = totalIntersectedTime;
      });

      const conversationsWithDetails = filteredConversations.map(conversation => {
        const details = this.getConversationDetail(conversation.conversationId);
        const metricsCopy = [...conversation.metrics];
        
        const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
        const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];
        const interactSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'interact');
        const wrapUpSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'wrapup');
        const alertSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'alert');
        const holdSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'hold');

        const calculateSegmentTimeInPresence = (segments, presenceDetails) => {
          let totalOnQueueTime = 0;
          let totalAuxTime = 0;

          segments?.forEach(segment => {
            const segmentStart = new Date(segment.segmentStart);
            const segmentEnd = new Date(segment.segmentEnd);
            const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
            const maxStartSegment = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
            const minEndSegment = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));

            presenceDetails.forEach(presence => {
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);
              const maxStartPresence = new Date(Math.max(presenceStart.getTime(), maxStartSegment.getTime()));
              const minEndPresence = new Date(Math.min(presenceEnd.getTime(), minEndSegment.getTime()));

              if (minEndPresence > maxStartPresence) {
                const intersectTime = minEndPresence - maxStartPresence;
                if (presence.systemPresence === 'ON_QUEUE') {
                  totalOnQueueTime += intersectTime;
                } else {
                  totalAuxTime += intersectTime;
                }
              }
            });
          });

          return { totalOnQueueTime, totalAuxTime };
        };

        let totalDuration = 0;
        let totalAcw = 0;
        let totalAlert = 0;
        let totalHold = 0;

        const interactTimes = calculateSegmentTimeInPresence(interactSegments, presenceDetails);
        totalDuration += interactSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const wrapUpTimes = calculateSegmentTimeInPresence(wrapUpSegments, presenceDetails);
        totalAcw += wrapUpSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const alertTimes = calculateSegmentTimeInPresence(alertSegments, presenceDetails);
        totalAlert += alertSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const holdTimes = calculateSegmentTimeInPresence(holdSegments, presenceDetails);
        totalHold += holdSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        // Añadir nuevas métricas calculadas a metricsCopy
        if (totalDuration > 0) {
          metricsCopy.push({ metric: 'tTalk', correct: true, stats: { max: totalDuration, min: totalDuration, count: 1, sum: totalDuration }, onQueue: interactTimes.totalOnQueueTime, aux: interactTimes.totalAuxTime });
        }
        if (totalAcw > 0) {
          metricsCopy.push({ metric: 'tAcw', correct: true, stats: { max: totalAcw, min: totalAcw, count: 1, sum: totalAcw }, onQueue: wrapUpTimes.totalOnQueueTime, aux: wrapUpTimes.totalAuxTime });
        }
        if (totalAlert > 0) {
          metricsCopy.push({ metric: 'tAlert', correct: true, stats: { max: totalAlert, min: totalAlert, count: 1, sum: totalAlert }, onQueue: alertTimes.totalOnQueueTime, aux: alertTimes.totalAuxTime });
        }
        if (totalHold > 0) {
          metricsCopy.push({ metric: 'tHold', correct: true, stats: { max: totalHold, min: totalHold, count: 1, sum: totalHold }, onQueue: holdTimes.totalOnQueueTime, aux: holdTimes.totalAuxTime });
        }

        // Gestión de la métrica endedConversacion
        const lastSegment = lastSession?.segments?.[lastSession?.segments?.length - 1];
        if (lastSegment) {
          const segmentEnd = new Date(lastSegment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));

          // Validar si segmentEnd del último segmento se encuentra dentro del intervalo de consulta
          if (segmentEnd >= intervalStart && segmentEnd <= intervalEnd) {
            // Calcular los tiempos totales de los segmentos sin considerar la intersección con el intervalo
            const totalInteractTime = interactSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);
            const totalWrapUpTime = wrapUpSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);
            const totalAlertTime = alertSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);
            const totalHoldTime = holdSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);

            // Crear la nueva métrica endedConversacion con el valor de segmentEnd y los tiempos totales de cada métrica
            metricsCopy.push({
              metric: 'endedConversacion',
              correct: true,
              value: lastSegment.segmentEnd,
              totals: {
                tTalk: totalInteractTime,
                tAcw: totalWrapUpTime,
                tAlert: totalAlertTime,
                tHold: totalHoldTime
              }
            });
          }
        }

        // Asegurarse de devolver la conversación con la copia modificada de metrics
        return { ...conversation, metrics: metricsCopy, details, userPresences: presenceDetails };
      });

      return conversationsWithDetails;
    },*/

    getConversationsPerUsersQueueAndIntervalALL(userId, queueId, interval) {
      const filteredConversations = this.allUserConversationsAggregates.filter(conversation => {

              return (
                  conversation.userId === userId && conversation.queueId == queueId && conversation.interval == interval 
              );

      });

      const presenceDetails = this.getPresenceDetailsForInterval(userId, interval);

      // Calcular el tiempo de intersección para cada presencia
      presenceDetails.forEach(presence => {
        let totalIntersectedTime = 0;

        filteredConversations.forEach(conversation => {
          const details = this.getConversationDetail(conversation.conversationId);
          const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
          const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];

          if (lastSession) {
            lastSession.segments.forEach(segment => {
              const segmentStart = new Date(segment.segmentStart);
              const segmentEnd = new Date(segment.segmentEnd);
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);

              // Calcular la intersección entre el segmento y la presencia
              const maxStart = new Date(Math.max(segmentStart.getTime(), presenceStart.getTime()));
              const minEnd = new Date(Math.min(segmentEnd.getTime(), presenceEnd.getTime()));

              if (minEnd > maxStart) {
                totalIntersectedTime += minEnd - maxStart;
              }
            });
          }
        });

        // Agregar la métrica de tiempo total interceptado en milisegundos
        presence.intersectedTimeWithConversations = totalIntersectedTime;
      });

      const conversationsWithDetails = filteredConversations.map(conversation => {
        const details = this.getConversationDetail(conversation.conversationId);
        const metricsCopy = [...conversation.metrics];
        
        const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
        const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];
        const interactSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'interact');
        const wrapUpSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'wrapup');
        const alertSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'alert');
        const holdSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'hold');

        const calculateSegmentTimeInPresence = (segments, presenceDetails) => {
          let totalOnQueueTime = 0;
          let totalAuxTime = 0;

          segments?.forEach(segment => {
            const segmentStart = new Date(segment.segmentStart);
            const segmentEnd = new Date(segment.segmentEnd);
            const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
            const maxStartSegment = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
            const minEndSegment = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));

            presenceDetails.forEach(presence => {
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);
              const maxStartPresence = new Date(Math.max(presenceStart.getTime(), maxStartSegment.getTime()));
              const minEndPresence = new Date(Math.min(presenceEnd.getTime(), minEndSegment.getTime()));

              if (minEndPresence > maxStartPresence) {
                const intersectTime = minEndPresence - maxStartPresence;
                if (presence.systemPresence === 'ON_QUEUE') {
                  totalOnQueueTime += intersectTime;
                } else {
                  totalAuxTime += intersectTime;
                }
              }
            });
          });

          return { totalOnQueueTime, totalAuxTime };
        };

        let totalDuration = 0;
        let totalAcw = 0;
        let totalAlert = 0;
        let totalHold = 0;

        const interactTimes = calculateSegmentTimeInPresence(interactSegments, presenceDetails);
        totalDuration += interactSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const wrapUpTimes = calculateSegmentTimeInPresence(wrapUpSegments, presenceDetails);
        totalAcw += wrapUpSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const alertTimes = calculateSegmentTimeInPresence(alertSegments, presenceDetails);
        totalAlert += alertSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const holdTimes = calculateSegmentTimeInPresence(holdSegments, presenceDetails);
        totalHold += holdSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        // Añadir nuevas métricas calculadas a metricsCopy
        if (totalDuration > 0) {
          metricsCopy.push({ metric: 'tTalk', correct: true, stats: { max: totalDuration, min: totalDuration, count: 1, sum: totalDuration }, onQueue: interactTimes.totalOnQueueTime, aux: interactTimes.totalAuxTime });
        }
        if (totalAcw > 0) {
          metricsCopy.push({ metric: 'tAcw', correct: true, stats: { max: totalAcw, min: totalAcw, count: 1, sum: totalAcw }, onQueue: wrapUpTimes.totalOnQueueTime, aux: wrapUpTimes.totalAuxTime });
        }
        if (totalAlert > 0) {
          metricsCopy.push({ metric: 'tAlert', correct: true, stats: { max: totalAlert, min: totalAlert, count: 1, sum: totalAlert }, onQueue: alertTimes.totalOnQueueTime, aux: alertTimes.totalAuxTime });
        }
        if (totalHold > 0) {
          metricsCopy.push({ metric: 'tHold', correct: true, stats: { max: totalHold, min: totalHold, count: 1, sum: totalHold }, onQueue: holdTimes.totalOnQueueTime, aux: holdTimes.totalAuxTime });
        }

        // Gestión de la métrica endedConversacion
        const lastSegment = lastSession?.segments?.[lastSession?.segments?.length - 1];
        if (lastSegment) {
          const segmentEnd = new Date(lastSegment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));

          // Validar si segmentEnd del último segmento se encuentra dentro del intervalo de consulta
          if (segmentEnd >= intervalStart && segmentEnd <= intervalEnd) {
            // Crear la nueva métrica endedConversacion con el valor de segmentEnd
            metricsCopy.push({ metric: 'endedConversacion', correct: true, value: lastSegment.segmentEnd });
          }
        }

        // Asegurarse de devolver la conversación con la copia modificada de metrics
        return { ...conversation, metrics: metricsCopy, details, userPresences: presenceDetails };
      });

      return conversationsWithDetails;
    },

    getConversationsTOTALInboundOutbound(userId, interval, direction) {
      /*const filteredConversations = this.allUserConversationsAggregates.filter(conversation => {
          if (queueDifferent) {
              return (
                  conversation.userId === userId &&
                  (queueId !== null && conversation.queueId !== queueId) &&
                  conversation.interval == interval &&
                  (direction === null || conversation.direction == direction)
              );
          } else {
              return (
                  conversation.userId === userId &&
                  ((queueId === null &&  conversation.queueId === undefined) || conversation.queueId == queueId) &&
                  conversation.interval == interval &&
                  (direction === null || conversation.direction == direction)
              );
          }
      });*/

      const filteredConversations = this.allUserConversationsAggregates.filter(conversation => {

        return (
            conversation.userId === userId && conversation.interval === interval  && conversation.direction === direction  && (conversation.queueId === null || conversation.queueId === undefined)
        );

      });

      /*const conversationsWithDetails = filteredConversations.map(conversation => {
          const details = this.getConversationDetail(conversation.conversationId);
          return { ...conversation, details };
      });*/

      const presenceDetails = this.getPresenceDetailsForInterval(userId, interval);

      // Calcular el tiempo de intersección para cada presencia
      presenceDetails.forEach(presence => {
        let totalIntersectedTime = 0;

        filteredConversations.forEach(conversation => {
          const details = this.getConversationDetail(conversation.conversationId);
          const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
          const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];

          if (lastSession) {
            lastSession.segments.forEach(segment => {
              const segmentStart = new Date(segment.segmentStart);
              const segmentEnd = new Date(segment.segmentEnd);
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);

              // Calcular la intersección entre el segmento y la presencia
              const maxStart = new Date(Math.max(segmentStart.getTime(), presenceStart.getTime()));
              const minEnd = new Date(Math.min(segmentEnd.getTime(), presenceEnd.getTime()));

              if (minEnd > maxStart) {
                totalIntersectedTime += minEnd - maxStart;
              }
            });
          }
        });

        // Agregar la métrica de tiempo total interceptado en milisegundos
        presence.intersectedTimeWithConversations = totalIntersectedTime;
      });

      const conversationsWithDetails = filteredConversations.map(conversation => {
        const details = this.getConversationDetail(conversation.conversationId);
        const metricsCopy = [...conversation.metrics];
        
        const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
        const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];
        const interactSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'interact');
        const wrapUpSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'wrapup');
        const alertSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'alert');
        const holdSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'hold');

        const calculateSegmentTimeInPresence = (segments, presenceDetails) => {
          let totalOnQueueTime = 0;
          let totalAuxTime = 0;

          segments?.forEach(segment => {
            const segmentStart = new Date(segment.segmentStart);
            const segmentEnd = new Date(segment.segmentEnd);
            const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
            const maxStartSegment = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
            const minEndSegment = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));

            presenceDetails.forEach(presence => {
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);
              const maxStartPresence = new Date(Math.max(presenceStart.getTime(), maxStartSegment.getTime()));
              const minEndPresence = new Date(Math.min(presenceEnd.getTime(), minEndSegment.getTime()));

              if (minEndPresence > maxStartPresence) {
                const intersectTime = minEndPresence - maxStartPresence;
                if (presence.systemPresence === 'ON_QUEUE') {
                  totalOnQueueTime += intersectTime;
                } else {
                  totalAuxTime += intersectTime;
                }
              }
            });
          });

          return { totalOnQueueTime, totalAuxTime };
        };

        let totalDuration = 0;
        let totalAcw = 0;
        let totalAlert = 0;
        let totalHold = 0;

        const interactTimes = calculateSegmentTimeInPresence(interactSegments, presenceDetails);
        totalDuration += interactSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const wrapUpTimes = calculateSegmentTimeInPresence(wrapUpSegments, presenceDetails);
        totalAcw += wrapUpSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const alertTimes = calculateSegmentTimeInPresence(alertSegments, presenceDetails);
        totalAlert += alertSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const holdTimes = calculateSegmentTimeInPresence(holdSegments, presenceDetails);
        totalHold += holdSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        // Añadir nuevas métricas calculadas a metricsCopy
        if (totalDuration > 0) {
          metricsCopy.push({ metric: 'tTalk', correct: true, stats: { max: totalDuration, min: totalDuration, count: 1, sum: totalDuration }, onQueue: interactTimes.totalOnQueueTime, aux: interactTimes.totalAuxTime });
        }
        if (totalAcw > 0) {
          metricsCopy.push({ metric: 'tAcw', correct: true, stats: { max: totalAcw, min: totalAcw, count: 1, sum: totalAcw }, onQueue: wrapUpTimes.totalOnQueueTime, aux: wrapUpTimes.totalAuxTime });
        }
        if (totalAlert > 0) {
          metricsCopy.push({ metric: 'tAlert', correct: true, stats: { max: totalAlert, min: totalAlert, count: 1, sum: totalAlert }, onQueue: alertTimes.totalOnQueueTime, aux: alertTimes.totalAuxTime });
        }
        if (totalHold > 0) {
          metricsCopy.push({ metric: 'tHold', correct: true, stats: { max: totalHold, min: totalHold, count: 1, sum: totalHold }, onQueue: holdTimes.totalOnQueueTime, aux: holdTimes.totalAuxTime });
        }

        // Gestión de la métrica endedConversacion
        const lastSegment = lastSession?.segments?.[lastSession?.segments?.length - 1];
        if (lastSegment) {
          const segmentEnd = new Date(lastSegment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));

          // Validar si segmentEnd del último segmento se encuentra dentro del intervalo de consulta
          if (segmentEnd >= intervalStart && segmentEnd <= intervalEnd) {
            // Crear la nueva métrica endedConversacion con el valor de segmentEnd
            metricsCopy.push({ metric: 'endedConversacion', correct: true, value: lastSegment.segmentEnd });
          }
        }

        // Asegurarse de devolver la conversación con la copia modificada de metrics
        return { ...conversation, metrics: metricsCopy, details, userPresences: presenceDetails };
      });

      return conversationsWithDetails;
    },


    getConversationsTotalALL(userId, interval) {
      const filteredConversations = this.allUserConversationsAggregates.filter(conversation => {

              return (
                  conversation.userId === userId && conversation.interval == interval 
              );

      });

      /*const conversationsWithDetails = filteredConversations.map(conversation => {
          const details = this.getConversationDetail(conversation.conversationId);
          return { ...conversation, details };
      });*/

      const presenceDetails = this.getPresenceDetailsForInterval(userId, interval);

      // Calcular el tiempo de intersección para cada presencia
      presenceDetails.forEach(presence => {
        let totalIntersectedTime = 0;

        filteredConversations.forEach(conversation => {
          const details = this.getConversationDetail(conversation.conversationId);
          const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
          const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];

          if (lastSession) {
            lastSession.segments.forEach(segment => {
              const segmentStart = new Date(segment.segmentStart);
              const segmentEnd = new Date(segment.segmentEnd);
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);

              // Calcular la intersección entre el segmento y la presencia
              const maxStart = new Date(Math.max(segmentStart.getTime(), presenceStart.getTime()));
              const minEnd = new Date(Math.min(segmentEnd.getTime(), presenceEnd.getTime()));

              if (minEnd > maxStart) {
                totalIntersectedTime += minEnd - maxStart;
              }
            });
          }
        });

        // Agregar la métrica de tiempo total interceptado en milisegundos
        presence.intersectedTimeWithConversations = totalIntersectedTime;
      });

      const conversationsWithDetails = filteredConversations.map(conversation => {
        const details = this.getConversationDetail(conversation.conversationId);
        const metricsCopy = [...conversation.metrics];
        
        const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
        const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];
        const interactSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'interact');
        const wrapUpSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'wrapup');
        const alertSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'alert');
        const holdSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'hold');

        const calculateSegmentTimeInPresence = (segments, presenceDetails) => {
          let totalOnQueueTime = 0;
          let totalAuxTime = 0;

          segments?.forEach(segment => {
            const segmentStart = new Date(segment.segmentStart);
            const segmentEnd = new Date(segment.segmentEnd);
            const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
            const maxStartSegment = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
            const minEndSegment = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));

            presenceDetails.forEach(presence => {
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);
              const maxStartPresence = new Date(Math.max(presenceStart.getTime(), maxStartSegment.getTime()));
              const minEndPresence = new Date(Math.min(presenceEnd.getTime(), minEndSegment.getTime()));

              if (minEndPresence > maxStartPresence) {
                const intersectTime = minEndPresence - maxStartPresence;
                if (presence.systemPresence === 'ON_QUEUE') {
                  totalOnQueueTime += intersectTime;
                } else {
                  totalAuxTime += intersectTime;
                }
              }
            });
          });

          return { totalOnQueueTime, totalAuxTime };
        };

        let totalDuration = 0;
        let totalAcw = 0;
        let totalAlert = 0;
        let totalHold = 0;

        const interactTimes = calculateSegmentTimeInPresence(interactSegments, presenceDetails);
        totalDuration += interactSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const wrapUpTimes = calculateSegmentTimeInPresence(wrapUpSegments, presenceDetails);
        totalAcw += wrapUpSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const alertTimes = calculateSegmentTimeInPresence(alertSegments, presenceDetails);
        totalAlert += alertSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const holdTimes = calculateSegmentTimeInPresence(holdSegments, presenceDetails);
        totalHold += holdSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        // Añadir nuevas métricas calculadas a metricsCopy
        if (totalDuration > 0) {
          metricsCopy.push({ metric: 'tTalk', correct: true, stats: { max: totalDuration, min: totalDuration, count: 1, sum: totalDuration }, onQueue: interactTimes.totalOnQueueTime, aux: interactTimes.totalAuxTime });
        }
        if (totalAcw > 0) {
          metricsCopy.push({ metric: 'tAcw', correct: true, stats: { max: totalAcw, min: totalAcw, count: 1, sum: totalAcw }, onQueue: wrapUpTimes.totalOnQueueTime, aux: wrapUpTimes.totalAuxTime });
        }
        if (totalAlert > 0) {
          metricsCopy.push({ metric: 'tAlert', correct: true, stats: { max: totalAlert, min: totalAlert, count: 1, sum: totalAlert }, onQueue: alertTimes.totalOnQueueTime, aux: alertTimes.totalAuxTime });
        }
        if (totalHold > 0) {
          metricsCopy.push({ metric: 'tHold', correct: true, stats: { max: totalHold, min: totalHold, count: 1, sum: totalHold }, onQueue: holdTimes.totalOnQueueTime, aux: holdTimes.totalAuxTime });
        }

        // Gestión de la métrica endedConversacion
        const lastSegment = lastSession?.segments?.[lastSession?.segments?.length - 1];
        if (lastSegment) {
          const segmentEnd = new Date(lastSegment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));

          // Validar si segmentEnd del último segmento se encuentra dentro del intervalo de consulta
          if (segmentEnd >= intervalStart && segmentEnd <= intervalEnd) {
            // Crear la nueva métrica endedConversacion con el valor de segmentEnd
            metricsCopy.push({ metric: 'endedConversacion', correct: true, value: lastSegment.segmentEnd });
          }
        }

        // Asegurarse de devolver la conversación con la copia modificada de metrics
        return { ...conversation, metrics: metricsCopy, details, userPresences: presenceDetails };
      });

      return conversationsWithDetails;
    },

    sumarI_AvailTimeUnicos(itemsForQueues) {
      // Filtrar y mapear los valores asegurando que sean números válidos
      const i_availtimeUnicos = [...new Set(itemsForQueues.map(item => {
        const value = Number(item.i_availtime);
        return isNaN(value) ? 0 : value; // Convertir a número y manejar NaN
      }))];

      // Encontrar el valor único mayor
      const maxValor = Math.max(...i_availtimeUnicos);

      return maxValor;
    },

    sumarI_StaffUnicos(itemsForQueues) {
      // Filtrar y mapear los valores asegurando que sean números válidos
      const i_availtimeUnicos = [...new Set(itemsForQueues.map(item => {
        const value = Number(item.i_stafftime);
        return isNaN(value) ? 0 : value; // Convertir a número y manejar NaN
      }))];

      // Encontrar el valor único mayor
      const maxValor = Math.max(...i_availtimeUnicos);

      return maxValor;
    },

    contarI_AvailTimeUnicos(itemsForQueues) {
      const i_availtimeUnicos = [...new Set(itemsForQueues.map(item => item.i_availtime))];
      return i_availtimeUnicos.length;
    },

    contarI_StaffUnicos(itemsForQueues) {
      const i_availtimeUnicos = [...new Set(itemsForQueues.map(item => item.i_stafftime))];
      return i_availtimeUnicos.length;
    },

    //const contadorOthertimeUnicos = this.contarI_OtherTimeUnicos(itemsForQueues)
    //const sumadorOthertimeUnicos = this.sumarI_OtherTimeUnicos(itemsForQueues)

    sumarI_OtherTimeUnicos(itemsForQueues) {
      // Filtrar y mapear los valores asegurando que sean números válidos
      const i_availtimeUnicos = [...new Set(itemsForQueues.map(item => {
        const value = Number(item.i_othertime);
        return isNaN(value) ? 0 : value; // Convertir a número y manejar NaN
      }))];

      // Encontrar el valor único mayor
      const maxValor = Math.max(...i_availtimeUnicos);

      return maxValor;
    },

    contarI_OtherTimeUnicos(itemsForQueues) {
      const i_availtimeUnicos = [...new Set(itemsForQueues.map(item => item.i_othertime))];
      return i_availtimeUnicos.length;
    },

    /*sumarIAuxtimeUnicos(itemsForQueues) {
      const i_auxtimeUnicos = [...new Set(itemsForQueues.map(item => item.i_auxtime))];
      const suma = i_auxtimeUnicos.reduce((acumulado, valorActual) => acumulado + valorActual, 0);
      return suma;
    },*/

    sumarIAuxtimeunicos(itemsForQueues){
      // Filtrar y mapear los valores asegurando que sean números válidos
      const i_availtimeUnicos = [...new Set(itemsForQueues.map(item => {
        const value = Number(item.i_auxtime);
        return isNaN(value) ? 0 : value; // Convertir a número y manejar NaN
      }))];

      // Encontrar el valor único mayor
      const maxValor = Math.max(...i_availtimeUnicos);

      return maxValor;
    },

    contarIAuxtimeUnicos(itemsForQueues) {
      const i_auxtimeUnicos = [...new Set(itemsForQueues.map(item => item.i_auxtime))];
      return i_auxtimeUnicos.length;
    },

    /*sumarIOthertime(itemsForQueues) {
      const i_othertimeUnicos = [...new Set(itemsForQueues.map(item => item.i_othertime))];
      const suma = i_othertimeUnicos.reduce((acumulado, valorActual) => {
        const valorNumerico = Number(valorActual); // Convertir el valor actual a número
        return acumulado + valorNumerico;
      }, 0);
      return suma;
    },

    sumarIOthertime(itemsForQueues) {
      const i_othertimeUnicos = [...new Set(itemsForQueues.map(item => item.i_othertime))];
      const suma = i_othertimeUnicos.reduce((acumulado, valorActual) => {
        const valorNumerico = Number(valorActual); // Convertir el valor actual a número
        return acumulado + valorNumerico;
      }, 0);
      const promedio = suma / i_othertimeUnicos.length; // Dividir la suma entre la cantidad de datos únicos
      return promedio;
    },*/
    

    sumarIOthertime(itemsForQueues, avail, aux, staff) {
      if(avail>0 && aux>0){
        return staff-(avail+aux)
      }
      const i_othertimeUnicos = [...new Set(itemsForQueues.map(item => Number(item.i_othertime)))];
      const maximo = Math.max(...i_othertimeUnicos);
      return maximo;
    },

    getConversationsPerUsersQueueAndIntervalNONACD(userId, queueId = null, interval, direction = null) {
      const filteredConversations = this.allUserConversationsAggregates.filter(conversation => {

              return (
                  conversation.userId === userId &&
                  (queueId === null || conversation.queueId === undefined) &&
                  conversation.interval == interval &&
                  (direction === null || conversation.direction == direction)
              );
         
      });

      /*const conversationsWithDetails = filteredConversations.map(conversation => {
          const details = this.getConversationDetail(conversation.conversationId);
          return { ...conversation, details };
      });*/

      const presenceDetails = this.getPresenceDetailsForInterval(userId, interval);

      // Calcular el tiempo de intersección para cada presencia
      presenceDetails.forEach(presence => {
        let totalIntersectedTime = 0;

        filteredConversations.forEach(conversation => {
          const details = this.getConversationDetail(conversation.conversationId);
          const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
          const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];

          if (lastSession) {
            lastSession.segments.forEach(segment => {
              const segmentStart = new Date(segment.segmentStart);
              const segmentEnd = new Date(segment.segmentEnd);
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);

              // Calcular la intersección entre el segmento y la presencia
              const maxStart = new Date(Math.max(segmentStart.getTime(), presenceStart.getTime()));
              const minEnd = new Date(Math.min(segmentEnd.getTime(), presenceEnd.getTime()));

              if (minEnd > maxStart) {
                totalIntersectedTime += minEnd - maxStart;
              }
            });
          }
        });

        // Agregar la métrica de tiempo total interceptado en milisegundos
        presence.intersectedTimeWithConversations = totalIntersectedTime;
      });

      const conversationsWithDetails = filteredConversations.map(conversation => {
        const details = this.getConversationDetail(conversation.conversationId);
        const metricsCopy = [...conversation.metrics];
        
        const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
        const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];
        const interactSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'interact');
        const wrapUpSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'wrapup');
        const alertSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'alert');
        const holdSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'hold');

        const calculateSegmentTimeInPresence = (segments, presenceDetails) => {
          let totalOnQueueTime = 0;
          let totalAuxTime = 0;

          segments?.forEach(segment => {
            const segmentStart = new Date(segment.segmentStart);
            const segmentEnd = new Date(segment.segmentEnd);
            const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
            const maxStartSegment = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
            const minEndSegment = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));

            presenceDetails.forEach(presence => {
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);
              const maxStartPresence = new Date(Math.max(presenceStart.getTime(), maxStartSegment.getTime()));
              const minEndPresence = new Date(Math.min(presenceEnd.getTime(), minEndSegment.getTime()));

              if (minEndPresence > maxStartPresence) {
                const intersectTime = minEndPresence - maxStartPresence;
                if (presence.systemPresence === 'ON_QUEUE') {
                  totalOnQueueTime += intersectTime;
                } else {
                  totalAuxTime += intersectTime;
                }
              }
            });
          });

          return { totalOnQueueTime, totalAuxTime };
        };

        let totalDuration = 0;
        let totalAcw = 0;
        let totalAlert = 0;
        let totalHold = 0;

        const interactTimes = calculateSegmentTimeInPresence(interactSegments, presenceDetails);
        totalDuration += interactSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const wrapUpTimes = calculateSegmentTimeInPresence(wrapUpSegments, presenceDetails);
        totalAcw += wrapUpSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const alertTimes = calculateSegmentTimeInPresence(alertSegments, presenceDetails);
        totalAlert += alertSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const holdTimes = calculateSegmentTimeInPresence(holdSegments, presenceDetails);
        totalHold += holdSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        // Añadir nuevas métricas calculadas a metricsCopy
        if (totalDuration > 0) {
          metricsCopy.push({ metric: 'tTalk', correct: true, stats: { max: totalDuration, min: totalDuration, count: 1, sum: totalDuration }, onQueue: interactTimes.totalOnQueueTime, aux: interactTimes.totalAuxTime });
        }
        if (totalAcw > 0) {
          metricsCopy.push({ metric: 'tAcw', correct: true, stats: { max: totalAcw, min: totalAcw, count: 1, sum: totalAcw }, onQueue: wrapUpTimes.totalOnQueueTime, aux: wrapUpTimes.totalAuxTime });
        }
        if (totalAlert > 0) {
          metricsCopy.push({ metric: 'tAlert', correct: true, stats: { max: totalAlert, min: totalAlert, count: 1, sum: totalAlert }, onQueue: alertTimes.totalOnQueueTime, aux: alertTimes.totalAuxTime });
        }
        if (totalHold > 0) {
          metricsCopy.push({ metric: 'tHold', correct: true, stats: { max: totalHold, min: totalHold, count: 1, sum: totalHold }, onQueue: holdTimes.totalOnQueueTime, aux: holdTimes.totalAuxTime });
        }

        // Gestión de la métrica endedConversacion
        const lastSegment = lastSession?.segments?.[lastSession?.segments?.length - 1];
        if (lastSegment) {
          const segmentEnd = new Date(lastSegment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));

          // Validar si segmentEnd del último segmento se encuentra dentro del intervalo de consulta
          if (segmentEnd >= intervalStart && segmentEnd <= intervalEnd) {
            // Crear la nueva métrica endedConversacion con el valor de segmentEnd
            metricsCopy.push({ metric: 'endedConversacion', correct: true, value: lastSegment.segmentEnd });
          }
        }

        // Asegurarse de devolver la conversación con la copia modificada de metrics
        return { ...conversation, metrics: metricsCopy, details, userPresences: presenceDetails };
      });

      return conversationsWithDetails;
    },

    getConversationsPerUsersQueueAndIntervalNew(userId, queueId = null, interval, direction = null, queueDifferent = false) {
      const filteredConversations = this.allUserConversationsAggregates.filter(conversation => {
          if (queueDifferent) {
              return (
                  conversation.userId === userId &&
                  (queueId !== null && conversation.queueId !== queueId) &&
                  conversation.interval == interval &&
                  (direction === null || conversation.direction == direction)
              );
          } else {
              if(direction!=='outbound'){
                return (
                    conversation.userId === userId &&
                    ((queueId === null &&  conversation.queueId === undefined) || conversation.queueId == queueId) &&
                    conversation.interval == interval &&
                    //(direction === null || conversation.direction == direction)
                    (conversation.direction == direction)
                );
              }else{
                return (
                  conversation.userId === userId &&
                  ((queueId === null &&  conversation.queueId === undefined) || conversation.queueId == queueId) &&
                  conversation.interval == interval &&
                  //(direction === null || conversation.direction == direction)
                  (conversation.direction == direction)
              );
              }
              
          }
      });

      const presenceDetails = this.getPresenceDetailsForInterval(userId, interval);

      // Calcular el tiempo de intersección para cada presencia
      presenceDetails.forEach(presence => {
        let totalIntersectedTime = 0;

        filteredConversations.forEach(conversation => {
          const details = this.getConversationDetail(conversation.conversationId);
          const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
          const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];

          if (lastSession) {
            lastSession.segments.forEach(segment => {
              const segmentStart = new Date(segment.segmentStart);
              const segmentEnd = new Date(segment.segmentEnd);
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);

              // Calcular la intersección entre el segmento y la presencia
              const maxStart = new Date(Math.max(segmentStart.getTime(), presenceStart.getTime()));
              const minEnd = new Date(Math.min(segmentEnd.getTime(), presenceEnd.getTime()));

              if (minEnd > maxStart) {
                totalIntersectedTime += minEnd - maxStart;
              }
            });
          }
        });

        // Agregar la métrica de tiempo total interceptado en milisegundos
        presence.intersectedTimeWithConversations = totalIntersectedTime;
      });

      const conversationsWithDetails = filteredConversations.map(conversation => {
        const details = this.getConversationDetail(conversation.conversationId);
        const metricsCopy = [...conversation.metrics];
        
        const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
        const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];
        const interactSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'interact');
        const wrapUpSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'wrapup');
        const alertSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'alert');
        const holdSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'hold');

        const calculateSegmentTimeInPresence = (segments, presenceDetails) => {
          let totalOnQueueTime = 0;
          let totalAuxTime = 0;

          segments?.forEach(segment => {
            const segmentStart = new Date(segment.segmentStart);
            const segmentEnd = new Date(segment.segmentEnd);
            const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
            const maxStartSegment = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
            const minEndSegment = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));

            presenceDetails.forEach(presence => {
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);
              const maxStartPresence = new Date(Math.max(presenceStart.getTime(), maxStartSegment.getTime()));
              const minEndPresence = new Date(Math.min(presenceEnd.getTime(), minEndSegment.getTime()));

              if (minEndPresence > maxStartPresence) {
                const intersectTime = minEndPresence - maxStartPresence;
                if (presence.systemPresence === 'ON_QUEUE') {
                  totalOnQueueTime += intersectTime;
                } else {
                  totalAuxTime += intersectTime;
                }
              }
            });
          });

          return { totalOnQueueTime, totalAuxTime };
        };

        let totalDuration = 0;
        let totalAcw = 0;
        let totalAlert = 0;
        let totalHold = 0;

        const interactTimes = calculateSegmentTimeInPresence(interactSegments, presenceDetails);
        totalDuration += interactSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const wrapUpTimes = calculateSegmentTimeInPresence(wrapUpSegments, presenceDetails);
        totalAcw += wrapUpSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const alertTimes = calculateSegmentTimeInPresence(alertSegments, presenceDetails);
        totalAlert += alertSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const holdTimes = calculateSegmentTimeInPresence(holdSegments, presenceDetails);
        totalHold += holdSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        // Añadir nuevas métricas calculadas a metricsCopy
        if (totalDuration > 0) {
          metricsCopy.push({ metric: 'tTalk', correct: true, stats: { max: totalDuration, min: totalDuration, count: 1, sum: totalDuration }, onQueue: interactTimes.totalOnQueueTime, aux: interactTimes.totalAuxTime });
        }
        if (totalAcw > 0) {
          metricsCopy.push({ metric: 'tAcw', correct: true, stats: { max: totalAcw, min: totalAcw, count: 1, sum: totalAcw }, onQueue: wrapUpTimes.totalOnQueueTime, aux: wrapUpTimes.totalAuxTime });
        }
        if (totalAlert > 0) {
          metricsCopy.push({ metric: 'tAlert', correct: true, stats: { max: totalAlert, min: totalAlert, count: 1, sum: totalAlert }, onQueue: alertTimes.totalOnQueueTime, aux: alertTimes.totalAuxTime });
        }
        if (totalHold > 0) {
          metricsCopy.push({ metric: 'tHold', correct: true, stats: { max: totalHold, min: totalHold, count: 1, sum: totalHold }, onQueue: holdTimes.totalOnQueueTime, aux: holdTimes.totalAuxTime });
        }

        // Gestión de la métrica endedConversacion
        const lastSegment = lastSession?.segments?.[lastSession?.segments?.length - 1];
        if (lastSegment) {
          const segmentEnd = new Date(lastSegment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));

          // Validar si segmentEnd del último segmento se encuentra dentro del intervalo de consulta
          if (segmentEnd >= intervalStart && segmentEnd <= intervalEnd) {
            // Crear la nueva métrica endedConversacion con el valor de segmentEnd
            metricsCopy.push({ metric: 'endedConversacion', correct: true, value: lastSegment.segmentEnd });
          }
        }

        // Asegurarse de devolver la conversación con la copia modificada de metrics
        return { ...conversation, metrics: metricsCopy, details, userPresences: presenceDetails };
      });

      return conversationsWithDetails;
    },

    /*getConversationsPerUsersQueueAndInterval(userId, queueId = null, interval, direction = null, queueDifferent = false) {
      const filteredConversations = this.allUserConversationsAggregates.filter(conversation => {
          if (queueDifferent) {
              return (
                  conversation.userId === userId &&
                  (queueId !== null && conversation.queueId !== queueId) &&
                  conversation.interval == interval &&
                  (direction === null || conversation.direction == direction)
              );
          } else {
          
                return (
                    conversation.userId === userId &&
                    ((queueId === null ||  conversation.queueId === undefined) || conversation.queueId === queueId) &&
                    conversation.interval == interval &&
                    (direction !== null && conversation.direction === direction)
                );

          }
      });

      const presenceDetails = this.getPresenceDetailsForInterval(userId, interval);

      const conversationsWithDetails = filteredConversations.map(conversation => {
        const details = this.getConversationDetail(conversation.conversationId);

        const metricsCopy = [...conversation.metrics];
        
        const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
        const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];
        const interactSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'interact');
        const wrapUpSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'wrapup');
        const alertSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'alert');
        const holdSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'hold');

        const calculateSegmentTimeInPresence = (segments, presenceDetails) => {
          let totalOnQueueTime = 0;
          let totalAuxTime = 0;

          segments?.forEach(segment => {
            const segmentStart = new Date(segment.segmentStart);
            const segmentEnd = new Date(segment.segmentEnd);
            const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
            const maxStartSegment = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
            const minEndSegment = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));

            presenceDetails.forEach(presence => {
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);
              const maxStartPresence = new Date(Math.max(presenceStart.getTime(), maxStartSegment.getTime()));
              const minEndPresence = new Date(Math.min(presenceEnd.getTime(), minEndSegment.getTime()));

              if (minEndPresence > maxStartPresence) {
                const intersectTime = minEndPresence - maxStartPresence;
                if (presence.systemPresence === 'ON_QUEUE') {
                  totalOnQueueTime += intersectTime;
                } else {
                  totalAuxTime += intersectTime;
                }
              }
            });
          });

          return { totalOnQueueTime, totalAuxTime };
        };

        let totalDuration = 0;
        let totalAcw = 0;
        let totalAlert = 0;
        let totalHold = 0;

        const interactTimes = calculateSegmentTimeInPresence(interactSegments, presenceDetails);
        totalDuration += interactSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const wrapUpTimes = calculateSegmentTimeInPresence(wrapUpSegments, presenceDetails);
        totalAcw += wrapUpSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const alertTimes = calculateSegmentTimeInPresence(alertSegments, presenceDetails);
        totalAlert += alertSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const holdTimes = calculateSegmentTimeInPresence(holdSegments, presenceDetails);
        totalHold += holdSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        // Añadir nuevas métricas calculadas a metricsCopy
        if (totalDuration > 0) {
          metricsCopy.push({ metric: 'tTalk', correct: true, stats: { max: totalDuration, min: totalDuration, count: 1, sum: totalDuration }, onQueue: interactTimes.totalOnQueueTime, aux: interactTimes.totalAuxTime });
        }
        if (totalAcw > 0) {
          metricsCopy.push({ metric: 'tAcw', correct: true, stats: { max: totalAcw, min: totalAcw, count: 1, sum: totalAcw }, onQueue: wrapUpTimes.totalOnQueueTime, aux: wrapUpTimes.totalAuxTime });
        }
        if (totalAlert > 0) {
          metricsCopy.push({ metric: 'tAlert', correct: true, stats: { max: totalAlert, min: totalAlert, count: 1, sum: totalAlert }, onQueue: alertTimes.totalOnQueueTime, aux: alertTimes.totalAuxTime });
        }
        if (totalHold > 0) {
          metricsCopy.push({ metric: 'tHold', correct: true, stats: { max: totalHold, min: totalHold, count: 1, sum: totalHold }, onQueue: holdTimes.totalOnQueueTime, aux: holdTimes.totalAuxTime });
        }

        // Gestión de la métrica endedConversacion
        const lastSegment = lastSession?.segments?.[lastSession?.segments?.length - 1];
        if (lastSegment) {
          const segmentEnd = new Date(lastSegment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));

          // Validar si segmentEnd del último segmento se encuentra dentro del intervalo de consulta
          if (segmentEnd >= intervalStart && segmentEnd <= intervalEnd) {
            // Crear la nueva métrica endedConversacion con el valor de segmentEnd
            metricsCopy.push({ metric: 'endedConversacion', correct: true, value: lastSegment.segmentEnd });
          }
        }

        // Asegurarse de devolver la conversación con la copia modificada de metrics
        return { ...conversation, metrics: metricsCopy, details, userPresences: presenceDetails };
      });

      return conversationsWithDetails;
    },*/

    getConversationsPerUsersQueueAndInterval(userId, queueId = null, interval, direction = null, queueDifferent = false) {
      const filteredConversations = this.allUserConversationsAggregates.filter(conversation => {
        if (queueDifferent) {
          return (
            conversation.userId === userId &&
            (queueId !== null && conversation.queueId !== queueId) &&
            conversation.interval == interval &&
            (direction === null || conversation.direction == direction)
          );
        } else {
          return (
            conversation.userId === userId &&
            ((queueId === null || conversation.queueId === undefined) || conversation.queueId === queueId) &&
            conversation.interval == interval &&
            (direction !== null && conversation.direction === direction)
          );
        }
      });

      const presenceDetails = this.getPresenceDetailsForInterval(userId, interval);

      // Calcular el tiempo de intersección para cada presencia
      presenceDetails.forEach(presence => {
        let totalIntersectedTime = 0;

        filteredConversations.forEach(conversation => {
          const details = this.getConversationDetail(conversation.conversationId);
          const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
          const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];

          if (lastSession) {
            lastSession.segments.forEach(segment => {
              const segmentStart = new Date(segment.segmentStart);
              const segmentEnd = new Date(segment.segmentEnd);
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);

              // Calcular la intersección entre el segmento y la presencia
              const maxStart = new Date(Math.max(segmentStart.getTime(), presenceStart.getTime()));
              const minEnd = new Date(Math.min(segmentEnd.getTime(), presenceEnd.getTime()));

              if (minEnd > maxStart) {
                totalIntersectedTime += minEnd - maxStart;
              }
            });
          }
        });

        // Agregar la métrica de tiempo total interceptado en milisegundos
        presence.intersectedTimeWithConversations = totalIntersectedTime;
      });

      const conversationsWithDetails = filteredConversations.map(conversation => {
        const details = this.getConversationDetail(conversation.conversationId);
        const metricsCopy = [...conversation.metrics];
        
        const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
        const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];
        const interactSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'interact');
        const wrapUpSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'wrapup');
        const alertSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'alert');
        const holdSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'hold');

        const calculateSegmentTimeInPresence = (segments, presenceDetails) => {
          let totalOnQueueTime = 0;
          let totalAuxTime = 0;

          segments?.forEach(segment => {
            const segmentStart = new Date(segment.segmentStart);
            const segmentEnd = new Date(segment.segmentEnd);
            const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
            const maxStartSegment = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
            const minEndSegment = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));

            presenceDetails.forEach(presence => {
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);
              const maxStartPresence = new Date(Math.max(presenceStart.getTime(), maxStartSegment.getTime()));
              const minEndPresence = new Date(Math.min(presenceEnd.getTime(), minEndSegment.getTime()));

              if (minEndPresence > maxStartPresence) {
                const intersectTime = minEndPresence - maxStartPresence;
                if (presence.systemPresence === 'ON_QUEUE') {
                  totalOnQueueTime += intersectTime;
                } else {
                  totalAuxTime += intersectTime;
                }
              }
            });
          });

          return { totalOnQueueTime, totalAuxTime };
        };

        let totalDuration = 0;
        let totalAcw = 0;
        let totalAlert = 0;
        let totalHold = 0;

        const interactTimes = calculateSegmentTimeInPresence(interactSegments, presenceDetails);
        totalDuration += interactSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const wrapUpTimes = calculateSegmentTimeInPresence(wrapUpSegments, presenceDetails);
        totalAcw += wrapUpSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const alertTimes = calculateSegmentTimeInPresence(alertSegments, presenceDetails);
        totalAlert += alertSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const holdTimes = calculateSegmentTimeInPresence(holdSegments, presenceDetails);
        totalHold += holdSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        // Añadir nuevas métricas calculadas a metricsCopy
        if (totalDuration > 0) {
          metricsCopy.push({ metric: 'tTalk', correct: true, stats: { max: totalDuration, min: totalDuration, count: 1, sum: totalDuration }, onQueue: interactTimes.totalOnQueueTime, aux: interactTimes.totalAuxTime });
        }
        if (totalAcw > 0) {
          metricsCopy.push({ metric: 'tAcw', correct: true, stats: { max: totalAcw, min: totalAcw, count: 1, sum: totalAcw }, onQueue: wrapUpTimes.totalOnQueueTime, aux: wrapUpTimes.totalAuxTime });
        }
        if (totalAlert > 0) {
          metricsCopy.push({ metric: 'tAlert', correct: true, stats: { max: totalAlert, min: totalAlert, count: 1, sum: totalAlert }, onQueue: alertTimes.totalOnQueueTime, aux: alertTimes.totalAuxTime });
        }
        if (totalHold > 0) {
          metricsCopy.push({ metric: 'tHold', correct: true, stats: { max: totalHold, min: totalHold, count: 1, sum: totalHold }, onQueue: holdTimes.totalOnQueueTime, aux: holdTimes.totalAuxTime });
        }

        // Gestión de la métrica endedConversacion
        const lastSegment = lastSession?.segments?.[lastSession?.segments?.length - 1];
        if (lastSegment) {
          const segmentEnd = new Date(lastSegment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));

          // Validar si segmentEnd del último segmento se encuentra dentro del intervalo de consulta
          if (segmentEnd >= intervalStart && segmentEnd <= intervalEnd) {
            // Crear la nueva métrica endedConversacion con el valor de segmentEnd
            metricsCopy.push({ metric: 'endedConversacion', correct: true, value: lastSegment.segmentEnd });
          }
        }

        // Asegurarse de devolver la conversación con la copia modificada de metrics
        return { ...conversation, metrics: metricsCopy, details, userPresences: presenceDetails };
      });

      return conversationsWithDetails;
    },

    getConversationsPerUsersQueueAndIntervalNew2(userId, queueId = null, interval, direction = null, queueDifferent = false) {
      const filteredConversations = this.allUserConversationsAggregates.filter(conversation => {
        if (queueDifferent) {
          return (
            conversation.userId === userId &&
            (queueId !== null && conversation.queueId !== queueId) &&
            conversation.interval == interval &&
            (direction === null || conversation.direction == direction || conversation.originatingDirection == direction)
          );
        } else {
          return (
            conversation.userId === userId &&
            ((queueId === null || conversation.queueId === undefined) || conversation.queueId === queueId) &&
            conversation.interval == interval &&
            (direction !== null && (conversation.direction === direction || conversation.originatingDirection == direction))
          );
        }
      });

      const presenceDetails = this.getPresenceDetailsForInterval(userId, interval);

      // Calcular el tiempo de intersección para cada presencia
      presenceDetails.forEach(presence => {
          let totalIntersectedTime = 0;

          filteredConversations.forEach(conversation => {
              const details = this.getConversationDetail(conversation.conversationId);
              const participants = details?.participants?.filter(participant => participant.purpose === 'agent' && participant.userId === userId);

              participants?.forEach(agentParticipant => {
                  const lastSession = agentParticipant?.sessions[agentParticipant.sessions.length - 1];

                  if (lastSession) {
                      lastSession.segments.forEach(segment => {
                          const segmentStart = new Date(segment.segmentStart);
                          const segmentEnd = new Date(segment.segmentEnd);
                          const presenceStart = new Date(presence.startTime);
                          const presenceEnd = new Date(presence.endTime);

                          // Calcular la intersección entre el segmento y la presencia
                          const maxStart = new Date(Math.max(segmentStart.getTime(), presenceStart.getTime()));
                          const minEnd = new Date(Math.min(segmentEnd.getTime(), presenceEnd.getTime()));

                          if (minEnd > maxStart) {
                              totalIntersectedTime += minEnd - maxStart;
                          }
                      });
                  }
              });
          });

          // Agregar la métrica de tiempo total interceptado en milisegundos
          presence.intersectedTimeWithConversations = totalIntersectedTime;
      });

      const conversationsWithDetails = filteredConversations.map(conversation => {
        const details = this.getConversationDetail(conversation.conversationId);
        const metricsCopy = [...conversation.metrics];
        
        const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
        
        const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];
        const interactSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'interact');
        const wrapUpSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'wrapup');
        const alertSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'alert');
        const holdSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'hold');

        const calculateSegmentTimeInPresence = (segments, presenceDetails) => {
          let totalOnQueueTime = 0;
          let totalAuxTime = 0;

          segments?.forEach(segment => {
            const segmentStart = new Date(segment.segmentStart);
            const segmentEnd = new Date(segment.segmentEnd);
            const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
            const maxStartSegment = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
            const minEndSegment = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));

            presenceDetails.forEach(presence => {
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);
              const maxStartPresence = new Date(Math.max(presenceStart.getTime(), maxStartSegment.getTime()));
              const minEndPresence = new Date(Math.min(presenceEnd.getTime(), minEndSegment.getTime()));

              if (minEndPresence > maxStartPresence) {
                const intersectTime = minEndPresence - maxStartPresence;
                if (presence.systemPresence === 'ON_QUEUE') {
                  totalOnQueueTime += intersectTime;
                } else {
                  totalAuxTime += intersectTime;
                }
              }
            });
          });

          return { totalOnQueueTime, totalAuxTime };
        };

        let totalDuration = 0;
        let totalAcw = 0;
        let totalAlert = 0;
        let totalHold = 0;

        const interactTimes = calculateSegmentTimeInPresence(interactSegments, presenceDetails);
        totalDuration += interactSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const wrapUpTimes = calculateSegmentTimeInPresence(wrapUpSegments, presenceDetails);
        totalAcw += wrapUpSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const alertTimes = calculateSegmentTimeInPresence(alertSegments, presenceDetails);
        totalAlert += alertSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const holdTimes = calculateSegmentTimeInPresence(holdSegments, presenceDetails);
        totalHold += holdSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        // Añadir nuevas métricas calculadas a metricsCopy
        if (totalDuration > 0) {
          metricsCopy.push({ metric: 'tTalk', correct: true, stats: { max: totalDuration, min: totalDuration, count: 1, sum: totalDuration }, onQueue: interactTimes.totalOnQueueTime, aux: interactTimes.totalAuxTime });
        }
        if (totalAcw > 0) {
          metricsCopy.push({ metric: 'tAcw', correct: true, stats: { max: totalAcw, min: totalAcw, count: 1, sum: totalAcw }, onQueue: wrapUpTimes.totalOnQueueTime, aux: wrapUpTimes.totalAuxTime });
        }
        if (totalAlert > 0) {
          metricsCopy.push({ metric: 'tAlert', correct: true, stats: { max: totalAlert, min: totalAlert, count: 1, sum: totalAlert }, onQueue: alertTimes.totalOnQueueTime, aux: alertTimes.totalAuxTime });
        }
        if (totalHold > 0) {
          metricsCopy.push({ metric: 'tHold', correct: true, stats: { max: totalHold, min: totalHold, count: 1, sum: totalHold }, onQueue: holdTimes.totalOnQueueTime, aux: holdTimes.totalAuxTime });
        }

        // Gestión de la métrica endedConversacion
        const lastSegment = lastSession?.segments?.[lastSession?.segments?.length - 1];
        if (lastSegment) {
          const segmentEnd = new Date(lastSegment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));

          // Validar si segmentEnd del último segmento se encuentra dentro del intervalo de consulta
          if (segmentEnd >= intervalStart && segmentEnd <= intervalEnd) {
            // Calcular los tiempos totales de los segmentos sin considerar la intersección con el intervalo
            const totalInteractTime = interactSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);
            const totalWrapUpTime = wrapUpSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);
            const totalAlertTime = alertSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);
            const totalHoldTime = holdSegments?.reduce((acc, segment) => acc + (new Date(segment.segmentEnd) - new Date(segment.segmentStart)), 0);

            // Crear la nueva métrica endedConversacion con el valor de segmentEnd y los tiempos totales de cada métrica
            metricsCopy.push({
              metric: 'endedConversacion',
              correct: true,
              value: lastSegment.segmentEnd,
              totals: {
                tTalk: totalInteractTime,
                tAcw: totalWrapUpTime,
                tAlert: totalAlertTime,
                tHold: totalHoldTime
              }
            });
          }
        }

        // Asegurarse de devolver la conversación con la copia modificada de metrics
        return { ...conversation, metrics: metricsCopy, details, userPresences: presenceDetails };
      });

      return conversationsWithDetails;
    },

    getConversationsPerUsersQueueAndIntervalTIVirtual(userId, queueId = null, interval, direction = null, queueDifferent = false) {
      const filteredConversations = this.allUserConversationsAggregates.filter(conversation => {
        if (queueDifferent) {
          return (
            conversation.userId === userId &&
            (queueId !== null && conversation.queueId !== queueId) &&
            conversation.interval == interval &&
            (direction === null || conversation.direction == direction)
          );
        } else {
          return (
            conversation.userId === userId &&
            ((queueId === null && conversation.queueId === undefined)) &&
            conversation.interval == interval &&
            (direction !== null && conversation.direction === direction)
          );
        }
      });

      const presenceDetails = this.getPresenceDetailsForInterval(userId, interval);

      // Calcular el tiempo de intersección para cada presencia
      presenceDetails.forEach(presence => {
        let totalIntersectedTime = 0;

        filteredConversations.forEach(conversation => {
          const details = this.getConversationDetail(conversation.conversationId);
          const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
          const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];

          if (lastSession) {
            lastSession.segments.forEach(segment => {
              const segmentStart = new Date(segment.segmentStart);
              const segmentEnd = new Date(segment.segmentEnd);
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);

              // Calcular la intersección entre el segmento y la presencia
              const maxStart = new Date(Math.max(segmentStart.getTime(), presenceStart.getTime()));
              const minEnd = new Date(Math.min(segmentEnd.getTime(), presenceEnd.getTime()));

              if (minEnd > maxStart) {
                totalIntersectedTime += minEnd - maxStart;
              }
            });
          }
        });

        // Agregar la métrica de tiempo total interceptado en milisegundos
        presence.intersectedTimeWithConversations = totalIntersectedTime;
      });

      const conversationsWithDetails = filteredConversations.map(conversation => {
        const details = this.getConversationDetail(conversation.conversationId);
        const metricsCopy = [...conversation.metrics];
        
        const agentParticipant = details?.participants?.findLast(participant => participant.purpose === 'agent' && participant.userId === userId);
        const lastSession = agentParticipant?.sessions[agentParticipant?.sessions?.length - 1];
        const interactSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'interact');
        const wrapUpSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'wrapup');
        const alertSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'alert');
        const holdSegments = lastSession?.segments?.filter(segment => segment.segmentType === 'hold');

        const calculateSegmentTimeInPresence = (segments, presenceDetails) => {
          let totalOnQueueTime = 0;
          let totalAuxTime = 0;

          segments?.forEach(segment => {
            const segmentStart = new Date(segment.segmentStart);
            const segmentEnd = new Date(segment.segmentEnd);
            const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
            const maxStartSegment = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
            const minEndSegment = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));

            presenceDetails.forEach(presence => {
              const presenceStart = new Date(presence.startTime);
              const presenceEnd = new Date(presence.endTime);
              const maxStartPresence = new Date(Math.max(presenceStart.getTime(), maxStartSegment.getTime()));
              const minEndPresence = new Date(Math.min(presenceEnd.getTime(), minEndSegment.getTime()));

              if (minEndPresence > maxStartPresence) {
                const intersectTime = minEndPresence - maxStartPresence;
                if (presence.systemPresence === 'ON_QUEUE') {
                  totalOnQueueTime += intersectTime;
                } else {
                  totalAuxTime += intersectTime;
                }
              }
            });
          });

          return { totalOnQueueTime, totalAuxTime };
        };

        let totalDuration = 0;
        let totalAcw = 0;
        let totalAlert = 0;
        let totalHold = 0;

        const interactTimes = calculateSegmentTimeInPresence(interactSegments, presenceDetails);
        totalDuration += interactSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const wrapUpTimes = calculateSegmentTimeInPresence(wrapUpSegments, presenceDetails);
        totalAcw += wrapUpSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const alertTimes = calculateSegmentTimeInPresence(alertSegments, presenceDetails);
        totalAlert += alertSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        const holdTimes = calculateSegmentTimeInPresence(holdSegments, presenceDetails);
        totalHold += holdSegments?.reduce((acc, segment) => {
          const segmentStart = new Date(segment.segmentStart);
          const segmentEnd = new Date(segment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
          const maxStart = new Date(Math.max(segmentStart.getTime(), intervalStart.getTime()));
          const minEnd = new Date(Math.min(segmentEnd.getTime(), intervalEnd.getTime()));
          return acc + (minEnd > maxStart ? minEnd - maxStart : 0);
        }, 0);

        // Añadir nuevas métricas calculadas a metricsCopy
        if (totalDuration > 0) {
          metricsCopy.push({ metric: 'tTalk', correct: true, stats: { max: totalDuration, min: totalDuration, count: 1, sum: totalDuration }, onQueue: interactTimes.totalOnQueueTime, aux: interactTimes.totalAuxTime });
        }
        if (totalAcw > 0) {
          metricsCopy.push({ metric: 'tAcw', correct: true, stats: { max: totalAcw, min: totalAcw, count: 1, sum: totalAcw }, onQueue: wrapUpTimes.totalOnQueueTime, aux: wrapUpTimes.totalAuxTime });
        }
        if (totalAlert > 0) {
          metricsCopy.push({ metric: 'tAlert', correct: true, stats: { max: totalAlert, min: totalAlert, count: 1, sum: totalAlert }, onQueue: alertTimes.totalOnQueueTime, aux: alertTimes.totalAuxTime });
        }
        if (totalHold > 0) {
          metricsCopy.push({ metric: 'tHold', correct: true, stats: { max: totalHold, min: totalHold, count: 1, sum: totalHold }, onQueue: holdTimes.totalOnQueueTime, aux: holdTimes.totalAuxTime });
        }

        // Gestión de la métrica endedConversacion
        const lastSegment = lastSession?.segments?.[lastSession?.segments?.length - 1];
        if (lastSegment) {
          const segmentEnd = new Date(lastSegment.segmentEnd);
          const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));

          // Validar si segmentEnd del último segmento se encuentra dentro del intervalo de consulta
          if (segmentEnd >= intervalStart && segmentEnd <= intervalEnd) {
            // Crear la nueva métrica endedConversacion con el valor de segmentEnd
            metricsCopy.push({ metric: 'endedConversacion', correct: true, value: lastSegment.segmentEnd });
          }
        }

        // Asegurarse de devolver la conversación con la copia modificada de metrics
        return { ...conversation, metrics: metricsCopy, details, userPresences: presenceDetails };
      });

      return conversationsWithDetails;
    },


    countAllConversations(userId, email){
      const allConversations = this.allUserConversations.filter(conversation => conversation.participants.filter(participant => participant.userId === userId).length > 0);
      const allConversationsFinished = this.allUserConversationsAggregates.filter(conversation => conversation.userId===userId).length;
      return email+' count '+allConversations.length+' conversations, and '+allConversationsFinished+' finished';
    },
    
    getUserData(userId){

      return this.allUsers.find(user => user.id == userId);

    },

    getConversationDetail(conversationId){

      return this.allUserConversations.find(conversation => conversation.conversationId == conversationId);

    },

    async getAllUserConversationsAggregates(){
      //https://connector.esmtcx.solutions/custom/c/8z0ibacqv57t26js/8z0ibacqv57t26js_data.json
      console.log('Buscando conversaciones de los agentes')
      try {
        const response = await axios.get(this.sourceConversationAggregates)
        
        this.allUserConversationsAggregates  = response.data.data[0].data.results.reduce((acc, item) => {
            const newData = item.data.map(data => ({...item.group, ...data}));
            return acc.concat(newData);
        }, []);
        
        console.log('Conversaciones encontradas')

        
      } catch (error) {
        console.error(error)
      }

      //console.log(this.flattenedArray)
    },

    depurarEventos(events) {
        const resultado = [];
        const estadoColas = new Map();

        for (const event of events) {
            const { action, queue, user } = event;

            const key = `${queue}-${user}`; // Clave única por cola y usuario

            if (action === "MemberAdd") {
                // Si hay una adición, verifica si el estado actual permite una nueva adición
                if (!estadoColas.has(key) || estadoColas.get(key) === 'removed') {
                    estadoColas.set(key, 'added');
                    resultado.push(event); // Registrar el evento de adición
                }
                // Si el estado es 'added', ignora el evento para evitar duplicados
            } else if (action === "MemberRemove") {
                // Para un evento de remoción, verifica el estado actual
                const currentState = estadoColas.get(key);
                if (currentState !== 'removed') {
                    estadoColas.set(key, 'removed'); // Cambiar el estado a 'removed'
                    resultado.push(event); // Registrar el evento de remoción
                }
                // Si ya estaba en 'removed', no se hace nada para evitar duplicados
            }
        }

        return resultado;
    },

    /*filtrarEventosPorIntervaloYTipo(events, intervalo, tipoEvento, queueId) {
        // Convertir el intervalo en fechas de inicio y fin, manejando correctamente la zona horaria
        const [inicioIntervalo, finIntervalo] = intervalo.split('/').map(isoString => new Date(isoString));

        // Filtrar eventos que coincidan con el tipo, el queueId y que estén dentro del intervalo de tiempo
        const eventosFiltrados = events.filter(event => {
            const eventDate = new Date(event.eventDate); // Convertir la fecha del evento a un objeto Date

            return event.action === tipoEvento && 
                  event.queue && event.queue === queueId && // Comprobar si el evento tiene queue y coincide con el ID de la queue
                  eventDate >= inicioIntervalo && eventDate <= finIntervalo; // Comparar la fecha del evento con el intervalo
        });

        return eventosFiltrados;
    },

    filtrarEventosPorIntervaloYTipo(events, intervalo, tipoEvento, queueId) {
        // Obtener el valor de inicio del intervalo para comparar con eventDateSegment
        const [inicioIntervalo] = intervalo.split('/'); // Solo necesitamos el inicio del intervalo

        // Filtrar eventos que coincidan con el tipo, el queueId y que el eventDateSegment esté dentro del intervalo de tiempo
        const eventosFiltrados = events.filter(event => {
            return event.action === tipoEvento && 
                  event.queue && event.queue === queueId && // Comprobar si el evento tiene queue y coincide con el ID de la queue
                  event.eventDateSegment === inicioIntervalo; // Comparar eventDateSegment con el inicio del intervalo
        });

        return eventosFiltrados;
    },

    filtrarEventosPorIntervaloYTipo(events, intervalo, tipoEvento, queueId) {
        // Obtener el valor de inicio del intervalo para comparar con eventDateSegment
        const [inicioIntervalo] = intervalo.split('/'); // Solo necesitamos el inicio del intervalo

        // Convertir el inicio del intervalo y el eventDateSegment a objetos de fecha
        const inicioIntervaloDate = new Date(inicioIntervalo).toISOString(); // Convertir a formato UTC estándar (ISO 8601)
        
        // Filtrar eventos que coincidan con el tipo, el queueId y que el eventDateSegment esté dentro del intervalo de tiempo
        const eventosFiltrados = events.filter(event => {
            const eventSegmentDate = new Date(event.eventDateSegment).toISOString(); // Convertir a formato UTC estándar
            return event.action === tipoEvento && 
                  event.queue && event.queue === queueId && // Comprobar si el evento tiene queue y coincide con el ID de la queue
                  eventSegmentDate === inicioIntervaloDate; // Comparar las fechas ya unificadas en formato UTC
        });

        return eventosFiltrados;
    },*/

    filtrarEventosPorIntervaloYTipo(events, intervalo, tipoEvento=null, queueId) {
      // Obtener el valor de inicio del intervalo
      const [inicioIntervalo] = intervalo.split('/'); // Solo necesitamos el inicio del intervalo

      // Normalizar el formato de inicio del intervalo para que sea comparable
      const inicioIntervaloNormalized = inicioIntervalo.split('.')[0]; // Remover milisegundos

      // Filtrar eventos que coincidan con el queueId y que el eventDateSegment esté dentro del intervalo
      const eventosFiltrados = events.filter(event => {
        //const eventSegmentNormalized = event.eventDateSegment.replace('Z', ''); // Remover la 'Z' al final de eventDateSegment

        return (tipoEvento === null || event.action === tipoEvento) && 
            event.queue && event.queue === queueId && // Comprobar si el evento tiene queue y coincide con el ID de la queue
            event.eventDateSegment === inicioIntervaloNormalized+'Z'; // Comparar las fechas normalizadas
      });

      return eventosFiltrados;
    },

    /*buscarUltimoEventoAnterior(events, intervalo, queueId) {
      // Obtener el valor de inicio del intervalo y eliminar la parte del desfase horario (-05:00)
      let [inicioIntervalo] = intervalo.split('/');
      inicioIntervalo = inicioIntervalo.split('.')[0]; // Remover milisegundos y asegurarse de que está en formato correcto


      const inicioIntervaloDate = new Date(inicioIntervalo+'Z'); // Asegurar que esté en UTC agregando 'Z'

      // Filtrar eventos que coincidan con el queueId, y que el eventDate sea anterior al inicio del intervalo
      const eventosFiltrados = events.filter(event => {
          const eventDate = new Date(event.eventDate);

          // Comprobar si el evento es anterior al inicio del intervalo y coincide con el queueId
          return event.queue === queueId && eventDate < inicioIntervaloDate;
      });

      // Si encontramos eventos, obtener el último (el más cercano al inicio del intervalo)
      if (eventosFiltrados.length > 0) {
          const ultimoEvento = eventosFiltrados.reduce((ultimo, actual) => {
              return new Date(actual.eventDate) > new Date(ultimo.eventDate) ? actual : ultimo;
          });
          return ultimoEvento;
      } else {
          return null; // No hay eventos anteriores al intervalo
      }
  },*/

      buscarUltimoEventoAnterior(events, intervalo, queueId, diferenciaMaximaEnDias = 1) {
          // Obtener el valor de inicio del intervalo y eliminar la parte del desfase horario (-05:00)
          let [inicioIntervalo] = intervalo.split('/');
          inicioIntervalo = inicioIntervalo.split('.')[0]; // Remover milisegundos y asegurarse de que está en formato correcto

          const inicioIntervaloDate = new Date(inicioIntervalo + 'Z'); // Asegurar que esté en UTC agregando 'Z'

          // Filtrar eventos que coincidan con el queueId y que el eventDate sea anterior al inicio del intervalo
          const eventosFiltrados = events.filter(event => {
              const eventDate = new Date(event.eventDate);
              
              // Comprobar si el evento es anterior al inicio del intervalo y coincide con el queueId
              return event.queue === queueId && eventDate < inicioIntervaloDate;
          });

          // Si encontramos eventos, obtener el último (el más cercano al inicio del intervalo)
          if (eventosFiltrados.length > 0) {
              const ultimoEvento = eventosFiltrados.reduce((ultimo, actual) => {
                  return new Date(actual.eventDate) > new Date(ultimo.eventDate) ? actual : ultimo;
              });

              // Verificar si el evento está dentro del mismo día del intervalo
              const ultimoEventoDate = new Date(ultimoEvento.eventDate);
              const diferenciaEnDias = Math.abs((inicioIntervaloDate - ultimoEventoDate) / (1000 * 60 * 60 * 24)); // Diferencia en días

              // Si el evento no es "MemberRemove", retornarlo solo si es del mismo día
              if (diferenciaEnDias === 0) {
                  return ultimoEvento;
              }

              // Si el evento es "MemberRemove", permitir una diferencia máxima configurable de días
              if (ultimoEvento.action === "MemberRemove" && diferenciaEnDias <= diferenciaMaximaEnDias) {
                  return ultimoEvento;
              }
          }

          return null; // No hay eventos anteriores que cumplan con la condición
      },

      buscarEventosEnIntervalo(events, intervalo, queueId) {
          // Obtener el inicio y fin del intervalo, y eliminar la parte del desfase horario (-05:00)
          let [inicioIntervalo, finIntervalo] = intervalo.split('/');
          inicioIntervalo = inicioIntervalo.split('.')[0]; // Remover milisegundos
          finIntervalo = finIntervalo.split('.')[0]; // Remover milisegundos

          const inicioIntervaloDate = new Date(inicioIntervalo + 'Z'); // Asegurar que esté en UTC agregando 'Z'
          const finIntervaloDate = new Date(finIntervalo + 'Z'); // Asegurar que el fin esté en UTC

          // Filtrar eventos que coincidan con el queueId y que el eventDate esté dentro del intervalo
          const eventosFiltrados = events.filter(event => {
              const eventDate = new Date(event.eventDate);

              // Comprobar si el eventDate está dentro del intervalo y coincide con el queueId
              return event.queue === queueId && eventDate >= inicioIntervaloDate && eventDate <= finIntervaloDate;
          });

          return eventosFiltrados; // Devolver todos los eventos que cumplan con la condición
      },


    getMaxAvailableTime(userId, interval){
        const userActivity = this.flattenedArray.filter(activity => activity.userId === userId && activity.startTimeP === interval && activity.queue !== '');

        let maxAvailableTime = 0;
        //let maxActivity = null;

        userActivity.forEach(activity => {
            const availableTime = (this.customRound(activity.countAllAvailableTime)/1000)-
            (activity.acdtime)-
            (activity.acwtime)-
            (activity.ringtime)-
            (activity.holdtime)-
            
            (activity.i_othertime_acdtime+
              activity.i_othertime_acwtime+
              activity.i_othertime_ringtime+
              activity.i_othertime_holdtime)

            if (availableTime > maxAvailableTime) {
                maxAvailableTime = availableTime;
                //maxActivity = activity;
            }
        });

        /*if (maxActivity) {
          maxAvailableTime = maxAvailableTime -
          (maxActivity.acdtime || 0) -
          (maxActivity.acwtime || 0) -
          (maxActivity.ringtime || 0) -
          (maxActivity.holdtime || 0)
        }*/

        return this.customRound(maxAvailableTime);
    },

    getMinAvailableTime(userId, interval){
        const userActivity = this.flattenedArray.filter(activity => activity.userId === userId && activity.interval === interval && activity.queue !== '');

        let minAvailableTime = Infinity;

        userActivity.forEach(activity => {
            const availableTime = (activity.countAllAvailableTime)-
            (activity.i_acdtime || 0)-
            (activity.i_acwtime || 0)-
            (activity.ringtime || 0)-
            (activity.holdtime || 0)-
            
            (activity.i_othertime_acdtime+
              activity.i_othertime_acwtime+
              activity.i_othertime_ringtime+
              activity.i_othertime_holdtime)

            if (availableTime < minAvailableTime) {
                minAvailableTime = availableTime;
            }
        });

        return minAvailableTime;
    },

    formatTime(timeString) {
      let time = new Date(timeString);
      let hours = time.getUTCHours();
      let minutes = time.getUTCMinutes();

      // Asegurarse de que las horas y los minutos sean de dos dígitos
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;

      // Concatenar horas y minutos sin ":"
      return hours + '' + minutes;
    },

    formatDateToUTC(timeString){
        let time = new Date(timeString);  // Crear un objeto Date con el timeString

        // Obtener la fecha en formato UTC
        let year = time.getUTCFullYear();
        let month = time.getUTCMonth() + 1;  // getUTCMonth() devuelve el mes de 0 a 11
        let day = time.getUTCDate();

        // Asegurarse de que los meses y días sean de dos dígitos
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;

        // Formatear la fecha en UTC
        return year + '-' + month + '-' + day;
    },


    /*formatTime(timeString) {
      let time = new Date(timeString);

      // Convertir la hora a la zona horaria de México
      let options = { timeZone: 'America/Mexico_City', hour: '2-digit', minute: '2-digit', hour12: false };
      let mxTimeString = time.toLocaleString('en-US', options);
      
      // Extraer horas y minutos del string resultante
      let [hours, minutes] = mxTimeString.split(':');

      // Concatenar horas y minutos sin ":"
      return hours + '' + minutes;
    },*/

    /*getPresenceDetailsForInterval(userId, interval) {
      // Verificar si allUserPresences está definido y es un array
      if (Array.isArray(this.allUserPresences)) {
        // Convertir el intervalo de tiempo en dos objetos Date
        const [intervalStart, intervalEnd] = interval.split('/').map(isoString => new Date(isoString));
        // Filtrar el array para obtener solo las presencias del usuario especificado y que estén dentro del intervalo de tiempo
        const presenceDetailsWithinInterval = this.allUserPresences.filter(presence => {
          // Verificar si la presencia pertenece al usuario y está dentro del intervalo de tiempo
          const presenceStart = new Date(presence.startTime);
          const presenceEnd = new Date(presence.endTime);
          return presence.userId === userId && presenceStart < intervalEnd && presenceEnd > intervalStart;
        });
        // Retornar los detalles de las presencias dentro del intervalo
        return presenceDetailsWithinInterval;
      } else {
        // Retornar un array vacío si allUserPresences no está definido o no es un array
        return [];
      }
    },*/

    getPresenceDetailsForInterval(userId, interval) {
      if (!Array.isArray(this.allUserPresences)) {
        return [];
      }

      const [intervalStartISO, intervalEndISO] = interval.split('/');
      const intervalStart = new Date(intervalStartISO);
      const intervalEnd = new Date(intervalEndISO);

      return this.allUserPresences.reduce((acc, presence) => {
        if (presence.userId !== userId) {
          return acc;
        }

        let { startTime, endTime } = presence;
        const presenceStart = new Date(startTime);
        const presenceEnd = new Date(endTime);

        // Ajustar startTime y endTime manteniendo el desfase horario
        if (presenceStart < intervalStart) {
          startTime = intervalStart.toISOString();
        }
        if (presenceEnd > intervalEnd) {
          endTime = intervalEnd.toISOString();
        }

        // Solo agregar si la presencia está dentro del intervalo
        if (presenceStart < intervalEnd && presenceEnd > intervalStart) {
          acc.push({ ...presence, startTime, endTime });
        }

        return acc;
      }, []);
    },

    aggregateIntersectedTimes(conversations) {
      // Objeto para almacenar la suma total de los tiempos de intersección por cada combinación de estados
      const stateTimeTotals = {};

      conversations.forEach(conversation => {
        // Acceder al array de presencias del usuario para cada conversación
        const userPresences = conversation.userPresences;

        // Recorrer cada presencia y acumular el tiempo de intersección
        userPresences.forEach(presence => {
          const { organizationPresenceId, subPresence, systemPresence, intersectedTimeWithConversations } = presence;

          // Crear una clave única basada en los atributos relevantes
          const stateKey = `${organizationPresenceId}-${subPresence}-${systemPresence}`;

          // Si la combinación de atributos ya existe en el objeto stateTimeTotals, se suma el tiempo de intersección si no es duplicado
          if (stateTimeTotals[stateKey]) {
            // Usar un Set para almacenar y verificar tiempos únicos
            stateTimeTotals[stateKey].times.add(intersectedTimeWithConversations);
          } else {
            // Si no existe, se inicializa con el objeto de estado y se crea un Set para tiempos de intersección
            stateTimeTotals[stateKey] = {
              organizationPresenceId,
              subPresence,
              systemPresence,
              times: new Set([intersectedTimeWithConversations])
            };
          }
        });
      });

      // Convertir el objeto stateTimeTotals a un array unificado, sumando los tiempos únicos
      const unifiedArray = Object.values(stateTimeTotals).map(stateInfo => ({
        organizationPresenceId: stateInfo.organizationPresenceId,
        subPresence: stateInfo.subPresence,
        systemPresence: stateInfo.systemPresence,
        totalIntersectedTime: Array.from(stateInfo.times).reduce((total, time) => total + time, 0)
      }));

      return unifiedArray;
    },

    convertToUTC(interval){
      // Dividir el intervalo en sus dos componentes
      const [start, end] = interval.split("/");

      // Crear objetos Date para cada componente
      const startDate = new Date(start);
      const endDate = new Date(end);

      // Ajustar cada fecha sumando 6 horas
      startDate.setHours(startDate.getHours() + 6);
      endDate.setHours(endDate.getHours() + 6);

      // Formatear de nuevo a strings ISO
      const startUTC = startDate.toISOString();
      const endUTC = endDate.toISOString();

      // Devolver el nuevo intervalo
      return `${startUTC}/${endUTC}`;
    },

    async getAllUserActivityPresences(){
      console.log('Buscando presencias de usuarios')
      try {


        this.flattenedArray = this.allUserAggregatesPresences.reduce((acc, item) => {
          const queues = this.findUserInQueues(item.group.userId);
          //const userInfo = this.getUserData(item.group.userId);
          const userInfo = this.getUserData(item.group.userId);
          //const conversationsAllDebug = this.getConversationsPerUsersQueueAndIntervalDebug(item.group.userId)
          //console.log(this.countAllConversations(item.group.userId, userInfo.email))
          //console.log(userInfo?.email?.split('@')[0])

          //console.log(userInfo?.email?.split('@')[0])

          if (queues.length > 0) {
             
              const newData = item?.data?.flatMap(data => {
                
                  const itemsForQueues = queues?.map(queue => {

                  const auditEvents = queue.auditEvents || []
                  const auditEventsDep = auditEvents.length > 0  ? this.depurarEventos(auditEvents):[]

                  const auditEventsDepFinderMemberAdd = auditEventsDep.length > 0  ? this.filtrarEventosPorIntervaloYTipo(auditEventsDep, data.interval,  'MemberAdd', queue.queueId):null
                  const auditEventsDepFinderMemberRemove = auditEventsDep.length > 0  ? this.filtrarEventosPorIntervaloYTipo(auditEventsDep, data.interval, 'MemberRemove', queue.queueId):null
                  const auditEventsDepFinderMemberUpdate = auditEventsDep.length > 0  ? this.filtrarEventosPorIntervaloYTipo(auditEventsDep, data.interval, 'MemberUpdate', queue.queueId):null
                  
                  const auditEventsDepFinderAll = auditEventsDep.length > 0  ? this.filtrarEventosPorIntervaloYTipo(auditEventsDep, data.interval, null, queue.queueId):null

                  const auditEventsDepFinderMemberAddPrevious = auditEvents.length > 0 ? this.buscarUltimoEventoAnterior(auditEventsDep, data.interval, queue.queueId):null

                  const auditEventsDepFinderMemberRemoveSum = auditEventsDep.length > 0  ? auditEventsDepFinderMemberRemove.reduce((total, event) => (total + event.diffInMilliseconds), 0):0
                  const auditEventsDepFinderMemberAddSum = auditEventsDep.length > 0  ? auditEventsDepFinderMemberAdd.reduce((total, event) => (total + event.diffInMilliseconds), 0):0
                  
                  if(auditEventsDepFinderAll?.length > 1){
                    console.log('arrayUpdate multiple events')
                    console.log(userInfo?.email?.split('@')[0])
                    console.log(auditEventsDepFinderAll)

                  }
                  /*if(userInfo?.email?.split('@')[0]==='cardozoh.6' && queue.queueName=='SAC_AD_Uruguay_Inbound'){
                        console.log('arrayPrevious ')
                        console.log(auditEventsDepFinderMemberAddPrevious)
                        console.log(auditEventsDepFinderMemberAddPrevious?.action)
                        console.log('arrayAdd '+auditEventsDepFinderMemberAdd.length)
                        console.log(auditEventsDepFinderMemberAdd)
                  }*/

                  const isOnline = this.hasAnyConversationExist(item.group.userId, data.interval) > 0 ? true : false

                  const c_countAllAvailableTime = 
                    ['ON_QUEUE'].reduce((total, qualifier) => {
                      const sum = data?.metrics.filter(metric => metric?.qualifier === qualifier).reduce((total, metric) => {
                        if (auditEventsDepFinderMemberAddPrevious?.action === 'MemberRemove' && (auditEventsDepFinderMemberAdd?.length || 0) <= 0 && !isOnline) {
                          return total; // Continuar el reduce sin afectar la suma, en vez de return 0
                        }

                        const metricSum = metric?.stats?.sum || 0;

                        if (auditEventsDepFinderMemberAddSum > 0) {
                          return Math.min(total + metricSum, auditEventsDepFinderMemberAddSum);
                        }

                        if (auditEventsDepFinderMemberRemoveSum > 0) {
                          return Math.min(total + metricSum, 1800000 - auditEventsDepFinderMemberRemoveSum);
                        }

                        return total + metricSum;
                      }, 0);

                      return total + sum;
                    }, 0);

                  /*const offlineTime = 
                    ['OFFLINE'].reduce((total, qualifier) => {
                      const sum = data?.metrics.filter(metric => metric?.qualifier === qualifier).reduce((total, metric) => {
                        if (auditEventsDepFinderMemberAddPrevious?.action === 'MemberRemove' && (auditEventsDepFinderMemberAdd?.length || 0) <= 0) {
                          return total; // Continuar el reduce sin afectar la suma, en vez de return 0
                        }

                        const metricSum = metric?.stats?.sum || 0;

                        if (auditEventsDepFinderMemberAddSum > 0) {
                          return Math.min(total + metricSum, auditEventsDepFinderMemberAddSum);
                        }

                        if (auditEventsDepFinderMemberRemoveSum > 0) {
                          return Math.min(total + metricSum, 1800000 - auditEventsDepFinderMemberRemoveSum);
                        }

                        return total + metricSum;
                      }, 0);

                      return total + sum;
                    }, 0);*/

                  const otherStatesTime = 
                    ['AVAILABLE', 'ON_QUEUE', 'BREAK', 'TRAINING', 'BUSY', 'AWAY', 'LUNCH', 'MEAL', 'MEETING'].reduce((total, qualifier) => {
                      const sum = data?.metrics.filter(metric => metric?.qualifier === qualifier).reduce((total, metric) => {
                        if (auditEventsDepFinderMemberAddPrevious?.action === 'MemberRemove' && (auditEventsDepFinderMemberAdd?.length || 0) <= 0 && !isOnline) {
                          return total; // Continuar el reduce sin afectar la suma, en vez de return 0
                        }

                        const metricSum = metric?.stats?.sum || 0;

                        if (auditEventsDepFinderMemberAddSum > 0) {
                          return Math.min(total + metricSum, auditEventsDepFinderMemberAddSum);
                        }

                        if (auditEventsDepFinderMemberRemoveSum > 0) {
                          return Math.min(total + metricSum, 1800000 - auditEventsDepFinderMemberRemoveSum);
                        }

                        return total + metricSum;
                      }, 0);

                      return total + sum;
                    }, 0);

                  const c_countAllStafftime = Math.max(0, otherStatesTime);


                  const otherAuxStatesTime = 
                  ['AVAILABLE', 'BREAK', 'TRAINING', 'BUSY', 'AWAY', 'LUNCH', 'MEAL', 'MEETING'].reduce((total, qualifier) => {
                      const sum = data?.metrics.filter(metric => metric?.qualifier === qualifier).reduce((total, metric) => {
                        if (auditEventsDepFinderMemberAddPrevious?.action === 'MemberRemove' && (auditEventsDepFinderMemberAdd?.length || 0) <= 0 && !isOnline) {
                          return total; // Continuar el reduce sin afectar la suma, en vez de return 0
                        }

                        const metricSum = metric?.stats?.sum || 0;

                        if (auditEventsDepFinderMemberAddSum > 0) {
                          return Math.min(total + metricSum, auditEventsDepFinderMemberAddSum);
                        }

                        if (auditEventsDepFinderMemberRemoveSum > 0) {
                          return Math.min(total + metricSum, 1800000 - auditEventsDepFinderMemberRemoveSum);
                        }

                        return total + metricSum;
                      }, 0);

                      return total + sum;
                    }, 0);

                  const c_countAllAuxtime = 
                  Math.max(0, otherAuxStatesTime)
                  ;

                  

                  //const conversationsAll = this.getConversationsPerUsersQueueAndInterval(item.group.userId, queue.queueId, data.interval, null);
                  const conversationsAll = isOnline ? this.getConversationsPerUsersQueueAndIntervalALLNew(item.group.userId, queue.queueId, data.interval):[]
                  const conversationsIn = isOnline ? this.getConversationsPerUsersQueueAndIntervalNew2(item.group.userId, queue.queueId, data.interval, 'inbound'):[]
                  const conversationsOut = isOnline ? this.getConversationsPerUsersQueueAndIntervalNew2(item.group.userId, queue.queueId, data.interval, 'outbound'):[]
                  const conversationsOutNONACD = isOnline ? this.getConversationsPerUsersQueueAndIntervalNONACD(item.group.userId, queue.queueId, data.interval, 'outbound'):[]
                  const conversationsAllOther = isOnline ? this.getConversationsPerUsersQueueAndIntervalOTHER(item.group.userId, queue.queueId, data.interval):[]
                  const conversationsAllOtherManualOut = isOnline ? this.getConversationsManualPerUsersIntervalOTHERWithDirection(item.group.userId, queue.queueId, data.interval, 'outbound'):[]
                  const conversationsAllOtherManualIN = isOnline ? this.getConversationsManualPerUsersIntervalOTHERWithDirection(item.group.userId, queue.queueId, data.interval, 'inbound'):[]
                  
                  const interceptedTimes = isOnline ? this.aggregateIntersectedTimes(conversationsAll):[]

                  
                  //const log_audit_exist_memberAdd = JSON.stringify(auditEventsDepFinderMemberAdd).replace(/,/g, ';')
                  //const log_audit_exist_memberRemove = JSON.stringify(auditEventsDepFinderMemberRemove).replace(/,/g, ';')

                  let ACDCALLSIDS = []
                  let RINGCALLSIDS = []
                  let ABNCALLSIDS = []
                  let ONQUEUEACDTIME = 0
                  let AUXACDTIME = 0
                  let ONQUEUEACWTIME = 0
                  let AUXACWTIME = 0
                  let ONQUEUEHOLDTIME = 0
                  let AUXHOLDTIME = 0
                  let ONQUEUEALERTTIME = 0
                  let AUXALERTTIME = 0
                  let SALIENTES = []

                  let OFFLINEACD = 0
                  let OFFLINEACW = 0
                  let OFFLINEALERT = 0
                  let OFFLINEHOLD = 0



                  /*const c_countAllStafftime = 
                    data?.metrics.filter(metric => metric?.qualifier === 'AVAILABLE').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric?.qualifier === 'ON_QUEUE').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric?.qualifier === 'BREAK').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric?.qualifier === 'TRAINING').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric?.qualifier === 'BUSY').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric?.qualifier === 'AWAY').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric?.qualifier === 'LUNCH').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric?.qualifier === 'MEAL').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric?.qualifier === 'OFFLINE').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric?.qualifier === 'MEETING').reduce((total, metric) => (total + metric.stats.sum), 0)

                  const c_countAllAuxtime =
                    data?.metrics.filter(metric => metric?.qualifier === 'AVAILABLE').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric?.qualifier === 'BREAK').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric?.qualifier === 'TRAINING').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric?.qualifier === 'BUSY').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric?.qualifier === 'AWAY').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric?.qualifier === 'LUNCH').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric?.qualifier === 'MEAL').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric?.qualifier === 'OFFLINE').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric?.qualifier === 'MEETING').reduce((total, metric) => (total + metric.stats.sum), 0)*/



                  //console.log('Encontrando a----')

                  
                  /////////////////////Intervalos

                    const c_i_acdtime = conversationsAll.reduce((total, conversation) => {
                    // Filtrar por el participant que tenga en su userId el id del usuario
                      const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                      let passedThroughACD = false;
                      // Verificar si existe un participant anterior con purpose 'acd'
                      if (userParticipantIndex > 0) {
                        for (let i = userParticipantIndex - 1; i >= 0; i--) {
                          if (conversation.details.participants[i].purpose === 'acd') {
                            passedThroughACD = true;
                            break;
                          }
                        }
                      }

                      // Proceder solo si la conversación pasó por un segmento de ACD
                      if (passedThroughACD) {
                        const tTalkMetrics = conversation?.metrics?.filter(metric => metric?.metric === "tTalk") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTTalkMetrics = tTalkMetrics.filter(metric => metric.correct === true);

                        let sumACD;
                        if (correctTTalkMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumACD = correctTTalkMetrics.reduce((total, metric) => total + (metric.sum || 0), 0);
                        } else {
                          // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumACD = tTalkMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }

                        return total + sumACD;
                      }

                      return total; // Si no pasó por ACD, retornar el total actual sin cambios
                    }, 0) / 1000;

                    //const c_i_acdtime_onQueue_extra_aux=0

                    const c_i_acdtime_onQueue = conversationsAll.reduce((total, conversation) => {
                      // Filtrar por el participant que tenga en su userId el id del usuario
                      const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                      let passedThroughACD = false;
                      // Verificar si existe un participant anterior con purpose 'acd'
                      if (userParticipantIndex > 0) {
                        for (let i = userParticipantIndex - 1; i >= 0; i--) {
                          if (conversation.details.participants[i].purpose === 'acd') {
                            passedThroughACD = true;
                            break;
                          }
                        }
                      }

                      // Proceder solo si la conversación pasó por un segmento de ACD
                      if (passedThroughACD) {
                        const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "tTalk") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTTalkMetrics = tTalkMetrics.filter(metric => metric.correct === true);

                        let sumACD = 0;
                        //let sumACDAUX;
                        if (correctTTalkMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumACD = correctTTalkMetrics.reduce((total, metric) => total + (metric.onQueue || 0), 0);
                          if(sumACD > 0){
                            ONQUEUEACDTIME=ONQUEUEACDTIME+sumACD;
                          }

                        }
                          /*sumACDAUX = correctTTalkMetrics.reduce((total, metric) => total + (metric.aux || 0), 0);
                          if(sumACDAUX>0){
                            c_i_acdtime_onQueue_extra_aux+=sumACDAUX
                          }
                        } else {
                          // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumACD = tTalkMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }*/


                        return total + sumACD;
                      }
                      

                      return total; // Si no pasó por ACD, retornar el total actual sin cambios
                    }, 0) / 1000;

                    /*
                    const c_i_acdtime_aux = conversationsAll.reduce((total, conversation) => {
                      // Filtrar por el participant que tenga en su userId el id del usuario
                      const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                      let passedThroughACD = false;
                      // Verificar si existe un participant anterior con purpose 'acd'
                      if (userParticipantIndex > 0) {
                        for (let i = userParticipantIndex - 1; i >= 0; i--) {
                          if (conversation.details.participants[i].purpose === 'acd') {
                            passedThroughACD = true;
                            break;
                          }
                        }
                      }

                      // Proceder solo si la conversación pasó por un segmento de ACD
                      if (passedThroughACD) {
                        const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "tTalk") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTTalkMetrics = tTalkMetrics.filter(metric => metric.correct === true);

                        let sumACD;
                        let sumACDMOD;
                        let sumACDCorrect;

                        if (correctTTalkMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumACDMOD = tTalkMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                          //sumACD = correctTTalkMetrics.reduce((total, metric) => total + ((metric.stats.sum) || 0), 0);
                          sumACDCorrect = correctTTalkMetrics.reduce((total, metric) => total + (metric.onQueue || 0), 0);

                          if(sumACDMOD>sumACDCorrect){
                            sumACD=(sumACDMOD-sumACDCorrect)
                          }else{
                            sumACD=0
                          }

                        } else {
                          // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumACD = tTalkMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }

                        return total + sumACD;
                      }

                      return total; // Si no pasó por ACD, retornar el total actual sin cambios
                    }, 0) / 1000; */
                   

                    const c_i_acdtime_aux = conversationsAll.reduce((total, conversation) => {
                      // Filtrar por el participant que tenga en su userId el id del usuario
                      const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                      let passedThroughACD = false;
                      // Verificar si existe un participant anterior con purpose 'acd'
                      if (userParticipantIndex > 0) {
                        for (let i = userParticipantIndex - 1; i >= 0; i--) {
                          if (conversation.details.participants[i].purpose === 'acd') {
                            passedThroughACD = true;
                            break;
                          }
                        }
                      }

                      // Proceder solo si la conversación pasó por un segmento de ACD
                      if (passedThroughACD) {
                        const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "tTalk") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTTalkMetrics = tTalkMetrics.filter(metric => metric.correct === true);

                        let sumACD = 0;
                        let sumACDOFFLINE = 0;
                        if (correctTTalkMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumACD = correctTTalkMetrics.reduce((total, metric) => total + (metric.auxO > 0 ? metric.auxO: metric.aux || 0), 0);
                          sumACDOFFLINE = correctTTalkMetrics.reduce((total, metric) => total + (metric.offlineAcd || 0), 0);
                          if(sumACD > 0){
                            AUXACDTIME=AUXACDTIME+sumACD;
                            OFFLINEACD=OFFLINEACD+sumACDOFFLINE;
                          }
                          
                        }/* else {
                          // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumACD = tTalkMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }*/

                        return total + sumACD;
                      }

                      return total; // Si no pasó por ACD, retornar el total actual sin cambios*/
                    }, 0) / 1000;
                  
                  //ACW time intervalo
                  const c_i_acwtime = conversationsAll.reduce((total, conversation) => {
                      const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                      let passedThroughACD = false;
                      // Verificar si existe un participant anterior con purpose 'acd'
                      if (userParticipantIndex > 0) {
                        for (let i = userParticipantIndex - 1; i >= 0; i--) {
                          if (conversation.details.participants[i].purpose === 'acd') {
                            passedThroughACD = true;
                            break;
                          }
                        }
                      }

                      // Proceder solo si la conversación pasó por un segmento de ACD
                      if (passedThroughACD) {
                        const tAcwMetrics = conversation.metrics?.filter(metric => metric?.metric === "tAcw") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTtAcwMetrics = tAcwMetrics.filter(metric => metric.correct === true);

                        let sumACW;
                        if (correctTtAcwMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumACW = correctTtAcwMetrics.reduce((total, metric) => total + (metric.onQueue || 0), 0);
                        } else {
                          // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumACW = tAcwMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }

                        return total + sumACW;
                      }

                      return total; // Si no pasó por ACD, retornar el total actual sin cambios
                    }, 0) / 1000
                    

                    const c_i_acwtime_onQueue = conversationsAll.reduce((total, conversation) => {
                      const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                      let passedThroughACD = false;
                      // Verificar si existe un participant anterior con purpose 'acd'
                      if (userParticipantIndex > 0) {
                        for (let i = userParticipantIndex - 1; i >= 0; i--) {
                          if (conversation.details.participants[i].purpose === 'acd') {
                            passedThroughACD = true;
                            break;
                          }
                        }
                      }

                      // Proceder solo si la conversación pasó por un segmento de ACD
                      if (passedThroughACD) {
                        const tAcwMetrics = conversation.metrics?.filter(metric => metric?.metric === "tAcw") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTtAcwMetrics = tAcwMetrics.filter(metric => metric.correct === true);

                        let sumACW;
                        if (correctTtAcwMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumACW = correctTtAcwMetrics.reduce((total, metric) => total + (metric.onQueue || 0), 0);
                          if(sumACW > 0){
                            ONQUEUEACWTIME=ONQUEUEACWTIME+sumACW;
                          } 
                        } else {
                          // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumACW = tAcwMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }

                        return total + sumACW;
                      }

                      return total; // Si no pasó por ACD, retornar el total actual sin cambios
                    }, 0) / 1000

                    const c_i_acwtime_aux = conversationsAll.reduce((total, conversation) => {
                      const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                      let passedThroughACD = false;
                      // Verificar si existe un participant anterior con purpose 'acd'
                      if (userParticipantIndex > 0) {
                        for (let i = userParticipantIndex - 1; i >= 0; i--) {
                          if (conversation.details.participants[i].purpose === 'acd') {
                            passedThroughACD = true;
                            break;
                          }
                        }
                      }

                      // Proceder solo si la conversación pasó por un segmento de ACD
                      if (passedThroughACD) {
                        const tAcwMetrics = conversation.metrics?.filter(metric => metric?.metric === "tAcw") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTtAcwMetrics = tAcwMetrics.filter(metric => metric.correct === true);

                        let sumACW;
                        if (correctTtAcwMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumACW = correctTtAcwMetrics.reduce((total, metric) => total + (metric.auxO > 0? metric.auxO:metric.aux || 0), 0);

                          if(sumACW > 0){
                            AUXACWTIME=AUXACWTIME+sumACW;
                          }
                          
                        } else {
                          // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumACW = tAcwMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }

                        return total + sumACW;
                      }

                      return total; // Si no pasó por ACD, retornar el total actual sin cambios
                    }, 0) / 1000

                    const c_i_acwauxtime = conversationsOut?.reduce((total, conversation) => {
                        //const tDialingMetric = conversation.metrics?.find(metric => metric?.metric === "tDialing");
                        //const tContactingMetric = conversation.metrics?.find(metric => metric?.metric === "tContacting");
                        const tAcwMetrics = conversation.metrics?.filter(metric => metric?.metric === "tAcw") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correcttAcwMetrics = tAcwMetrics.filter(metric => metric.correct === true);

                        /*if(tDialingMetric===undefined && tContactingMetric===undefined){
                          return total
                        }*/

                        let sumACW;
                        let sumACWOFFLINE;
                        if (correcttAcwMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumACW = correcttAcwMetrics.reduce((total, metric) => total + (metric.auxO > 0? metric.auxO:metric.aux || 0), 0);
                          sumACWOFFLINE = correcttAcwMetrics.reduce((total, metric) => total + (metric.offlineAcw || 0), 0);
                          if(sumACW > 0){
                            AUXACWTIME=AUXACWTIME+sumACW;
                            OFFLINEACW=OFFLINEACW+sumACWOFFLINE;
                          }
                        } else {
                          // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumACW = tAcwMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }

                        //const count = tDialingMetric?.stats.sum || 0;
                        //return total + sumACD + count;
                        return total + sumACW;
                      }, 0) / 1000

                    const c_i_holdtime = conversationsAll.reduce((total, conversation) => {
                      const userParticipantIndex = conversation?.details?.participants?.findIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                      let passedThroughACD = false;
                      // Verificar si existe un participant anterior con purpose 'acd'
                      if (userParticipantIndex > 0) {
                        for (let i = userParticipantIndex - 1; i >= 0; i--) {
                          if (conversation.details.participants[i].purpose === 'acd') {
                            passedThroughACD = true;
                            break;
                          }
                        }
                      }

                      // Proceder solo si la conversación pasó por un segmento de ACD
                      if (passedThroughACD) {
                        const tHoldMetrics = conversation.metrics?.filter(metric => metric?.metric === "tHold") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTHoldMetrics = tHoldMetrics.filter(metric => metric.correct === true);

                        let sumHold;
                        if (correctTHoldMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumHold = correctTHoldMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        } else {
                          // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumHold = tHoldMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }

                        return total + sumHold;
                      }
                      
                      return total; // Si no pasó por ACD, retornar el total actual sin cambios
                    }, 0) / 1000

                  
                    const c_i_holdtime_onQueue = conversationsAll.reduce((total, conversation) => {
                      const userParticipantIndex = conversation?.details?.participants?.findIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                      let passedThroughACD = false;
                      // Verificar si existe un participant anterior con purpose 'acd'
                      if (userParticipantIndex > 0) {
                        for (let i = userParticipantIndex - 1; i >= 0; i--) {
                          if (conversation.details.participants[i].purpose === 'acd') {
                            passedThroughACD = true;
                            break;
                          }
                        }
                      }

                      // Proceder solo si la conversación pasó por un segmento de ACD
                      if (passedThroughACD) {
                        const tHoldMetrics = conversation.metrics?.filter(metric => metric?.metric === "tHold") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTHoldMetrics = tHoldMetrics.filter(metric => metric.correct === true);

                        let sumHold;
                        if (correctTHoldMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumHold = correctTHoldMetrics.reduce((total, metric) => total + (metric.onQueue || 0), 0);

                          if(sumHold > 0){
                            ONQUEUEHOLDTIME=ONQUEUEHOLDTIME+sumHold;
                          }
                        } else {
                          // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumHold = tHoldMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }

                        return total + sumHold;
                      }
                      
                      return total; // Si no pasó por ACD, retornar el total actual sin cambios
                    }, 0) / 1000

                    const c_i_holdtime_aux = conversationsAll.reduce((total, conversation) => {
                      const userParticipantIndex = conversation?.details?.participants?.findIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                      let passedThroughACD = false;
                      // Verificar si existe un participant anterior con purpose 'acd'
                      if (userParticipantIndex > 0) {
                        for (let i = userParticipantIndex - 1; i >= 0; i--) {
                          if (conversation.details.participants[i].purpose === 'acd') {
                            passedThroughACD = true;
                            break;
                          }
                        }
                      }

                      // Proceder solo si la conversación pasó por un segmento de ACD
                      if (passedThroughACD) {
                        const tHoldMetrics = conversation.metrics?.filter(metric => metric?.metric === "tHold") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTHoldMetrics = tHoldMetrics.filter(metric => metric.correct === true);

                        let sumHold;
                        let sumHOLDFFLINE;
                        if (correctTHoldMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumHold = correctTHoldMetrics.reduce((total, metric) => total + (metric.aux || 0), 0);
                          sumHOLDFFLINE = correctTHoldMetrics.reduce((total, metric) => total + (metric.offlineHold || 0), 0);

                          if(sumHold > 0){
                            AUXHOLDTIME=AUXHOLDTIME+sumHold;
                            OFFLINEHOLD = OFFLINEHOLD+sumHOLDFFLINE;
                          }
                        } else {
                          // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumHold = tHoldMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }

                        return total + sumHold;
                      }
                      
                      return total; // Si no pasó por ACD, retornar el total actual sin cambios
                    }, 0) / 1000

                  
                  const c_i_acwouttime = 0.000
                  const c_i_acwintime = 0.000

                    
                  const c_i_auxouttime = conversationsOut?.reduce((total, conversation) => {
                      //const tDialingMetric = conversation.metrics?.find(metric => metric?.metric === "tDialing");
                      const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "tTalk") || [];
                      const tContactingMetrics = conversation.metrics?.filter(metric => metric?.metric === "tContacting") || [];
                      const tDialingMetrics = conversation.metrics?.filter(metric => metric?.metric === "tDialing") || [];
                      // Verificar si existe algún tTalk con correct: true
                      const correctTTalkMetrics = tTalkMetrics.filter(metric => metric.correct === true);

                      if(tContactingMetrics===undefined && tDialingMetrics===undefined){
                        return total
                      }

                      let sumACD;
                      if (correctTTalkMetrics.length > 0) {
                        // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                        sumACD = correctTTalkMetrics.reduce((total, metric) => total + (metric.aux || 0), 0);
                      } else {
                        // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                        sumACD = tTalkMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                      }
                      //const count = tDialingMetric?.stats.sum || 0;
                      //return total + sumACD + count;
                      return total + sumACD;
                    }, 0) / 1000

                    const c_i_auxouttime_onQueue = conversationsOut?.reduce((total, conversation) => {
                      //const tDialingMetric = conversation.metrics?.find(metric => metric?.metric === "tDialing");
                      const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "tTalk") || [];
                      const tContactingMetrics = conversation.metrics?.filter(metric => metric?.metric === "tContacting") || [];
                      const tDialingMetrics = conversation.metrics?.filter(metric => metric?.metric === "tDialing") || [];
                      // Verificar si existe algún tTalk con correct: true
                      const correctTTalkMetrics = tTalkMetrics.filter(metric => metric.correct === true);

                      if(tContactingMetrics===undefined && tDialingMetrics===undefined){
                        return total
                      }

                      let sumACD;
                      if (correctTTalkMetrics.length > 0) {
                        // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                        sumACD = correctTTalkMetrics.reduce((total, metric) => total + (metric.onQueue || 0), 0);
                      } else {
                        // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                        sumACD = tTalkMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                      }
                      //const count = tDialingMetric?.stats.sum || 0;
                      //return total + sumACD + count;
                      return total + sumACD;
                    }, 0) / 1000

                  const c_i_auxintime=0.000

                  /*const c_i_auxintime = conversationsIn?.reduce((total, conversation) => {
                      //const tDialingMetric = conversation.metrics?.find(metric => metric?.metric === "tDialing");
                      const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "tTalk") || [];
                      const tContactingMetrics = conversation.metrics?.filter(metric => metric?.metric === "tContacting") || [];
                      const tDialingMetrics = conversation.metrics?.filter(metric => metric?.metric === "tDialing") || [];
                      // Verificar si existe algún tTalk con correct: true
                      const correctTTalkMetrics = tTalkMetrics.filter(metric => metric.correct === true);

                      if(tContactingMetrics===undefined && tDialingMetrics===undefined){
                        return total
                      }

                      let sumACD;
                      if (correctTTalkMetrics.length > 0) {
                        // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                        sumACD = correctTTalkMetrics.reduce((total, metric) => total + (metric.aux || 0), 0);
                      } else {
                        // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                        sumACD = tTalkMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                      }
                      //const count = tDialingMetric?.stats.sum || 0;
                      //return total + sumACD + count;
                      return total + sumACD;
                    }, 0) / 1000*/

                  
                  //Ring time intervalo
                  /*const c_i_ringtime = conversationsAll.reduce((total, conversation) => {
                    const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                      let passedThroughACD = false;
                      // Verificar si existe un participant anterior con purpose 'acd'
                      if (userParticipantIndex > 0) {
                        for (let i = userParticipantIndex - 1; i >= 0; i--) {
                          if (conversation.details.participants[i].purpose === 'acd') {
                            passedThroughACD = true;
                            break;
                          }
                        }
                      }

                      // Proceder solo si la conversación pasó por un segmento de ACD
                      if (passedThroughACD) {

                        const tAlertMetrics = conversation.metrics?.filter(metric => metric?.metric === "tAlert") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTAlertMetrics = tAlertMetrics.filter(metric => metric.correct === true);

                        let sumAlert;
                        if (correctTAlertMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumAlert = correctTAlertMetrics.reduce((total, metric) => total + (metric.onQueue || 0), 0);
                        } else {
                          // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumAlert = tAlertMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }

                        return total + sumAlert;
                      }
                      return total; // Si no pasó por ACD, retornar el total actual sin cambios
                    }, 0) / 1000*/

                    const c_i_ringtime = conversationsAll.reduce((total, conversation) => {
                      const userParticipantIndices = conversation?.details?.participants?.map((participant, index) => participant.userId === item.group.userId ? index : -1)
                        .filter(index => index !== -1);

                      let sumAlert = 0;

                      userParticipantIndices?.forEach(userParticipantIndex => {
                        let passedThroughACD = false;
                        // Verificar si existe un participant anterior con purpose 'acd'
                        if (userParticipantIndex > 0) {
                          for (let i = userParticipantIndex - 1; i >= 0; i--) {
                            if (conversation.details.participants[i].purpose === 'acd') {
                              passedThroughACD = true;
                              break;
                            }
                          }
                        }

                        // Proceder solo si la conversación pasó por un segmento de ACD
                        if (passedThroughACD) {
                          const tAlertMetrics = conversation.metrics?.filter(metric => metric?.metric === "tAlert") || [];
                          // Verificar si existe algún tAlert con correct: true
                          const correctTAlertMetrics = tAlertMetrics.filter(metric => metric.correct === true);

                          if (correctTAlertMetrics.length > 0) {
                            // Si existe al menos un tAlert con correct: true, usar solo esos para la suma
                            sumAlert += correctTAlertMetrics.reduce((total, metric) => total + (metric.onQueue || 0), 0);

                            if(sumAlert > 0){
                              ONQUEUEALERTTIME=ONQUEUEALERTTIME+sumAlert;
                            }
                          } else {
                            // Si no existe ninguno con correct: true, proceder con la suma de todos los tAlert
                            sumAlert += tAlertMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                          }
                        }
                      });

                      return total + sumAlert;
                    }, 0) / 1000;

                    //Ring time intervalo
                  /*const c_i_ringtime_aux = conversationsAll.reduce((total, conversation) => {
                    const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                      let passedThroughACD = false;
                      // Verificar si existe un participant anterior con purpose 'acd'
                      if (userParticipantIndex > 0) {
                        for (let i = userParticipantIndex - 1; i >= 0; i--) {
                          if (conversation.details.participants[i].purpose === 'acd') {
                            passedThroughACD = true;
                            break;
                          }
                        }
                      }

                      // Proceder solo si la conversación pasó por un segmento de ACD
                      if (passedThroughACD) {

                        const tAlertMetrics = conversation.metrics?.filter(metric => metric?.metric === "tAlert") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTAlertMetrics = tAlertMetrics.filter(metric => metric.correct === true);

                        let sumAlert;
                        if (correctTAlertMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumAlert = correctTAlertMetrics.reduce((total, metric) => total + (metric.aux || 0), 0);
                        } else {
                          // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumAlert = tAlertMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }

                        return total + sumAlert;
                      }
                      return total; // Si no pasó por ACD, retornar el total actual sin cambios
                    }, 0) / 1000*/

                    const c_i_ringtime_aux = conversationsAll.reduce((total, conversation) => {
                      const userParticipantIndices = conversation?.details?.participants?.map((participant, index) => participant.userId === item.group.userId ? index : -1)
                        .filter(index => index !== -1);

                      let sumAlert = 0;
                      let sumALERTOFFLINE = 0;

                      userParticipantIndices?.forEach(userParticipantIndex => {
                        let passedThroughACD = false;
                        // Verificar si existe un participant anterior con purpose 'acd'
                        if (userParticipantIndex > 0) {
                          for (let i = userParticipantIndex - 1; i >= 0; i--) {
                            if (conversation.details.participants[i].purpose === 'acd') {
                              passedThroughACD = true;
                              break;
                            }
                          }
                        }

                        // Proceder solo si la conversación pasó por un segmento de ACD
                        if (passedThroughACD) {
                          const tAlertMetrics = conversation?.metrics?.filter(metric => metric?.metric === "tAlert") || [];
                          // Verificar si existe algún tAlert con correct: true
                          const correctTAlertMetrics = tAlertMetrics.filter(metric => metric.correct === true);

                          if (correctTAlertMetrics.length > 0) {
                            // Si existe al menos un tAlert con correct: true, usar solo esos para la suma
                            sumAlert += correctTAlertMetrics.reduce((total, metric) => total + (metric.aux || 0), 0);
                            sumALERTOFFLINE += correctTAlertMetrics.reduce((total, metric) => total + (metric.offlineHold || 0), 0);
                            if(sumAlert > 0){
                              AUXALERTTIME=AUXALERTTIME+sumAlert;
                              OFFLINEALERT=OFFLINEALERT+sumALERTOFFLINE;
                            }
                          } else {
                            // Si no existe ninguno con correct: true, proceder con la suma de todos los tAlert
                            sumAlert += tAlertMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                          }
                        }
                      });

                      return total + sumAlert;
                    }, 0) / 1000;


                  ////////////////////Totales

                  //Total gestionadas y desconectadas por el agente

                    const c_t_acd_release = conversationsAll?.reduce((total, conversation) => {
                      const agentParticipant = conversation.details?.participants?.find(participant => participant.purpose === "agent");
                      const customerParticipant = conversation.details?.participants?.find(participant => participant.purpose === "customer");
                      const ended = conversation.metrics?.filter(metric => metric?.metric === "endedConversacion") || [];

                      if (agentParticipant && customerParticipant && ended.length>0) {
                        const lastSessionCustomer = customerParticipant.sessions?.[customerParticipant.sessions.length - 1];
                        const lastSessionAgent = agentParticipant.sessions?.[agentParticipant.sessions.length - 1];

                        const customerSessionValid = lastSessionCustomer && lastSessionCustomer.segments?.some(segment => segment.disconnectType === "peer" || segment.disconnectType === "system");
                        const agentSessionValid = lastSessionAgent && lastSessionAgent.segments?.some(segment => segment.disconnectType !== "peer");

                        const agentAnsweredCall = agentParticipant.sessions?.some(session => 
                          session.segments?.some(segment => segment.segmentType === 'alert') &&
                          session.segments?.some(segment => segment.segmentType === 'interact')
                        );

                        if (customerSessionValid && agentSessionValid && agentAnsweredCall) {
                          return total + 1;
                        }
                      }

                      return total;
                    }, 0);

                  //Llamadas alertadas intervalo

                  /*const c_i_ringcalls = conversationsAll?.reduce((total, conversation) => {
                          const hasAlertMetric = conversation.metrics?.some(metric => metric?.metric === "tAlert");
                          return total + (hasAlertMetric ? 1 : 0);
                    }, 0);

                    const c_i_ringcalls = conversationsAll?.reduce((total, conversation) => {
                      const alertMetrics = conversation.metrics?.filter(metric => metric?.metric === "tAlert" && metric?.correct !== true ) || [];
                      const sumAlertMetrics = alertMetrics.reduce((sum, metric) => sum + (metric.stats.count || 0), 0);
                      return total + sumAlertMetrics;
                    }, 0);*/

                    // Second filtering: agentParticipants
                    const c_i_ringcalls = conversationsAll?.reduce((total, conversation) => {
      
                      let interactionTime = 0;
                          // Second filtering: agentParticipants
                          const agentParticipants = conversation?.details?.participants?.filter(participant => participant.purpose === 'agent' && participant.userId === item.group.userId) || [];
                          agentParticipants?.forEach(agentParticipant => {
                            agentParticipant?.sessions?.forEach(session => {
                              const sessionTTalkMetrics = session.metrics?.filter(metric => metric?.name === "tAlert") || [];
                              //const hasTAnswered = session.metrics?.some(metric => metric?.name === "tAnswered");
                              //if (sessionTTalkMetrics.length > 0 && hasTAnswered) {
                              if (sessionTTalkMetrics.length) {
                                interactionTime++;
                                RINGCALLSIDS.push(conversation.conversationId)
                              }
                            });
                          });
                        
                        

                        return total + interactionTime;
                      }, 0);


                  //Total transferidas

                  const c_t_transferred = conversationsAll?.reduce((total, conversation) => {
                    //const customerParticipant = conversation.details?.participants?.findLast(participant => participant?.purpose === "customer");
                    const agentParticipant= conversation.details?.participants?.findLast(participant=>participant?.purpose === "agent" && participant.userId === item.group.userId);
                    const sumFinishConversation = conversation.metrics?.find(metric => metric?.metric === "tTalkComplete")?.stats?.count || 0;

                    // && customerParticipant!==null && customerParticipant?.sessions!==undefined && customerParticipant?.sessions!==null
                          if (sumFinishConversation>0 && agentParticipant) {
                              const lastSessionA = agentParticipant.sessions[agentParticipant.sessions.length - 1];
                              //const validSegmentsA = lastSessionA.segments?.filter(segment => (segment.segmentType === "wrapup" && segment.disconnectType === "transfer") || (segment.segmentType === "interact" && segment.disconnectType === "transfer") );
                              const validSegmentsA = lastSessionA.segments?.filter(segment => (segment.disconnectType === "transfer") );
// && Array.isArray(lastSessionA.destinationAddresses)
                              if (validSegmentsA && validSegmentsA.length > 0 && lastSessionA.destinationAddresses.length > 0) {
                                  return total + 1;
                              }
                          }

                          return total;
                      }, 0)
                    
                  //Total conferencias
                    const c_t_conference = conversationsAll?.reduce((total, conversation) => {
                          const customerParticipant = conversation.details?.participants?.findLast(participant => participant?.purpose === "customer");
                          const agentParticipant= conversation.details?.participants?.findLast(participant=>participant?.purpose === "agent" && participant.userId === item.group.userId);

                          if (agentParticipant && customerParticipant!==null && customerParticipant?.sessions!==undefined && customerParticipant?.sessions!==null) {
                              const lastSession = customerParticipant.sessions[customerParticipant.sessions?.length - 1];
                              const validSegments = lastSession.segments?.filter(segment =>  segment.segmentType === "interact" && segment.disconnectType === "endpoint");

                              const lastSessionA = agentParticipant.sessions[agentParticipant.sessions.length - 1];
                              const validSegmentsA = lastSessionA.segments?.filter(segment => segment.segmentType === "interact" && segment.conference === true);

                              if (validSegments && validSegments.length > 0 && validSegmentsA && validSegmentsA.length > 0) {
                                  return total + 1;
                              }
                          }

                          return total;
                      }, 0)

                     /* const c_acdcalls = conversationsAll?.reduce((total, conversation) => {
                        // Validación para verificar si la conversación pasó por un segmento de ACD
                        const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                        let passedThroughACD = false;
                        // Verificar si existe un participant anterior con purpose 'acd'
                        if (userParticipantIndex > 0) {
                          for (let i = userParticipantIndex - 1; i >= 0; i--) {
                            if (conversation.details.participants[i].purpose === 'acd') {
                              passedThroughACD = true;
                              break;
                            }
                          }
                        }

                        // Proceder solo si la conversación pasó por un segmento de ACD
                        if (passedThroughACD) {
                          // First filtering: tTalkMetrics
                          const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "endedConversacion") || [];
                          let interactionTime = 0;
                          if (tTalkMetrics.length > 0) {
                              // Second filtering: agentParticipant
                              const agentParticipant = conversation?.details?.participants?.find(participant => participant.purpose === 'agent' && participant.userId === item.group.userId);
                              if (agentParticipant) {
                                  agentParticipant.sessions.forEach(session => {
                                      
                                      //const sessionTAnsweredMetrics = session.metrics?.filter(metric => metric?.name === "tAnswered") || [];
                                      //interactionTime += sessionTAnsweredMetrics.reduce((total, metric) => total + (metric.value || 0), 0);

                                      const sessionTTalkMetrics = session.metrics?.filter(metric => metric?.name === "tTalkComplete") || [];
                                      
                                      if(sessionTTalkMetrics.length>0){
                                        interactionTime++
                                        ACDCALLSIDS.push(conversation.conversationId)
                                      }
                                      ///ACDCALLSIDS.push(conversation.conversationId)
                                  });
                              }
                          }

                          

                          return total + interactionTime;
                        }

                        return total; // Si no pasó por ACD, retornar el total actual sin cambios
                      }, 0);*/

                      const c_acdcalls = conversationsAll?.reduce((total, conversation) => {
                        // Validación para verificar si la conversación pasó por un segmento de ACD
                        const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId);

                        let passedThroughACD = false;
                        // Verificar si existe un participant anterior con purpose 'acd'
                        if (userParticipantIndex > 0) {
                          for (let i = userParticipantIndex - 1; i >= 0; i--) {
                            if (conversation?.details?.participants[i].purpose === 'acd') {
                              passedThroughACD = true;
                              break;
                            }
                          }
                        }

                        // Proceder solo si la conversación pasó por un segmento de ACD
                        if (passedThroughACD) {
                          // First filtering: tTalkMetrics
                          const tTalkMetrics = conversation?.metrics?.filter(metric => metric?.metric === "endedConversacion") || [];
                          const tTalkCompleteMetric = conversation?.metrics?.filter(metric => metric?.metric === "tTalkComplete") || [];
                          const tHandleMetrics = conversation?.metrics?.filter(metric => metric?.metric === "tHandle") || [];

                          let interactionTime = 0;
                          if (tTalkMetrics.length > 0 || (tTalkCompleteMetric.length > 0 && tHandleMetrics.length > 0)) {
                            // Second filtering: agentParticipants
                            const agentParticipants = conversation?.details?.participants?.filter(participant => participant.purpose === 'agent' && participant.userId === item.group.userId) || [];
                            agentParticipants?.forEach(agentParticipant => {
                              agentParticipant.sessions.forEach(session => {
                                // Filtrar métricas de sesión con nombre "tTalkComplete"
                                const sessionTTalkMetrics = session.metrics?.filter(metric => metric?.name === "tTalkComplete") || [];
                                if (sessionTTalkMetrics.length > 0) {
                                  interactionTime++;
                                  ACDCALLSIDS.push(conversation.conversationId);
                                }
                              });
                            });
                          }
                          return total + interactionTime;
                        }

                        return total; // Si no pasó por ACD, retornar el total actual sin cambios
                      }, 0);

                      const c_t_ringcalls = conversationsAll?.reduce((total, conversation) => {
                        // First filtering: tTalkMetrics
                        const tTalkMetrics = conversation?.metrics?.filter(metric => metric?.metric === "endedConversacion") || [];
                        let interactionTime = 0;
                        if (tTalkMetrics.length > 0) {
                          // Second filtering: agentParticipants
                          const agentParticipants = conversation?.details?.participants?.filter(participant => participant.purpose === 'agent' && participant.userId === item.group.userId) || [];
                          agentParticipants?.forEach(agentParticipant => {
                            agentParticipant?.sessions?.forEach(session => {
                              const sessionTTalkMetrics = session.metrics?.filter(metric => metric?.name === "tAlert") || [];
                              //const hasTAnswered = session.metrics?.some(metric => metric?.name === "tAnswered");
                              //if (sessionTTalkMetrics.length > 0 && hasTAnswered) {
                              if (sessionTTalkMetrics.length) {
                                interactionTime++;
                                RINGCALLSIDS.push(conversation.conversationId)
                              }
                            });
                          });
                        }
                        

                        return total + interactionTime;
                      }, 0);

                      /*const c_t_ringcalls = conversationsAll?.reduce((total, conversation) => {
                        // First filtering: tTalkMetrics
                        const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "endedConversacion") || [];
                        let interactionTime = 0;
                        if (tTalkMetrics.length > 0) {
                          // Second filtering: agentParticipant
                          const agentParticipant = conversation?.details?.participants?.find(participant => participant.purpose === 'agent' && participant.userId === item.group.userId);
                          if (agentParticipant) {
                            agentParticipant.sessions.forEach(session => {
                              const sessionTTalkMetrics = session.metrics?.filter(metric => metric?.name === "tAlert") || [];
                              if (sessionTTalkMetrics.length > 0) {
                                interactionTime += sessionTTalkMetrics.reduce((sum, stats) => sum + (stats.count || 0), 0);
                              }
                            });
                          }
                        }
                        return total + interactionTime;
                      }, 0);*/


                    //Total llamdas en holds
                    const c_t_holdcalls = conversationsAll?.reduce((total, conversation) => {
                        const metric = conversation?.metrics?.find(metric => metric?.metric === "tHeldComplete");
                        
                        const count = metric?.stats.count || 0;
                        return total + (count>0? 1 :0);
                      }, 0)

                      /*const c_t_holdcalls = conversationsAll?.reduce((total, conversation) => {
                        const tTalkMetrics = conversation?.metrics?.filter(metric => metric?.metric === "endedConversacion") || [];

                        if (tTalkMetrics.length > 0) {
                          const metrics = conversation?.metrics?.filter(metric => metric?.metric === "tHeldComplete");

                          //const count = metrics?.reduce((acc, metric) => acc + (metric?.stats?.count || 0), 0);
                          return total + metrics.length;
                        }

                      }, 0);*/

                      const c_t_abncalls = conversationsAll?.reduce((total, conversation) => {
                        const agentParticipant = conversation?.details?.participants?.findLast(participant => participant.purpose === "agent" && participant.userId === item.group.userId);
                        const customerParticipant = conversation?.details?.participants?.findLast(participant => participant.purpose === "customer");

                        if (agentParticipant && customerParticipant) {
                          const lastSessionCustomer = customerParticipant.sessions[customerParticipant.sessions.length-1];
                          const lastSessionAgent = agentParticipant.sessions[agentParticipant.sessions.length-1];
                          
                          //const alertSegmentExists = agentParticipant.sessions.some(session => 
                            //session.segments?.some(segment => segment.segmentType === 'alert')
                          //);

                          // Verificar que no existan segmentos de tipo "interact" en todas las sesiones del cliente, manteniendo la validación de disconnectType
                          const customerSessionsValid = lastSessionCustomer.segments?.some(segment => 
                            (segment.segmentType == "interact" && (segment.disconnectType === "endpoint"))
                          );

                          // Verificar que no existan segmentos de tipo "interact" en todas las sesiones del agente, manteniendo la validación de disconnectType
                          const agentSessionsValid = lastSessionAgent.segments?.some(segment => 
                            (segment.segmentType == "alert" && (segment.disconnectType === "peer"))
                          );
                          //alertSegmentExists && 
                          if (customerSessionsValid && agentSessionsValid) {
                            ABNCALLSIDS.push(conversation.conversationId)
                            return total + 1;
                            
                          }
                        }

                        return total;
                      }, 0);



                  const c_t_holdabncalls = conversationsAll?.reduce((total, conversation) => {
                          const lastParticipant = conversation.details?.participants[conversation.details.participants.length - 1];
                          const agentParticipant = lastParticipant && lastParticipant.purpose === "agent" && lastParticipant.userId === item.group.userId ? lastParticipant : null;
                          //const agentParticipant = conversation.details.participants?.findLast(participant => participant.purpose === "agent" && participant.userId === presenciasDeUsuario.userId);
                          const customerParticipant = conversation.details?.participants?.findLast(participant => participant.purpose === "customer");

                          if (agentParticipant && customerParticipant!==null && customerParticipant?.sessions!==undefined && customerParticipant?.sessions!==null) {
                              const lastSession = customerParticipant.sessions[customerParticipant.sessions?.length - 1];
                              const validSegments = lastSession.segments?.filter(segment => segment.disconnectType === "interact" && segment.disconnectType === "endpoint");

                              const lastSessionA = agentParticipant.sessions[agentParticipant.sessions.length - 1];
                              const validSegmentsA = lastSessionA.segments?.filter(segment => segment.segmentType === "hold" && segment.disconnectType === "peer");

                              if (validSegments && validSegments.length > 0 && validSegmentsA && validSegmentsA.length > 0) {
                                  return total + 1;
                              }
                          }

                          return total;
                      }, 0)
                  

                  
                      /*
                  //ACD time total
                  const c_t_acdtime = conversationsAll?.reduce((total, conversation) => {
                    const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                    let passedThroughACD = false;
                    // Verificar si existe un participant anterior con purpose 'acd'
                    if (userParticipantIndex > 0) {
                      for (let i = userParticipantIndex - 1; i >= 0; i--) {
                        if (conversation.details.participants[i].purpose === 'acd') {
                          passedThroughACD = true;
                          break;
                        }
                      }
                    }

                      // Proceder solo si la conversación pasó por un segmento de ACD
                      if (passedThroughACD) {
                          // First filtering: tTalkMetrics
                          const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "endedConversacion") || [];
                          let interactionTime = 0;
                          if (tTalkMetrics.length > 0) {
                              // Second filtering: agentParticipant
                              const agentParticipant = conversation?.details?.participants?.find(participant => participant.purpose === 'agent' && participant.userId === item.group.userId);
                              if (agentParticipant) {
                                  agentParticipant.sessions.forEach(session => {
                                      
                                      //const sessionTAnsweredMetrics = session.metrics?.filter(metric => metric?.name === "tAnswered") || [];
                                      //interactionTime += sessionTAnsweredMetrics.reduce((total, metric) => total + (metric.value || 0), 0);

                                      const sessionTTalkMetrics = session.metrics?.filter(metric => metric?.name === "tTalkComplete") || [];
                                      interactionTime += sessionTTalkMetrics.reduce((total, metric) => total + (metric.value || 0), 0);
                                  });
                              }
                              //interactionTime = tTalkMetrics.reduce((total, metric) => total + (metric?.totals?.tTalk || 0), 0);
                          }
                          return total + interactionTime;

                        }
                        return total; // Si no pasó por ACD, retornar el total actual sin cambios
                      }, 0)/1000*/

                      const c_t_acdtime = conversationsAll?.reduce((total, conversation) => {
                        const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId);

                        let passedThroughACD = false;
                        // Verificar si existe un participant anterior con purpose 'acd'
                        if (userParticipantIndex > 0) {
                          for (let i = userParticipantIndex - 1; i >= 0; i--) {
                            if (conversation.details.participants[i].purpose === 'acd') {
                              passedThroughACD = true;
                              break;
                            }
                          }
                        }

                        // Proceder solo si la conversación pasó por un segmento de ACD
                        if (passedThroughACD) {
                          // First filtering: tTalkMetrics
                          const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "endedConversacion") || [];

                          const tTalkCompleteMetric = conversation?.metrics?.filter(metric => metric?.metric === "tTalkComplete") || [];
                          const tHandleMetrics = conversation?.metrics?.filter(metric => metric?.metric === "tHandle") || [];
                          

                          //const tTalkMetricsComplete = conversation?.metrics?.filter(metric => metric?.metric === "tTalk") || [];
                          // Verificar si existe algún tTalk con correct: true
                          //const correctTTalkMetricsComplete = tTalkMetricsComplete.filter(metric => metric.correct === true);
                          

                          let interactionTime = 0;
                          if (tTalkMetrics.length > 0 || (tTalkCompleteMetric.length > 0 && tHandleMetrics.length > 0)) {
                            // Second filtering: agentParticipants
                            const agentParticipants = conversation?.details?.participants?.filter(participant => participant.purpose === 'agent' && participant.userId === item.group.userId) || [];
                            agentParticipants.forEach(agentParticipant => {
                              agentParticipant.sessions.forEach(session => {
                                // Filtrar métricas de sesión con nombre "tTalkComplete"
                                const sessionTTalkMetrics = session.metrics?.filter(metric => metric?.name === "tTalk") || [];
                                interactionTime += sessionTTalkMetrics.reduce((total, metric) => total + (metric.value || 0), 0);
                              });
                            }); 

                            //interactionTime = correctTTalkMetricsComplete.reduce((total, metric) => total + (metric.complete || 0), 0)
                          }
                          return total + interactionTime;
                        }

                        return total; // Si no pasó por ACD, retornar el total actual sin cambios
                      }, 0) / 1000;
                      
                      
                  //ACW time total
                  const c_t_acwtime = conversationsAll?.reduce((total, conversation) => {
                    const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                      let passedThroughACD = false;
                      // Verificar si existe un participant anterior con purpose 'acd'
                      if (userParticipantIndex > 0) {
                        for (let i = userParticipantIndex - 1; i >= 0; i--) {
                          if (conversation.details.participants[i].purpose === 'acd') {
                            passedThroughACD = true;
                            break;
                          }
                        }
                      }

                    // Proceder solo si la conversación pasó por un segmento de ACD
                    if (passedThroughACD) {
                          // First filtering: tTalkMetrics
                          const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "endedConversacion") || [];
                          let interactionTime = 0;
                          if (tTalkMetrics.length > 0) {
                              // Second filtering: agentParticipant
                              /*const agentParticipant = conversation?.details?.participants?.find(participant => participant.purpose === 'agent' && participant.userId === item.group.userId);
                              if (agentParticipant) {
                                  agentParticipant.sessions.forEach(session => {
                                      
                                      //const sessionTAnsweredMetrics = session.metrics?.filter(metric => metric?.name === "tAnswered") || [];
                                      //interactionTime += sessionTAnsweredMetrics.reduce((total, metric) => total + (metric.value || 0), 0);

                                      const sessionTTalkMetrics = session.metrics?.filter(metric => metric?.name === "tAcw") || [];
                                      interactionTime += sessionTTalkMetrics.reduce((total, metric) => total + (metric.value || 0), 0);
                                  });
                              }*/
                              interactionTime = tTalkMetrics.reduce((total, metric) => total + (metric?.totals?.tAcw || 0), 0);
                          }
                          return total + interactionTime;
                        }

                        return total; // Si no pasó por ACD, retornar el total actual sin cambios
                      }, 0)/1000
                  
                  //Ring time total
                  const c_t_ringtime = conversationsAll?.reduce((total, conversation) => {

                    const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                      let passedThroughACD = false;
                      // Verificar si existe un participant anterior con purpose 'acd'
                      if (userParticipantIndex > 0) {
                        for (let i = userParticipantIndex - 1; i >= 0; i--) {
                          if (conversation.details.participants[i].purpose === 'acd') {
                            passedThroughACD = true;
                            break;
                          }
                        }
                      }

                    // Proceder solo si la conversación pasó por un segmento de ACD
                    if (passedThroughACD) {
                          // First filtering: tTalkMetrics
                          const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "endedConversacion") || [];
                          let interactionTime = 0;
                          if (tTalkMetrics.length > 0) {
                              // Second filtering: agentParticipant
                              /*const agentParticipant = conversation?.details?.participants?.find(participant => participant.purpose === 'agent' && participant.userId === item.group.userId);
                              if (agentParticipant) {
                                  agentParticipant.sessions.forEach(session => {

                                      const sessionTTalkMetrics = session.metrics?.filter(metric => metric?.name === "tAlert") || [];
                                      interactionTime += sessionTTalkMetrics.reduce((total, metric) => total + (metric.value || 0), 0);
                                  });
                              }*/
                              interactionTime = tTalkMetrics.reduce((total, metric) => total + (metric?.totals?.tAlert || 0), 0);
                          }
                          return total + interactionTime;
                        }
                        return total; // Si no pasó por ACD, retornar el total actual sin cambios
                      }, 0)/1000

                  const ansringtime = conversationsAll?.reduce((total, conversation) => {
                          // First filtering: tTalkMetrics
                          const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "endedConversacion") || [];
                          let interactionTime = 0;
                          if (tTalkMetrics.length > 0) {
                              // Second filtering: agentParticipant
                              const agentParticipant = conversation?.details?.participants?.find(participant => participant.purpose === 'agent' && participant.userId === item.group.userId);
                              if (agentParticipant) {
                                  agentParticipant.sessions.forEach(session => {

                                      const sessionTTalkMetrics = session.metrics?.filter(metric => metric?.name === "tAnswered") || [];
                                      interactionTime += sessionTTalkMetrics.reduce((total, metric) => total + (metric.value || 0), 0);
                                  });
                              }
                          }
                          return total + interactionTime;
                      }, 0)/1000
                  
                  //Hold time total
                  const c_t_holdtime = conversationsAll?.reduce((total, conversation) => {
                          // First filtering: tTalkMetrics
                          const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "endedConversacion") || [];
                          let interactionTime = 0;
                          if (tTalkMetrics.length > 0) {
                              // Second filtering: agentParticipant
                              /*const agentParticipant = conversation?.details?.participants?.find(participant => participant.purpose === 'agent' && participant.userId === item.group.userId);
                              if (agentParticipant) {
                                  agentParticipant.sessions.forEach(session => {
                                      
                                      //const sessionTAnsweredMetrics = session.metrics?.filter(metric => metric?.name === "tAnswered") || [];
                                      ///interactionTime += sessionTAnsweredMetrics.reduce((total, metric) => total + (metric.value || 0), 0);

                                      const sessionTTalkMetrics = session.metrics?.filter(metric => metric?.name === "tHeld") || [];
                                      interactionTime += sessionTTalkMetrics.reduce((total, metric) => total + (metric.value || 0), 0);
                                  });
                              }*/

                              interactionTime = tTalkMetrics.reduce((total, metric) => total + (metric?.totals?.tHold || 0), 0);
                          }
                          return total + interactionTime;
                      }, 0)/1000

                      /*const c_t_acwouttime = conversationsOut?.reduce((total, conversation) => {
                          // First filtering: tTalkMetrics
                          //const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "endedConversacion") || [];
                          const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "tAcw") || [];
                          let interactionTime = 0;
                          if (tTalkMetrics.length > 0) {
                              // Second filtering: agentParticipant
                              const agentParticipant = conversation?.details?.participants?.find(participant => participant.purpose === 'agent' && participant.userId === item.group.userId);
                              if (agentParticipant) {
                                  agentParticipant.sessions.forEach(session => {
                                      
                                      //const sessionTAnsweredMetrics = session.metrics?.filter(metric => metric?.name === "tAnswered") || [];
                                      //interactionTime += sessionTAnsweredMetrics.reduce((total, metric) => total + (metric.value || 0), 0);

                                      const sessionTTalkMetrics = session.metrics?.filter(metric => metric?.name === "tAcw") || [];
                                      interactionTime += sessionTTalkMetrics.reduce((total, metric) => total + (metric.value || 0), 0);
                                  });
                              }
                          }
                          return total + interactionTime;
                      }, 0)/1000

                      const c_t_acwoutcalls = conversationsOut?.reduce((total, conversation) => {
                        const tTalkMetricsEnded = conversation.metrics?.filter(metric => metric?.metric === "endedConversacion") || [];

                        if (tTalkMetricsEnded.length > 0) {
                          // First filtering: tTalkMetrics
                          const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "tAcw") || [];
                          let interactionTime = 0;
                          if (tTalkMetrics.length > 0) {
                              // Second filtering: agentParticipant
                              const agentParticipant = conversation?.details?.participants?.find(participant => participant.purpose === 'agent' && participant.userId === item.group.userId);
                              if (agentParticipant) {
                                  agentParticipant.sessions.forEach(session => {

                                      const sessionTTalkMetrics = session.metrics?.filter(metric => metric?.name === "tAcw") || [];
                                      if(sessionTTalkMetrics.length>0){
                                        interactionTime++
                                      }
                                      
                                  });
                              }
                          }
                          return total + interactionTime
                          
                        }

                      }, 0)*/

                      const c_t_acwoutcalls = 0
                      const c_t_acwouttime = 0

                    /*const c_t_auxoutcalls = conversationsOut?.reduce((total, conversation) => {
                          // First filtering: tTalkMetrics
                          const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "endedConversacion") || [];
                          let interactionTime = 0;
                          if (tTalkMetrics.length > 0) {
                              // Second filtering: agentParticipant
                              const agentParticipant = conversation?.details?.participants?.find(participant => participant.purpose === 'agent' && participant.userId === item.group.userId);
                              if (agentParticipant) {
                                  agentParticipant.sessions.forEach(session => {
                                      
                                      //const sessionTAnsweredMetrics = session.metrics?.filter(metric => metric?.name === "tAnswered") || [];
                                      //interactionTime += sessionTAnsweredMetrics.reduce((total, metric) => total + (metric.value || 0), 0);

                                      const sessionTTalkMetrics = session.metrics?.filter(metric => metric?.name === "tHandle") || [];
                                      if(sessionTTalkMetrics.length>0){
                                        interactionTime++
                                      }
                                      
                                  });
                              }
                          }
                          return total + interactionTime;
                      }, 0)*/

                      /*const c_t_auxoutcalls = conversationsOut?.reduce((total, conversation) => {
                        //const tTalkMetricsEnded = conversation.metrics?.filter(metric => metric?.metric === "endedConversacion") || [];

                        //if (tTalkMetricsEnded.length > 0) {
                          // First filtering: tTalkMetrics

                          
                          const tHandleMetrics = conversation.metrics?.filter(metric => metric?.metric === "tHandle") || [];
                          const tDialingMetrics = conversation.metrics?.filter(metric => metric?.metric === "tDialing") || [];
                          //const tContactingMetrics = conversation.metrics?.filter(metric => metric?.metric === "tContacting") || [];
                          let interactionTime = 0;

                          // || tContactingMetrics.length > 0
                          if ((tDialingMetrics.length >0 && tHandleMetrics.length > 0)) {
                            // Second filtering: agentParticipant
                            const agentParticipant = conversation?.details?.participants?.find(participant => (participant.purpose === 'agent') && participant.userId === item.group.userId);
                            if (agentParticipant) {
                              //const outboundSession = agentParticipant.sessions.find(session => session.direction === 'outbound');
                              //if (outboundSession) {
                                //const sessionTTalkMetrics = outboundSession.metrics?.filter(metric => metric?.name === "tHandle") || [];
                                //if (sessionTTalkMetrics.length > 0) {
                                  interactionTime++;
                                  SALIENTES.push(conversation.conversationId);
                                //}
                              //}
                            }
                          }
                          return total + interactionTime;
                        //} 
                      }, 0);*/

                      const c_t_auxoutcalls = conversationsOut?.reduce((total, conversation) => {
                        const tHandleMetrics = conversation.metrics?.filter(metric => metric?.metric === "tHandle") || [];
                        const tDialingMetrics = conversation.metrics?.filter(metric => metric?.metric === "tDialing") || [];
                        const tContactingMetrics = conversation.metrics?.filter(metric => metric?.metric === "tContacting") || [];
                        let interactionTime = 0;

                        if (tDialingMetrics.length > 0 || (tContactingMetrics.length > 0 && tHandleMetrics.length > 0)) {
                          const agentParticipants = conversation?.details?.participants?.filter(participant => (participant.purpose === 'agent') && participant.userId === item.group.userId) || [];
                          agentParticipants.forEach(agentParticipant => {
                            const outboundSessions = agentParticipant.sessions.filter(session => session.direction === 'outbound');
                            //outboundSessions.forEach(session => {
                              //const sessionTTalkMetrics = session.metrics?.filter(metric => metric?.name === "tHandle") || [];
                              //if (sessionTTalkMetrics.length > 0) {
                                if (outboundSessions.length > 0) {
                                interactionTime++;
                                SALIENTES.push(conversation.conversationId);
                                }
                              //}
                            //});
                          });
                        }
                        return total + interactionTime;
                      }, 0);

                      const c_t_auxouttime = conversationsOut?.reduce((total, conversation) => {

                          /*const tDialingMetrics = conversation.metrics?.filter(metric => metric?.metric === "tDialing") || [];
                          const tContactingMetrics = conversation.metrics?.filter(metric => metric?.metric === "tContacting") || [];
                          let interactionTime = 0;
                          if ((tDialingMetrics.length > 0 || tContactingMetrics.length > 0)) {
                            // Second filtering: agentParticipant
                            const agentParticipant = conversation?.details?.participants?.find(participant => (participant.purpose === 'agent') && participant.userId === item.group.userId);
                            if (agentParticipant) {
                              const outboundSession = agentParticipant.sessions.find(session => session.direction === 'outbound');
                              if (outboundSession) {
                                const sessionTTalkMetrics = outboundSession.metrics?.filter(metric => metric?.name === "tHandle") || [];
                                if (sessionTTalkMetrics.length > 0) {
                                  interactionTime += sessionTTalkMetrics.reduce((total, metric) => total + (metric.value || 0), 0);
                                }
                              }
                            }
                          }
                          return total + interactionTime;

                          const tDialingMetrics = conversation.metrics?.filter(metric => metric?.metric === "tDialing") || [];
                          let interactionTime = 0;

                          if (tDialingMetrics.length > 0) {
                            const agentParticipants = conversation?.details?.participants?.filter(participant => (participant.purpose === 'agent') && participant.userId === item.group.userId) || [];
                            agentParticipants.forEach(agentParticipant => {
                              const outboundSessions = agentParticipant.sessions.filter(session => session.direction === 'outbound');
                              outboundSessions.forEach(session => {
                                const sessionTTalkMetrics = session.metrics?.filter(metric => metric?.name === "tHandle") || [];
                                if (sessionTTalkMetrics.length > 0) {
                                  //if (outboundSessions.length > 0) {
                                    interactionTime += sessionTTalkMetrics.reduce((total, metric) => total + (metric.value || 0), 0);
                                  //}
                                }
                              });
                            });
                          }
                          return total + interactionTime;*/

                          if(c_t_auxoutcalls > 0){
                            const tHandleMetrics = conversation.metrics?.filter(metric => metric?.metric === "tHandle") || [];
                            const tDialingMetrics = conversation.metrics?.filter(metric => metric?.metric === "tDialing") || [];
                            const tContactingMetrics = conversation.metrics?.filter(metric => metric?.metric === "tContacting") || [];
                            let interactionTime = 0;

                            if (tDialingMetrics.length > 0 || (tContactingMetrics.length > 0 && tHandleMetrics.length > 0)) {
                              const agentParticipants = conversation?.details?.participants?.filter(participant => (participant.purpose === 'agent') && participant.userId === item.group.userId) || [];
                              agentParticipants.forEach(agentParticipant => {
                                const outboundSessions = agentParticipant.sessions.filter(session => session.direction === 'outbound');
                                outboundSessions.forEach(session => {
                                  const sessionTTalkMetrics = session.metrics?.filter(metric => metric?.name === "tHandle") || [];
                                  if (sessionTTalkMetrics.length > 0) {
                                    if (outboundSessions.length > 0) {
                                      interactionTime += sessionTTalkMetrics.reduce((total, metric) => total + (metric.value || 0), 0);

                                    }
                                  }
                                });
                              });
                            }
                            return total + interactionTime;
                          }else{
                            return total
                          }

                          
                        
                      }, 0)/1000

                    /*const c_t_auxouttime = conversationsOut?.reduce((total, conversation) => {
               
                          // First filtering: tTalkMetrics
                          const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "endedConversacion") || [];
                          let interactionTime = 0;
                          if (tTalkMetrics.length > 0) {
                              // Second filtering: agentParticipant
                              const agentParticipant = conversation?.details?.participants?.find(participant => participant.purpose === 'agent' && participant.userId === item.group.userId);
                              if (agentParticipant) {
                                  agentParticipant.sessions.forEach(session => {
                                      
                                      //const sessionTAnsweredMetrics = session.metrics?.filter(metric => metric?.name === "tAnswered") || [];
                                      //interactionTime += sessionTAnsweredMetrics.reduce((total, metric) => total + (metric.value || 0), 0);

                                      const sessionTTalkMetrics = session.metrics?.filter(metric => metric?.name === "tTalkComplete") || [];
                                      interactionTime += sessionTTalkMetrics.reduce((total, metric) => total + (metric.value || 0), 0);
                                  });
                              }
                          }
                          return total + interactionTime;

                       
                      }, 0)/1000*/
                      
                    /*
const c_t_transferred = conversationsAll?.reduce((total, conversation) => {
                    //const customerParticipant = conversation.details?.participants?.findLast(participant => participant?.purpose === "customer");
                    const agentParticipant= conversation.details?.participants?.findLast(participant=>participant?.purpose === "agent" && participant.userId === item.group.userId);
                    const sumFinishConversation = conversation.metrics?.find(metric => metric?.metric === "tTalkComplete")?.stats?.count || 0;

                    // && customerParticipant!==null && customerParticipant?.sessions!==undefined && customerParticipant?.sessions!==null
                          if (sumFinishConversation>0 && agentParticipant) {
                              const lastSessionA = agentParticipant.sessions[agentParticipant.sessions.length - 1];
                              //const validSegmentsA = lastSessionA.segments?.filter(segment => (segment.segmentType === "wrapup" && segment.disconnectType === "transfer") || (segment.segmentType === "interact" && segment.disconnectType === "transfer") );
                              const validSegmentsA = lastSessionA.segments?.filter(segment => (segment.segmentType === "wrapup" && segment.disconnectType === "transfer") );
// && Array.isArray(lastSessionA.destinationAddresses)
                              if (validSegmentsA && validSegmentsA.length > 0 && lastSessionA.destinationAddresses.length > 0) {
                                  return total + 1;
                              }
                          }

                          return total;
                      }, 0)
                    */

                    const c_t_transferredtime = conversationsAll?.reduce((total, conversation) => {
                          //const customerParticipant = conversation.details?.participants?.findLast(participant => participant?.purpose === "customer");
                          const agentParticipant= conversation.details?.participants?.findLast(participant=>participant?.purpose === "agent" && participant.userId === item.group.userId);
                          const sumFinishConversation = conversation.metrics?.find(metric => metric?.metric === "tTalkComplete")?.stats?.count || 0;
 ///&& customerParticipant!==null && customerParticipant?.sessions!==undefined && customerParticipant?.sessions!==null
                          if (sumFinishConversation>0 && agentParticipant) {
                              const lastSessionA = agentParticipant.sessions[agentParticipant.sessions.length - 1];
                              const validSegmentsA = lastSessionA.segments?.filter(segment => segment.disconnectType === "transfer");
// && Array.isArray(lastSessionA.destinationAddresses) 
                              if (validSegmentsA && validSegmentsA.length > 0 && lastSessionA.destinationAddresses.length > 0) {
                                  const nextParticipantIndex = conversation.details?.participants?.findIndex(participant => participant === agentParticipant) + 1;
                                  const nextParticipant = conversation.details?.participants?.[nextParticipantIndex];

                                  if (nextParticipant) {
                                      const lastSessionNext = nextParticipant.sessions[nextParticipant.sessions.length - 1];
                                      const systemSegment = lastSessionNext.segments[0];

                                      if (systemSegment) {
                                          const transferTime = new Date(systemSegment.segmentEnd).getTime() - new Date(systemSegment.segmentStart).getTime();
                                          return total + transferTime;
                                      }
                                  }
                              }
                          }

                          return total;
                      }, 0)/1000

                      const c_t_abntime = conversationsAll?.reduce((total, conversation) => {
                        const agentParticipant = conversation.details?.participants?.findLast(participant => participant.purpose === "agent" && participant.userId === item.group.userId);
                        const customerParticipant = conversation.details?.participants?.findLast(participant => participant.purpose === "customer");

                        if (agentParticipant && customerParticipant !== null && customerParticipant?.sessions !== undefined && customerParticipant?.sessions !== null) {
                          const lastSessionCustomer = customerParticipant.sessions[customerParticipant.sessions?.length - 1];
                          const lastSessionAgent = agentParticipant.sessions[agentParticipant.sessions.length - 1];
                          
                          // Nueva validación: asegurar que exista al menos un segmento con segmentType == 'alert'
                          const alertSegmentExists = agentParticipant.sessions.some(session => 
                            session.segments?.some(segment => segment.segmentType === 'alert')
                          )

                          // Verificar que no existan segmentos de tipo "interact" en todas las sesiones del cliente, manteniendo la validación de disconnectType
                          const customerSessionsValid = lastSessionCustomer.segments?.some(segment => 
                            (segment.segmentType == "interact" && (segment.disconnectType === "endpoint"))
                          );

                          // Verificar que no existan segmentos de tipo "interact" en todas las sesiones del agente, manteniendo la validación de disconnectType
                          const agentSessionsValid = lastSessionAgent.segments?.some(segment => 
                            (segment.segmentType == "alert" && (segment.disconnectType === "peer"))
                          );

                          if (customerSessionsValid && alertSegmentExists && agentSessionsValid) {
                            // Encontrar el segmento con segmentType == 'alert' y calcular la diferencia de tiempo
                            const alertSegment = lastSessionAgent.segments?.find(segment => segment.segmentType === 'alert');
                            if (alertSegment) {
                              const startTime = new Date(alertSegment.segmentStart).getTime();
                              const endTime = new Date(alertSegment.segmentEnd).getTime();
                              const difference = endTime - startTime; // Diferencia en milisegundos
                              return total + difference;
                            }
                          }
                        }

                        return total;
                      }, 0)/1000

                      let c_i_othertime_acdtime_onQueue_extra_aux = 0

                      const c_i_othertime_acdtime = conversationsAllOther?.reduce((total, conversation) => {
                        const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                        let passedThroughACD = false;
                        // Verificar si existe un participant anterior con purpose 'acd'
                        if (userParticipantIndex > 0) {
                          for (let i = userParticipantIndex - 1; i >= 0; i--) {
                            if (conversation.details.participants[i].purpose === 'acd') {
                              passedThroughACD = true;
                              break;
                            }
                          }
                        }

                        // Proceder solo si la conversación pasó por un segmento de ACD
                        if (passedThroughACD) {
                          const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "tTalk") || [];
                          // Verificar si existe algún tTalk con correct: true
                          const correctTTalkMetrics = tTalkMetrics.filter(metric => metric.correct === true);

                          let sumACD =0;
                          let sumACDAUX =0;
                          if (correctTTalkMetrics.length > 0) {
                            // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                            sumACD = correctTTalkMetrics.reduce((total, metric) => total + (metric.onQueue || 0), 0);
                            sumACDAUX = correctTTalkMetrics.reduce((total, metric) => total + (metric.aux || 0), 0);
                            if(sumACDAUX>0){
                              c_i_othertime_acdtime_onQueue_extra_aux+=sumACDAUX
                            }
                          }

                          return total + sumACD;
                        }
                        return total
                      }, 0) / 1000

                      const c_i_othertime_acdtime_aux = conversationsAllOther?.reduce((total, conversation) => {
                        const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                        let passedThroughACD = false;
                        // Verificar si existe un participant anterior con purpose 'acd'
                        if (userParticipantIndex > 0) {
                          for (let i = userParticipantIndex - 1; i >= 0; i--) {
                            if (conversation.details.participants[i].purpose === 'acd') {
                              passedThroughACD = true;
                              break;
                            }
                          }
                        }

                        // Proceder solo si la conversación pasó por un segmento de ACD
                        if (passedThroughACD) {
                          const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "tTalk") || [];
                          // Verificar si existe algún tTalk con correct: true
                          const correctTTalkMetrics = tTalkMetrics.filter(metric => metric.correct === true);

                          let sumACD =0;
                          //let sumACDAUX =0;
                          if (correctTTalkMetrics.length > 0) {
                            // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                            sumACD = correctTTalkMetrics.reduce((total, metric) => total + (metric.auxO > 0? metric.auxO:metric.aux || 0), 0);
                            ///sumACDAUX = correctTTalkMetrics.reduce((total, metric) => total + (metric.aux || 0), 0);
                            /*if(sumACDAUX>0){
                              c_i_othertime_acdtime_onQueue_extra_aux+=sumACDAUX
                            }*/
                          }

                          return total + sumACD;
                        }
                        return total
                      }, 0) / 1000

                      const c_i_othertime_NONacd_aux = conversationsAllOther?.reduce((total, conversation) => {
                        const tDialingMetric = conversation.metrics?.find(metric => metric?.metric === "tDialing");
                        const tContactingMetric = conversation.metrics?.find(metric => metric?.metric === "tContacting");
                        const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "tTalk") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTTalkMetrics = tTalkMetrics.filter(metric => metric.correct === true);

                        if(tDialingMetric===undefined && tContactingMetric===undefined){
                          return total
                        }

                        let sumACD;
                        if (correctTTalkMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumACD = correctTTalkMetrics.reduce((total, metric) => total + (metric.auxO > 0? metric.auxO:metric.aux || 0), 0);
                        } else {
                          // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumACD = tTalkMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }
                        //const count = tDialingMetric?.stats.sum || 0;
                        //return total + sumACD + count;
                        return total + sumACD;
                      }, 0) / 1000


                      const c_i_othertime_acwtime = conversationsAllOther?.reduce((total, conversation) => {
                        const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                        let passedThroughACD = false;
                        // Verificar si existe un participant anterior con purpose 'acd'
                        if (userParticipantIndex > 0) {
                          for (let i = userParticipantIndex - 1; i >= 0; i--) {
                            if (conversation.details.participants[i].purpose === 'acd') {
                              passedThroughACD = true;
                              break;
                            }
                          }
                        }

                        // Proceder solo si la conversación pasó por un segmento de ACD
                        if (passedThroughACD) {

                          const tAcwMetrics = conversation.metrics?.filter(metric => metric?.metric === "tAcw") || [];
                          // Verificar si existe algún tTalk con correct: true
                          const correctTtAcwMetrics = tAcwMetrics.filter(metric => metric.correct === true);

                          let sumACW =0;
                          if (correctTtAcwMetrics.length > 0) {
                            // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                            sumACW = correctTtAcwMetrics.reduce((total, metric) => total + (metric.onQueue || 0), 0);
                          }

                          return total + sumACW;
                        }
                        return total // Si no pasó por ACD, retornar el total actual sin cambios*/
                      }, 0) / 1000

                      const c_i_othertime_acwtime_aux = conversationsAllOther?.reduce((total, conversation) => {
                        const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                        let passedThroughACD = false;
                        // Verificar si existe un participant anterior con purpose 'acd'
                        if (userParticipantIndex > 0) {
                          for (let i = userParticipantIndex - 1; i >= 0; i--) {
                            if (conversation.details.participants[i].purpose === 'acd') {
                              passedThroughACD = true;
                              break;
                            }
                          }
                        }

                        // Proceder solo si la conversación pasó por un segmento de ACD
                        if (passedThroughACD) {

                          const tAcwMetrics = conversation.metrics?.filter(metric => metric?.metric === "tAcw") || [];
                          // Verificar si existe algún tTalk con correct: true
                          const correctTtAcwMetrics = tAcwMetrics.filter(metric => metric.correct === true);

                          let sumACW =0;
                          if (correctTtAcwMetrics.length > 0) {
                            // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                            sumACW = correctTtAcwMetrics.reduce((total, metric) => total + (metric.auxO > 0? metric.auxO:metric.aux || 0), 0);
                          }else{
                            // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                            sumACW = tAcwMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                          }

                          return total + sumACW;
                        }
                        return total // Si no pasó por ACD, retornar el total actual sin cambios*/
                      }, 0) / 1000


                      const c_i_othertime_NONacw_aux = conversationsAllOther?.reduce((total, conversation) => {
                        const tDialingMetric = conversation.metrics?.find(metric => metric?.metric === "tDialing");
                        const tContactingMetric = conversation.metrics?.find(metric => metric?.metric === "tContacting");
                        const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "tAcw") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTTalkMetrics = tTalkMetrics.filter(metric => metric.correct === true);

                        if(tDialingMetric===undefined && tContactingMetric===undefined){
                          return total
                        }

                        let sumACD;
                        if (correctTTalkMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumACD = correctTTalkMetrics.reduce((total, metric) => total + (metric.aux || 0), 0);
                        }else{
                            // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumACD = tTalkMetrics.reduce((total, metric) => total + (metric.auxO > 0? metric.auxO:metric.aux || 0), 0);
                        }
                        //const count = tDialingMetric?.stats.sum || 0;
                        //return total + sumACD + count;
                        return total + sumACD;
                      }, 0) / 1000

                      /*const c_i_othertime_holdtime = conversationsAllOther.reduce((total, conversation) => {
                          const sumtAcw = conversation.metrics?.filter(metric => metric?.metric === "tHeld") || [];
                          const sumtAcwF = sumtAcw.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                          return total + sumtAcwF;
                      }, 0)/1000*/

                      const c_i_othertime_holdtime = conversationsAllOther?.reduce((total, conversation) => {
                        const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                        let passedThroughACD = false;
                        // Verificar si existe un participant anterior con purpose 'acd'
                        if (userParticipantIndex > 0) {
                          for (let i = userParticipantIndex - 1; i >= 0; i--) {
                            if (conversation.details.participants[i].purpose === 'acd') {
                              passedThroughACD = true;
                              break;
                            }
                          }
                        }

                        // Proceder solo si la conversación pasó por un segmento de ACD
                        if (passedThroughACD) {
                          const tHoldMetrics = conversation.metrics?.filter(metric => metric?.metric === "tHold") || [];
                          // Verificar si existe algún tTalk con correct: true
                          const correctTHoldMetrics = tHoldMetrics.filter(metric => metric.correct === true);

                          let sumHold =0;
                          if (correctTHoldMetrics.length > 0) {
                            // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                            sumHold = correctTHoldMetrics.reduce((total, metric) => total + (metric.onQueue || 0), 0);
                          }

                          return total + sumHold;
                        }
                        return total // Si no pasó por ACD, retornar el total actual sin cambios
                      }, 0) / 1000

                      const c_i_othertime_holdtime_auxtime = conversationsAllOther?.reduce((total, conversation) => {
                        const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                        let passedThroughACD = false;
                        // Verificar si existe un participant anterior con purpose 'acd'
                        if (userParticipantIndex > 0) {
                          for (let i = userParticipantIndex - 1; i >= 0; i--) {
                            if (conversation.details.participants[i].purpose === 'acd') {
                              passedThroughACD = true;
                              break;
                            }
                          }
                        }

                        // Proceder solo si la conversación pasó por un segmento de ACD
                        if (passedThroughACD) {
                          const tHoldMetrics = conversation.metrics?.filter(metric => metric?.metric === "tHold") || [];
                          // Verificar si existe algún tTalk con correct: true
                          const correctTHoldMetrics = tHoldMetrics.filter(metric => metric.correct === true);

                          let sumHold =0;
                          if (correctTHoldMetrics.length > 0) {
                            // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                            sumHold = correctTHoldMetrics.reduce((total, metric) => total + (metric.auxO > 0? metric.auxO:metric.aux || 0), 0);
                          }

                          return total + sumHold;
                        }
                        return total // Si no pasó por ACD, retornar el total actual sin cambios
                      }, 0) / 1000

                      const c_i_othertime_ringtime = conversationsAllOther?.reduce((total, conversation) => {
                        const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                        let passedThroughACD = false;
                        // Verificar si existe un participant anterior con purpose 'acd'
                        if (userParticipantIndex > 0) {
                          for (let i = userParticipantIndex - 1; i >= 0; i--) {
                            if (conversation.details.participants[i].purpose === 'acd') {
                              passedThroughACD = true;
                              break;
                            }
                          }
                        }

                        // Proceder solo si la conversación pasó por un segmento de ACD
                        if (passedThroughACD) {

                          const tAlertMetrics = conversation.metrics?.filter(metric => metric?.metric === "tAlert") || [];
                          // Verificar si existe algún tTalk con correct: true
                          const correctTAlertMetrics = tAlertMetrics.filter(metric => metric.correct === true);

                          let sumAlert =0;
                          if (correctTAlertMetrics.length > 0) {
                            // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                            sumAlert = correctTAlertMetrics.reduce((total, metric) => total + (metric.onQueue || 0), 0);
                          }else{
                            // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                            sumAlert = tAlertMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                          }

                          return total + sumAlert;
                        }
                        return total // Si no pasó por ACD, retornar el total actual sin cambios
                      }, 0) / 1000

                      const c_i_othertime_ringtime_aux = conversationsAllOther?.reduce((total, conversation) => {
                        const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                        let passedThroughACD = false;
                        // Verificar si existe un participant anterior con purpose 'acd'
                        if (userParticipantIndex > 0) {
                          for (let i = userParticipantIndex - 1; i >= 0; i--) {
                            if (conversation.details.participants[i].purpose === 'acd') {
                              passedThroughACD = true;
                              break;
                            }
                          }
                        }

                        // Proceder solo si la conversación pasó por un segmento de ACD
                        if (passedThroughACD) {

                          const tAlertMetrics = conversation.metrics?.filter(metric => metric?.metric === "tAlert") || [];
                          // Verificar si existe algún tTalk con correct: true
                          const correctTAlertMetrics = tAlertMetrics.filter(metric => metric.correct === true);

                          let sumAlert =0;
                          if (correctTAlertMetrics.length > 0) {
                            // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                            sumAlert = correctTAlertMetrics.reduce((total, metric) => total + (metric.auxO > 0? metric.auxO:metric.aux || 0), 0);
                          }else{
                            // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                            sumAlert = tAlertMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                          }

                          return total + sumAlert;
                        }
                        return total // Si no pasó por ACD, retornar el total actual sin cambios
                      }, 0) / 1000

                      const c_i_othertime_auxouttime = conversationsAllOtherManualOut?.reduce((total, conversation) => {
                        const tDialingMetric = conversation.metrics?.find(metric => metric?.metric === "tDialing");
                        const tContactingMetric = conversation.metrics?.find(metric => metric?.metric === "tContacting");
                        const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "tTalk") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTTalkMetrics = tTalkMetrics.filter(metric => metric.correct === true);

                        if(tDialingMetric===undefined && tContactingMetric===undefined){
                          return total
                        }

                        let sumACD;
                        if (correctTTalkMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumACD = correctTTalkMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }
                        //const count = tDialingMetric?.stats.sum || 0;
                        //return total + sumACD + count;
                        return total + sumACD;
                      }, 0) / 1000

                      const c_i_othertime_auxintime = conversationsAllOtherManualIN?.reduce((total, conversation) => {
                        const tDialingMetric = conversation.metrics?.find(metric => metric?.metric === "tDialing");
                        const tContactingMetric = conversation.metrics?.find(metric => metric?.metric === "tContacting");
                        const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "tTalk") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTTalkMetrics = tTalkMetrics.filter(metric => metric.correct === true);

                        if(tDialingMetric===undefined && tContactingMetric===undefined){
                          return total
                        }

                        let sumACD;
                        if (correctTTalkMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumACD = correctTTalkMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }
                        //const count = tDialingMetric?.stats.sum || 0;
                        //return total + sumACD + count;
                        return total + sumACD;
                      }, 0) / 1000

                      const logid = userInfo?.email?.split('@')[0]

                      
                      

                      const startTimeP = this.formatTime(data.interval.split('/')[0])
                      const intervalUTC = this.formatDateToUTC(data.interval.split('/')[0])

                      /*const c_i_availtime = 
                        (c_countAllAuxtime/1000)>=c_countAllStafftime?
                        0.000:

                        (c_countAllAvailableTime/1000)-
                        (c_i_acdtime + (c_i_acwtime_onQueue) + c_i_ringtime + (c_i_holdtime_onQueue))-
                        (c_i_othertime_acdtime+c_i_othertime_acwtime+c_i_othertime_ringtime+c_i_othertime_holdtime) > 0 ?
                  
                        parseFloat((c_countAllAvailableTime/1000)-
                        (c_i_acdtime + (c_i_acwtime_onQueue) + c_i_ringtime + (c_i_holdtime_onQueue))-
                        (c_i_othertime_acdtime+c_i_othertime_acwtime+c_i_othertime_ringtime+c_i_othertime_holdtime)).toFixed(3) : 0.000

                      
                      const c_i_availtime_log = 
                        (c_countAllAuxtime/1000)>=c_countAllStafftime?
                        0.000+'R0':

                        (c_countAllAvailableTime/1000)-
                        (c_i_acdtime + (c_i_acwtime_onQueue) + c_i_ringtime + (c_i_holdtime_onQueue))-
                        (c_i_othertime_acdtime+c_i_othertime_acwtime+c_i_othertime_ringtime+c_i_othertime_holdtime) > 0 ?
                  
                        parseFloat((c_countAllAvailableTime/1000)-
                        (c_i_acdtime + (c_i_acwtime_onQueue+c_i_acwtime_aux) + c_i_ringtime + (c_i_holdtime_onQueue+c_i_holdtime_aux))-
                        (c_i_othertime_acdtime+c_i_othertime_acwtime+c_i_othertime_ringtime+c_i_othertime_holdtime)).toFixed(3)+'R1' : 0.000+'R2'*/

                        /*const c_i_availtime = 
                        (c_countAllAvailableTime/1000)<=0?
                        0.000:

                        (c_countAllAvailableTime/1000)-
                        ((c_i_acdtime + (c_i_acwtime_onQueue) + c_i_ringtime + (c_i_holdtime_onQueue))+
                        (c_i_othertime_acdtime+c_i_othertime_acwtime+c_i_othertime_ringtime+c_i_othertime_holdtime)) >= 1 ?
                  
                        parseFloat((c_countAllAvailableTime/1000)-
                        ((c_i_acdtime + (c_i_acwtime_onQueue) + c_i_ringtime + (c_i_holdtime_onQueue))+
                        (c_i_othertime_acdtime+c_i_othertime_acwtime+c_i_othertime_ringtime+c_i_othertime_holdtime))).toFixed(3) : 0.000

                      
                        const c_i_availtime_log = 
                        (c_countAllAvailableTime/1000)<=0?
                        0.000+'R0':

                        (c_countAllAvailableTime/1000)-
                        ((c_i_acdtime + (c_i_acwtime_onQueue) + c_i_ringtime + (c_i_holdtime_onQueue))-
                        (c_i_othertime_acdtime+c_i_othertime_acwtime+c_i_othertime_ringtime+c_i_othertime_holdtime)) >= 1 ?
                  
                        parseFloat((c_countAllAvailableTime/1000)-
                        ((c_i_acdtime + (c_i_acwtime_onQueue) + c_i_ringtime + (c_i_holdtime_onQueue))-
                        (c_i_othertime_acdtime+c_i_othertime_acwtime+c_i_othertime_ringtime+c_i_othertime_holdtime))).toFixed(3)+'R1' :
                        0.000+'R2'*/

                        const c_i_availtime = 
                        (c_countAllAvailableTime/1000)-
                        ((c_i_acdtime_onQueue + (c_i_acwtime_onQueue)+ (c_i_ringtime) + (c_i_holdtime_onQueue))+
                        (c_i_othertime_acdtime+c_i_othertime_acwtime+c_i_othertime_ringtime+c_i_othertime_holdtime))>0?
                        parseFloat((c_countAllAvailableTime/1000)-
                        ((c_i_acdtime_onQueue + (c_i_acwtime_onQueue)+ (c_i_ringtime) +(c_i_holdtime_onQueue))+
                        (c_i_othertime_acdtime+c_i_othertime_acwtime+c_i_othertime_ringtime+c_i_othertime_holdtime))).toFixed(3):0.000

                      
                        const c_i_availtime_log = (c_countAllAvailableTime/1000)-((c_i_acdtime_onQueue + (c_i_acwtime_onQueue)+ (c_i_ringtime) + (c_i_holdtime_onQueue))-(c_i_othertime_acdtime+c_i_othertime_acwtime+c_i_othertime_ringtime+c_i_othertime_holdtime))
                      
                      ///+(c_i_auxouttime)+(c_i_auxintime)
                      //+c_i_othertime_NONacd_aux+c_i_othertime_NONacw_aux+
                      //+(c_i_auxouttime)+(c_i_auxintime)+(c_i_acwauxtime)
                      const c_i_auxtime = 
                      parseFloat(((c_countAllAuxtime/1000))-((c_i_acdtime_aux)+(c_i_acwtime_aux)+(c_i_holdtime_aux)+(c_i_ringtime_aux)+(c_i_othertime_acdtime_aux+c_i_othertime_acwtime_aux+c_i_othertime_holdtime_auxtime+c_i_othertime_ringtime_aux))).toFixed(3)

                      //+(c_i_auxouttime)+(c_i_auxintime)
                      //c_i_othertime_NONacd_aux+c_i_othertime_NONacw_aux+
                      //+(c_i_auxouttime)+(c_i_auxintime)+(c_i_acwauxtime)
                      const c_i_auxtime_log = ((c_countAllAuxtime/1000))-((c_i_acdtime_aux)+(c_i_acwtime_aux)+(c_i_holdtime_aux)+(c_i_ringtime_aux)+(c_i_othertime_acdtime_aux+c_i_othertime_acwtime_aux+c_i_othertime_holdtime_auxtime+c_i_othertime_ringtime_aux))
                      //-(c_i_auxouttime+c_i_auxintime+c_i_acwouttime)

                      const log_availrest = ((c_countAllAvailableTime/1000)-(c_i_acdtime+c_i_acwtime+c_i_ringtime+c_i_auxouttime+c_i_acwouttime)-(c_i_othertime_acdtime+c_i_othertime_acwtime+c_i_othertime_ringtime+c_i_holdtime))

                      //+c_i_othertime_NONacd_aux+c_i_othertime_NONacw_aux
                      //+(c_i_auxouttime)+(c_i_auxintime)+(c_i_acwauxtime)
                      const c_i_othertime_c = ((c_i_othertime_acdtime+(c_i_othertime_acdtime_onQueue_extra_aux/1000))+(c_i_othertime_acwtime_aux)+(c_i_othertime_acwtime)+(c_i_othertime_ringtime)+(c_i_othertime_ringtime_aux)+c_i_othertime_holdtime+c_i_othertime_holdtime_auxtime)+(c_i_holdtime_onQueue+c_i_holdtime_aux)
                      
                      const log_allTime = (Number(c_i_availtime))+(Number(c_i_acdtime_onQueue+c_i_acdtime_aux))+(Number(c_i_acwtime_onQueue+c_i_acwtime_aux))+(Number(c_i_ringtime+c_i_ringtime_aux))+(Number(c_i_auxtime))+(Number(c_i_othertime_c))

                      const c_countAllStafftime_log = c_countAllStafftime/1000

                      const c_i_availtime_corrector = ((c_i_availtime_log<0) && (log_allTime>c_countAllStafftime_log))? c_i_availtime_log: ((log_allTime>c_countAllStafftime_log))? log_allTime-c_countAllStafftime_log :0.000
                      const c_i_availtime_corrector_v = ((c_i_availtime_log<0) && (log_allTime>c_countAllStafftime_log))? 'Verdadero': ((log_allTime>c_countAllStafftime_log))? 'Verdadero':'Falso'

                      //c_i_othertime_NONacd_aux+c_i_othertime_NONacw_aux+
                      //+(c_i_auxouttime)+(c_i_auxintime)+(c_i_acwauxtime)+
                      const log_allTimeLog = ((c_countAllAuxtime/1000))-((c_i_acdtime_aux)+(c_i_acwtime_aux)+(c_i_ringtime_aux)+(c_i_holdtime_aux)+(c_i_othertime_acdtime_aux+c_i_othertime_acwtime_aux+c_i_othertime_holdtime_auxtime+c_i_othertime_ringtime_aux))

                      const c_i_auxtime_corrector = (c_i_auxtime_log<0)? log_allTimeLog : 0.000
                      const c_i_auxtime_corrector_v = (c_i_auxtime_log<0)? 'Verdadero':'Falso'

                      /*const c_i_auxtime_corrector2 = ((c_countAllAvailableTime/1000)-(c_i_othertime_acdtime+c_i_othertime_acwtime+c_i_othertime_ringtime+c_i_othertime_holdtime)<0)? (Math.abs((c_countAllAvailableTime/1000)-(c_i_othertime_acdtime+c_i_othertime_acwtime+c_i_othertime_ringtime+c_i_othertime_holdtime))) : 0.000
                      const c_i_auxtime_corrector2_v = ((c_countAllAvailableTime/1000)-(c_i_othertime_acdtime+c_i_othertime_acwtime+c_i_othertime_ringtime+c_i_othertime_holdtime)<0)? 'Verdadero':'Falso'*/
                      //const c_i_availtime_corrector = (log_allTime>c_countAllStafftime_log)? c_i_availtime_log :0.000
                      //const c_i_availtime_corrector_v = (log_allTime>c_countAllStafftime_log)? 'Verdadero':'Falso'

                      const c_i_othertime_original = parseFloat(c_i_othertime_c).toFixed(3)

                      const c_othertime_rest_avail = c_i_availtime_corrector<0 ? Math.abs(c_i_availtime_corrector):c_i_availtime_corrector
                      const c_othertime_rest_aux = c_i_auxtime_corrector<0 ? Math.abs(c_i_auxtime_corrector):c_i_auxtime_corrector

                      const c_i_othertime_rest_final = c_i_othertime_c-(c_othertime_rest_avail+c_othertime_rest_aux)>=0? c_i_othertime_c-( c_othertime_rest_avail+c_othertime_rest_aux ):0.000
                      
                      //const c_i_othertime = c_i_othertime_rest_final>=0?c_i_othertime_rest_final:0.000

                      const c_i_othertime = c_i_othertime_rest_final

                      const log_conversationIds = conversationsAll.map(conversation => conversation.conversationId).join('|');
                      
                      
                    let presencias = {}

                    for(let au=0; au<this.allPresences.length; au++){
                      presencias['ti_auxtime_'+this.allPresences[au].languageLabels.en_US] = 0
                    }

                    this.flattenedArrayExport.push({
                      id:0,
                      cms: userInfo?.division?.name,
                      row_date: intervalUTC,
                      starttime:startTimeP,
                      starttime_utc:0,
                      intrvl:0,
                      acd:0,
                      split_queue_skill:queue.queueName,
                      extension:0,
                      logid:logid,
                      loc_id:0,
                      ti_stafftime: 0,
                      i_stafftime: Math.max(0, (c_countAllAvailableTime/1000)+(c_countAllAuxtime/1000)),
                      i_availtime: Math.max(0, Number(c_i_availtime>0 ? c_i_availtime:0)),
                      ti_availtime:0,
                      i_acdtime: c_i_acdtime_onQueue+c_i_acdtime_aux,
                      i_acwtime: c_i_acwtime_onQueue+c_i_acwtime_aux,
                      //i_acdtime: c_i_acdtime_onQueue+c_i_acdtime_aux,
                      //i_acwtime: c_i_acwtime_onQueue+c_i_acwtime_aux,
                      i_acwouttime: c_i_acwouttime,
                      i_acwintime: c_i_acwintime,
                      ti_auxtime: 0,
                      i_auxouttime: c_i_auxouttime,
                      //i_auxtime: Number(c_i_auxtime>0 ? c_i_auxtime:0),
                      i_auxtime: Math.max(0, Number(c_i_auxtime>0 ? c_i_auxtime:0)),
             
                      i_auxintime: c_i_auxintime,
                      i_othertime: c_i_othertime,
                      ti_othertime: 0,
                      i_acdaux_outtime: 0,
                      i_acdauxintime: 0,
                      i_ringtime: c_i_ringtime+c_i_ringtime_aux,
                      i_ringcalls: c_i_ringcalls,
                      ringtime: c_t_ringtime,
                      ringcalls: c_t_ringcalls,


                      acwoutcalls: c_t_acwoutcalls,
                      acwouttime: c_t_acwouttime,
                      auxoutcalls: c_t_auxoutcalls,
                      auxouttime: c_t_auxouttime,

                      acdcalls: c_acdcalls,
                      acdtime: c_t_acdtime,
                      acwtime: c_t_acwtime,
                      o_acdcalls: conversationsOut?.reduce((total, conversation) => {
                          const sumACWOut = conversation.metrics?.find(metric => metric?.metric === "nOutboundConnected")?.stats.count || 0;
                          return total + sumACWOut;
                      }, 0),
                      o_acdtime: conversationsOut?.reduce((total, conversation) => {
                          const hasOutboundAttempted = conversation.metrics?.some(metric => metric?.metric === "nOutboundConnected");
                          if (hasOutboundAttempted) {
                            const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "tTalk") || [];
                            const sumACDOut = tTalkMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                            return total + sumACDOut;
                          }
                          return total;
                      }, 0)/1000,
                      o_acwtime: conversationsOut?.reduce((total, conversation) => {
                          const hasOutboundAttempted = conversation.metrics?.some(metric => metric?.metric === "nOutboundConnected");
                          if (hasOutboundAttempted) {
                            const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "tAcw") || [];
                            const sumACWOut = tTalkMetrics.reduce((total, metric) => total + (metric.stats.max || 0), 0);
                            return total + sumACWOut;
                          }
                          return total;
                      }, 0)/1000,
                      holdcalls: c_t_holdcalls,
                      holdtime: c_t_holdtime,
                      holdabncalls: c_t_holdabncalls,
                      transferred: c_t_transferred,
                      transferredtime: c_t_transferredtime,
                      conference: c_t_conference,
                      abncalls: c_t_abncalls,
                      abntime: c_t_abntime,
                      ansringtime:0,
                      acdauxoutcalls:0,
                      phantomabns:0,
                      holdacdtime:0,
                      acd_release: c_t_acd_release,
                      auxoutoffcalls:0,
                      auxoutofftime:0,
                      ...presencias,
                      totalInterceptedTimeSum:0,
                      totalInterceptedTimeSumSeg: 0,
                      totalAdjustedTimeSum:0,
                      comparisonResult:0,
                      c_i_acdtime_onQueue: c_i_acdtime_onQueue,
                      c_i_acdtime_aux: c_i_acdtime_aux,
                      c_i_acwtime_onQueue: c_i_acwtime_onQueue,
                      c_i_acwtime_aux: c_i_acwtime_aux,
                      c_i_holdtime_onQueue: c_i_holdtime_onQueue,
                      c_i_holdtime_aux: c_i_holdtime_aux,
                      c_i_ringtime: c_i_ringtime,
                      c_i_ringtime_aux: c_i_ringtime_aux,
                      c_i_othertime_acdtime: c_i_othertime_acdtime,
                      c_i_othertime_acdtime_aux: c_i_othertime_acdtime_aux,
                      c_i_othertime_acwtime: c_i_othertime_acwtime,
                      c_i_othertime_holdtime: c_i_othertime_holdtime,
                      c_i_othertime_holdtime_auxtime: c_i_othertime_holdtime_auxtime,
                      c_i_othertime_ringtime: c_i_othertime_ringtime,
                      c_i_othertime_ringtime_aux: c_i_othertime_ringtime_aux,
                      c_i_othertime_auxouttime: c_i_othertime_auxouttime,
                      c_i_othertime_auxintime: c_i_othertime_auxintime,
                      c_i_acwauxtime: c_i_acwauxtime,
                      c_i_othertime_NONacd_aux: c_i_othertime_NONacd_aux,
                      c_i_othertime_NONacw_aux:c_i_othertime_NONacw_aux,
                      c_i_othertime_acwtime_aux:c_i_othertime_acwtime_aux,
                      c_i_othertime_c: c_i_othertime_c,
                      c_i_othertime_original: c_i_othertime_original,
                      c_othertime_rest_avail: c_othertime_rest_avail,
                      c_othertime_rest_aux: c_othertime_rest_aux,
                      log_allTime: log_allTime,
                      c_i_availtime_corrector: c_i_availtime_corrector,
                      c_i_availtime_corrector_v: c_i_availtime_corrector_v,
                      c_countAllStafftime_log: c_countAllStafftime_log,
                      c_i_auxouttime_onQueue:c_i_auxouttime_onQueue,
                      i_auxtime_log: c_i_auxtime_log,
                      log_allTimeLog:log_allTimeLog,
                      c_i_auxtime_corrector:c_i_auxtime_corrector,
                      c_i_auxtime_corrector_v:c_i_auxtime_corrector_v,
                      v_c_t_i_acwauxouttime: 0,
                      v_c_t_i_auxouttimecontactingtime: 0,
                      v_c_t_total_auxtime: 0,
                      v_c_t_total_availtime: 0,
                      v_c_t_othertime_c: 0,
                      v_c_t_othertime: 0,

                      log_audit_exist_memberAdd: '',
                      log_audit_exist_memberRemove: '',

                      contadorAvailtimeUnicos:0,
                      contadorAuxtimeUnicos:0,
                      sumadorAuxtimeUnicos:0,
                      sumadorAvailtimeUnicos:0,

                      log_v_c_t_acdtime:0,
                      log_v_c_t_acdtime_Aux:0,
                      log_v_c_t_acwtime_onQueue:0,
                      log_v_c_t_acwtime_Aux:0,
                      log_v_c_t_ringtime_onQueue:0,
                      log_v_c_t_holdtime_onQueue:0,
                      log_v_c_t_holdtime_aux:0,
                      log_v_c_t_i_auxouttime:0,
                      log_v_c_t_i_auxintime:0,
                      v_c_i_availtime_corrector:0,
                      v_c_i_availtime_corrector_v:0,
                      log_conversationIds: log_conversationIds,
                      ACDCALLSIDS:  ACDCALLSIDS.join('|'),
                      RINGCALLSIDS:  RINGCALLSIDS.join('|'),
                      ABNCALLSIDS: ABNCALLSIDS.join('|'),
                      SALIENTES: SALIENTES.join('|'),
                      ONQUEUEACDTIME: ONQUEUEACDTIME,
                      AUXACDTIME: AUXACDTIME,
                      ONQUEUEACWTIME: ONQUEUEACWTIME,
                      AUXACWTIME: AUXACWTIME,
                      ONQUEUEHOLDTIME: ONQUEUEHOLDTIME,
                      AUXHOLDTIME: AUXHOLDTIME,
                      ONQUEUEALERTTIME:ONQUEUEALERTTIME,
                      AUXALERTTIME:AUXALERTTIME,
                      OFFLINEACD:OFFLINEACD,
                      OFFLINEACW:OFFLINEACW,
                      OFFLINEALERT:OFFLINEALERT,
                      OFFLINEHOLD:OFFLINEHOLD,
                      //log_audit_exist_memberAdd: log_audit_exist_memberAdd,
                      //log_audit_exist_memberRemove: log_audit_exist_memberRemove,
                      
                      /**/
                    })

                    return {
                      ...item.group,
                      ...data,
                      log_conversationIds: log_conversationIds,
                      metrics: data.metrics,
                      queue: queue.queueName,
                      name: userInfo?.name,
                      logid: logid,
                      division: userInfo?.division?.name,
                      row_date: intervalUTC,

                      auditEvents: auditEvents,
                      auditEventsDep: auditEventsDep,

                      auditEventsDepFinderMemberAdd: auditEventsDepFinderMemberAdd,
                      auditEventsDepFinderMemberRemove: auditEventsDepFinderMemberRemove,
                      auditEventsDepFinderMemberUpdate: auditEventsDepFinderMemberUpdate,
                      auditEventsDepFinderAll:  auditEventsDepFinderAll,
                      auditEventsDepFinderMemberRemoveSum: auditEventsDepFinderMemberRemoveSum,
                      auditEventsDepFinderMemberAddSum: auditEventsDepFinderMemberAddSum,
                      auditEventsDepFinderMemberAddPrevious: auditEventsDepFinderMemberAddPrevious,

                      interceptedTimes: interceptedTimes,

                      c_i_acdtime_onQueue: c_i_acdtime_onQueue,
                      c_i_acdtime_aux: c_i_acdtime_aux,
                      c_i_acwtime_onQueue: c_i_acwtime_onQueue,
                      c_i_acwtime_aux: c_i_acwtime_aux,
                      c_i_holdtime_onQueue: c_i_holdtime_onQueue,
                      c_i_holdtime_aux: c_i_holdtime_aux,
                      c_i_ringtime: c_i_ringtime,
                      c_i_ringtime_aux: c_i_ringtime_aux,
                      c_i_acwauxtime: c_i_acwauxtime,
                      log_allTime: log_allTime,
                      c_i_availtime_corrector: c_i_availtime_corrector,
                      c_i_availtime_corrector_v: c_i_availtime_corrector_v,

                      c_i_othertime_acdtime: c_i_othertime_acdtime,
                      c_i_othertime_acdtime_aux: c_i_othertime_acdtime_aux,
                      c_i_othertime_acwtime: c_i_othertime_acwtime,
                      c_i_othertime_holdtime: c_i_othertime_holdtime,
                      c_i_othertime_holdtime_auxtime: c_i_othertime_holdtime_auxtime,
                      c_i_othertime_ringtime: c_i_othertime_ringtime,
                      c_i_othertime_ringtime_aux: c_i_othertime_ringtime_aux,
                      c_i_othertime_auxouttime: c_i_othertime_auxouttime,
                      c_i_othertime_NONacd_aux: c_i_othertime_NONacd_aux,
                      c_i_othertime_NONacw_aux:c_i_othertime_NONacw_aux,
                      c_i_othertime_acwtime_aux:c_i_othertime_acwtime_aux,
                      c_i_othertime_auxintime: c_i_othertime_auxintime,
                      c_i_othertime_c: c_i_othertime_c,
                      c_i_othertime_original: c_i_othertime_original,
                      c_countAllStafftime_log: c_countAllStafftime_log,
                      c_i_auxouttime_onQueue: c_i_auxouttime_onQueue,
                      c_i_auxtime_corrector: c_i_auxtime_corrector,
                      c_i_auxtime_corrector_v: c_i_auxtime_corrector_v,

                      c_i_othertime: c_i_othertime,

                      log_availrest: log_availrest,

                      i_availtime: Math.max(0, Number(c_i_availtime>0 ? c_i_availtime:0)),
                      i_auxtime: Number(c_i_auxtime>0 ? c_i_auxtime:0),
                      i_availtime_log: c_i_availtime_log,
                      i_auxtime_log: c_i_auxtime_log,
                      //i_auxtime: c_i_auxtime,

                      i_acwouttime: c_i_acwouttime,
                      i_acwintime: c_i_acwintime,

                      countAllStafftime: c_countAllStafftime/1000,
                      countAllAvailableTime: c_countAllAvailableTime/1000,
                      countAllAuxtime: c_countAllAuxtime/1000,

                      extension: userInfo?.addresses[0]?.display ?? '',
                      //row_date: data.interval.split('T')[0],
                      startTimeP: startTimeP,
                      conversations: conversationsAll,
                      conversationsIn: conversationsIn,
                      conversationsOut: conversationsOut,
                      conversationsOutNONACD: conversationsOutNONACD,
                      conversationsOther: conversationsAllOther,

                      acdcalls: c_acdcalls,
                      acdtime: c_i_acdtime,
                      acdtimetotal: c_t_acdtime,
                      acwtime: c_t_acwtime,

                      o_acdcalls: conversationsOut?.reduce((total, conversation) => {
                          const sumACWOut = conversation.metrics?.find(metric => metric?.metric === "nOutboundConnected")?.stats.count || 0;
                          return total + sumACWOut;
                      }, 0),
                      o_acdtime: conversationsOut?.reduce((total, conversation) => {
                          const hasOutboundAttempted = conversation.metrics?.some(metric => metric?.metric === "nOutboundConnected");
                          if (hasOutboundAttempted) {
                            const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "tTalk") || [];
                            const sumACDOut = tTalkMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                            return total + sumACDOut;
                          }
                          return total;
                      }, 0)/1000,
                      o_acwtime: conversationsOut?.reduce((total, conversation) => {
                          const hasOutboundAttempted = conversation.metrics?.some(metric => metric?.metric === "nOutboundConnected");
                          if (hasOutboundAttempted) {
                            const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "tAcw") || [];
                            const sumACWOut = tTalkMetrics.reduce((total, metric) => total + (metric.stats.max || 0), 0);
                            return total + sumACWOut;
                          }
                          return total;
                      }, 0)/1000,

                      acd_release: c_t_acd_release,
                      transferred: c_t_transferred,
                      transferredtime: c_t_transferredtime,

                      conference: c_t_conference,
                      holdcalls: c_t_holdcalls,
                      holdtime: c_t_holdtime,
                      holdabncalls: c_t_holdabncalls,

                      abncalls:c_t_abncalls,
                      abntime: c_t_abntime,

                      ti_availtime:0,

                      ringcalls: c_t_ringcalls,
                      i_ringcalls: c_i_ringcalls,
                      ringtime: c_t_ringtime,
                      i_ringtime: c_i_ringtime+c_i_ringtime_aux,

                      i_holdtime:c_i_holdtime,
                      //i_othertime:c_i_othertime_acdtime+c_i_othertime_acwtime+c_i_othertime_ringtime+c_i_holdtime,
                      i_othertime: c_i_othertime,

                      auxoutcalls: c_t_auxoutcalls,
                      auxouttime: c_t_auxouttime,

                      acwoutcalls: conversationsOut?.reduce((total, conversation) => {
                          const sumACWOut = conversation.metrics?.find(metric => metric?.metric === "tAcw")?.stats.count || 0;
                          return total + sumACWOut;
                      }, 0),

                      acwouttime: c_t_acwouttime,

                      ansringtime: ansringtime,

                      i_acdtime: c_i_acdtime,
                      i_acwtime: c_i_acwtime,

                      ti_othertime:0,

                      i_othertime_acdtime: c_i_othertime_acdtime,
                      i_othertime_acwtime: c_i_othertime_acwtime,
                      i_othertime_holdtime: c_i_othertime_holdtime,
                      i_othertime_ringtime: c_i_othertime_ringtime,
                      i_auxouttime: c_i_auxouttime,

                      i_stafftime: Math.max(0, (c_countAllAvailableTime/1000)+(c_countAllAuxtime/1000)),

                      z_available_negative_time: (c_countAllAvailableTime/1000)-(c_i_acdtime+c_i_acwtime+c_i_ringtime+c_i_holdtime),
                      z_other_negative_time: (c_countAllAvailableTime/1000)-(c_i_othertime_acdtime+c_i_othertime_acwtime+c_i_othertime_ringtime+c_i_othertime_holdtime),
                    };
                  });

                  //const v_c_countAllAvailableTime = data.metrics.filter(metric => 
                    //metric.qualifier === 'ON_QUEUE').reduce((total, metric) => (total + metric.stats.sum), 0)

                  const v_c_countAllAvailableTime_IDLE = data.metrics.filter(metric => 
                    metric.qualifier === 'IDLE').reduce((total, metric) => (total + metric.stats.sum), 0)

                  const v_c_countAllAvailableTime_INTERACTING = data.metrics.filter(metric => 
                    metric.qualifier === 'INTERACTING').reduce((total, metric) => (total + metric.stats.sum), 0)

                  /*const v_c_countAllAuxtime =
                    data?.metrics.filter(metric => metric.qualifier === 'AVAILABLE').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric.qualifier === 'BREAK').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric.qualifier === 'TRAINING').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric.qualifier === 'BUSY').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric.qualifier === 'AWAY').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric.qualifier === 'LUNCH').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric.qualifier === 'MEAL').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric?.qualifier === 'OFFLINE').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric.qualifier === 'MEETING').reduce((total, metric) => (total + metric.stats.sum), 0)

                  const v_c_countAllStafftime = 
                    data?.metrics.filter(metric => metric.qualifier === 'AVAILABLE').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric.qualifier === 'ON_QUEUE').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric.qualifier === 'BREAK').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric.qualifier === 'TRAINING').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric.qualifier === 'BUSY').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric.qualifier === 'AWAY').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric.qualifier === 'LUNCH').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric.qualifier === 'MEAL').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric?.qualifier === 'OFFLINE').reduce((total, metric) => (total + metric.stats.sum), 0) +
                    data?.metrics.filter(metric => metric.qualifier === 'MEETING').reduce((total, metric) => (total + metric.stats.sum), 0) */

                  /*const v_otherStatesTime = 
                      data?.metrics.filter(metric => metric?.qualifier === 'AVAILABLE').reduce((total, metric) => (total + metric.stats.sum), 0) +
                      data?.metrics.filter(metric => metric?.qualifier === 'ON_QUEUE').reduce((total, metric) => (total + metric.stats.sum), 0) +
                      data?.metrics.filter(metric => metric?.qualifier === 'BREAK').reduce((total, metric) => (total + metric.stats.sum), 0) +
                      data?.metrics.filter(metric => metric?.qualifier === 'TRAINING').reduce((total, metric) => (total + metric.stats.sum), 0) +
                      data?.metrics.filter(metric => metric?.qualifier === 'BUSY').reduce((total, metric) => (total + metric.stats.sum), 0) +
                      data?.metrics.filter(metric => metric?.qualifier === 'AWAY').reduce((total, metric) => (total + metric.stats.sum), 0) +
                      data?.metrics.filter(metric => metric?.qualifier === 'LUNCH').reduce((total, metric) => (total + metric.stats.sum), 0) +
                      data?.metrics.filter(metric => metric?.qualifier === 'MEAL').reduce((total, metric) => (total + metric.stats.sum), 0) +
                      data?.metrics.filter(metric => metric?.qualifier === 'MEETING').reduce((total, metric) => (total + metric.stats.sum), 0);

                  const v_offlineTime = data?.metrics.filter(metric => metric?.qualifier === 'OFFLINE').reduce((total, metric) => (total + metric.stats.sum), 0);

                  const v_c_countAllStafftime = v_otherStatesTime + (v_otherStatesTime > 0 ? v_offlineTime : 0);

                  const v_otherAuxStatesTime = 
                      data?.metrics.filter(metric => metric?.qualifier === 'AVAILABLE').reduce((total, metric) => (total + metric.stats.sum), 0) +
                      data?.metrics.filter(metric => metric?.qualifier === 'BREAK').reduce((total, metric) => (total + metric.stats.sum), 0) +
                      data?.metrics.filter(metric => metric?.qualifier === 'TRAINING').reduce((total, metric) => (total + metric.stats.sum), 0) +
                      data?.metrics.filter(metric => metric?.qualifier === 'BUSY').reduce((total, metric) => (total + metric.stats.sum), 0) +
                      data?.metrics.filter(metric => metric?.qualifier === 'AWAY').reduce((total, metric) => (total + metric.stats.sum), 0) +
                      data?.metrics.filter(metric => metric?.qualifier === 'LUNCH').reduce((total, metric) => (total + metric.stats.sum), 0) +
                      data?.metrics.filter(metric => metric?.qualifier === 'MEAL').reduce((total, metric) => (total + metric.stats.sum), 0) +
                      data?.metrics.filter(metric => metric?.qualifier === 'MEETING').reduce((total, metric) => (total + metric.stats.sum), 0);

                  const v_offlineAuxTime = data?.metrics.filter(metric => metric?.qualifier === 'OFFLINE').reduce((total, metric) => (total + metric.stats.sum), 0);

                  const v_c_countAllAuxtime = v_otherAuxStatesTime + (v_otherAuxStatesTime > 0 ? v_offlineAuxTime : 0);*/

                  const v_c_countAllAvailableTime = 
                    ['ON_QUEUE'].reduce((total, qualifier) => {
                      const sum = data?.metrics.filter(metric => metric?.qualifier === qualifier).reduce((total, metric) => {
                      
                        const metricSum = metric?.stats?.sum || 0;
                        return total + metricSum;
                      }, 0);

                      return total + sum;
                    }, 0);

                  /*const v_offlineTime = 
                    ['OFFLINE'].reduce((total, qualifier) => {
                      const sum = data?.metrics.filter(metric => metric?.qualifier === qualifier).reduce((total, metric) => {

                        const metricSum = metric?.stats?.sum || 0;

                        return total + metricSum;
                      }, 0);

                      return total + sum;
                    }, 0);*/

                  const v_otherStatesTime = 
                    ['AVAILABLE', 'ON_QUEUE', 'BREAK', 'TRAINING', 'BUSY', 'AWAY', 'LUNCH', 'MEAL', 'MEETING'].reduce((total, qualifier) => {
                      const sum = data?.metrics.filter(metric => metric?.qualifier === qualifier).reduce((total, metric) => {
                        

                        const metricSum = metric?.stats?.sum || 0;


                        return total + metricSum;
                      }, 0);

                      return total + sum;
                    }, 0);

                  const v_c_countAllStafftime = 
                  Math.max(0, v_otherStatesTime)
                  ;


                  const v_otherAuxStatesTime = 
                  ['AVAILABLE', 'BREAK', 'TRAINING', 'BUSY', 'AWAY', 'LUNCH', 'MEAL', 'MEETING'].reduce((total, qualifier) => {
                      const sum = data?.metrics.filter(metric => metric?.qualifier === qualifier).reduce((total, metric) => {
                        
                        const metricSum = metric?.stats?.sum || 0;

                        return total + metricSum;
                      }, 0);

                      return total + sum;
                    }, 0);

                  const v_c_countAllAuxtime = 
                  Math.max(0, v_otherAuxStatesTime)
                  ;

                  const isOnline = v_otherStatesTime > 0 ? true : false;

                  const conversationsAllV = isOnline? this.getConversationsPerUsersQueueAndIntervalTI(item.group.userId, data.interval):[]
                  const conversationsInV = isOnline? this.getConversationsPerUsersQueueAndIntervalTIVirtual(item.group.userId, null, data.interval, 'inbound'):[]
                  const conversationsOutV = isOnline? this.getConversationsPerUsersQueueAndIntervalTIVirtual(item.group.userId, null, data.interval, 'outbound'):[]
                  //const conversationsOutTotal = this.getConversationsTOTALInboundOutbound(item.group.userId, data.interval, 'outbound'):[]
                  const conversationsInTotal = isOnline? this.getConversationsTOTALInboundOutbound(item.group.userId, data.interval, 'inbound'):[]
                  const conversationsAllTotal = isOnline? this.getConversationsTotalALL(item.group.userId, data.interval):[]
                  const interceptedTimesV = isOnline? this.aggregateIntersectedTimes(conversationsAllV):[]

                  //const v_c_countAllStafftime = v_c_countAllAvailableTime+v_c_countAllAuxtime

                  const v_c_t_acdtime = conversationsAllV.reduce((total, conversation) => {
                    // Filtrar por el participant que tenga en su userId el id del usuario
                    const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                    let passedThroughACD = false;
                    // Verificar si existe un participant anterior con purpose 'acd'
                    if (userParticipantIndex > 0) {
                      for (let i = userParticipantIndex - 1; i >= 0; i--) {
                        if (conversation.details.participants[i].purpose === 'acd') {
                          passedThroughACD = true;
                          break;
                        }
                      }
                    }

                      // Proceder solo si la conversación pasó por un segmento de ACD
                      if (passedThroughACD) {
                        const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "tTalk") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTTalkMetrics = tTalkMetrics.filter(metric => metric.correct === true);

                        let sumACD;
                        if (correctTTalkMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumACD = correctTTalkMetrics.reduce((total, metric) => total + (metric.onQueue || 0), 0);
                        } else {
                          // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumACD = tTalkMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }

                        return total + sumACD;
                      }

                      return total; // Si no pasó por ACD, retornar el total actual sin cambios
                    }, 0) / 1000

                  const v_c_t_acdtime_Aux = conversationsAllV.reduce((total, conversation) => {
                    // Filtrar por el participant que tenga en su userId el id del usuario
                      /*const userParticipantIndex = conversation?.details?.participants?.findIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                      let passedThroughACD = false;
                      // Verificar si existe un participant anterior y si su purpose es 'acd'
                      if (userParticipantIndex > 0 && conversation.details.participants[userParticipantIndex - 1].purpose === 'acd') {
                        passedThroughACD = true;
                      }*/

                      // Proceder solo si la conversación pasó por un segmento de ACD
                      //if (passedThroughACD) {
                        const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "tTalk") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTTalkMetrics = tTalkMetrics.filter(metric => metric.correct === true);

                        let sumACD;
                        if (correctTTalkMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumACD = correctTTalkMetrics.reduce((total, metric) => total + (metric.aux || 0), 0);
                        } else {
                          // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumACD = tTalkMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }

                        return total + sumACD;
                      //}

                      //return total; // Si no pasó por ACD, retornar el total actual sin cambios
                    }, 0) / 1000

                  const v_c_t_acwtime = conversationsAllV.reduce((total, conversation) => {
                    const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                    let passedThroughACD = false;
                    // Verificar si existe un participant anterior con purpose 'acd'
                    if (userParticipantIndex > 0) {
                      for (let i = userParticipantIndex - 1; i >= 0; i--) {
                        if (conversation.details.participants[i].purpose === 'acd') {
                          passedThroughACD = true;
                          break;
                        }
                      }
                    }

                      // Proceder solo si la conversación pasó por un segmento de ACD
                      if (passedThroughACD) {
                        const tAcwMetrics = conversation.metrics?.filter(metric => metric?.metric === "tAcw") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTtAcwMetrics = tAcwMetrics.filter(metric => metric.correct === true);

                        let sumACW;
                        if (correctTtAcwMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumACW = correctTtAcwMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        } else {
                          // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumACW = tAcwMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }

                        return total + sumACW;
                     }

                      return total; // Si no pasó por ACD, retornar el total actual sin cambios*/
                    }, 0) / 1000

                    const v_c_t_acwtime_onQueue = conversationsAllV.reduce((total, conversation) => {
                      const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                      let passedThroughACD = false;
                      // Verificar si existe un participant anterior con purpose 'acd'
                      if (userParticipantIndex > 0) {
                        for (let i = userParticipantIndex - 1; i >= 0; i--) {
                          if (conversation.details.participants[i].purpose === 'acd') {
                            passedThroughACD = true;
                            break;
                          }
                        }
                      }

                      // Proceder solo si la conversación pasó por un segmento de ACD
                      if (passedThroughACD) {
                        const tAcwMetrics = conversation.metrics?.filter(metric => metric?.metric === "tAcw") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTtAcwMetrics = tAcwMetrics.filter(metric => metric.correct === true);

                        let sumACW;
                        if (correctTtAcwMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumACW = correctTtAcwMetrics.reduce((total, metric) => total + (metric.onQueue || 0), 0);
                        } else {
                          // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumACW = tAcwMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }

                        return total + sumACW;
                      }

                      return total; // Si no pasó por ACD, retornar el total actual sin cambios*/
                    }, 0) / 1000

                  
                    const v_c_t_acwtime_Aux = conversationsAllV.reduce((total, conversation) => {
                      /*const userParticipantIndex = conversation?.details?.participants?.findIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                      let passedThroughACD = false;
                      // Verificar si existe un participant anterior y si su purpose es 'acd'
                      if (userParticipantIndex > 0 && conversation.details.participants[userParticipantIndex - 1].purpose === 'acd') {
                        passedThroughACD = true;
                      }

                      // Proceder solo si la conversación pasó por un segmento de ACD
                      if (passedThroughACD) {*/
                        const tAcwMetrics = conversation.metrics?.filter(metric => metric?.metric === "tAcw") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTtAcwMetrics = tAcwMetrics.filter(metric => metric.correct === true);

                        let sumACW;
                        if (correctTtAcwMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumACW = correctTtAcwMetrics.reduce((total, metric) => total + (metric.aux || 0), 0);
                        } else {
                          // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumACW = tAcwMetrics.reduce((total, metric) => total + (metric.aux || 0), 0);
                        }

                        return total + sumACW;
                      /*}

                      return total; // Si no pasó por ACD, retornar el total actual sin cambios*/
                    }, 0) / 1000
                  
                  /*const v_c_t_ringtime = conversationsAllV.reduce((total, conversation) => {
                    const userParticipantIndex = conversation?.details?.participants?.findLastIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                      let passedThroughACD = false;
                      // Verificar si existe un participant anterior con purpose 'acd'
                      if (userParticipantIndex > 0) {
                        for (let i = userParticipantIndex - 1; i >= 0; i--) {
                          if (conversation.details.participants[i].purpose === 'acd') {
                            passedThroughACD = true;
                            break;
                          }
                        }
                      }

                      // Proceder solo si la conversación pasó por un segmento de ACD
                      if (passedThroughACD) {

                        const tAlertMetrics = conversation.metrics?.filter(metric => metric?.metric === "tAlert") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTAlertMetrics = tAlertMetrics.filter(metric => metric.correct === true);

                        let sumAlert;
                        if (correctTAlertMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumAlert = correctTAlertMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        } else {
                          // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumAlert = tAlertMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }

                        return total + sumAlert;
                      }
                      return total; // Si no pasó por ACD, retornar el total actual sin cambios
                    }, 0) / 1000*/

                    const v_c_t_ringtime = conversationsAllV.reduce((total, conversation) => {


                        const tAlertMetrics = conversation.metrics?.filter(metric => metric?.metric === "tAlert") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTAlertMetrics = tAlertMetrics.filter(metric => metric.correct === true);

                        let sumAlert;
                        if (correctTAlertMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumAlert = correctTAlertMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        } else {
                          // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumAlert = tAlertMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }

                        return total + sumAlert;
                      
                    }, 0) / 1000

                    const v_c_t_ringtime_onQueue = conversationsAllV.reduce((total, conversation) => {
                      /*const userParticipantIndex = conversation?.details?.participants?.findIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                      let passedThroughACD = false;
                      // Verificar si existe un participant anterior y si su purpose es 'acd'
                      if (userParticipantIndex > 0 && conversation.details.participants[userParticipantIndex - 1].purpose === 'acd') {
                        passedThroughACD = true;
                      }*/

                      // Proceder solo si la conversación pasó por un segmento de ACD
                      //if (passedThroughACD) {

                        const tAlertMetrics = conversation.metrics?.filter(metric => metric?.metric === "tAlert") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTAlertMetrics = tAlertMetrics.filter(metric => metric.correct === true);

                        let sumAlert;
                        if (tAlertMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumAlert = correctTAlertMetrics?.reduce((total, metric) => total + (metric.onQueue || 0), 0);
                        }

                        return total + sumAlert;
                      //}
                      //return total; // Si no pasó por ACD, retornar el total actual sin cambios
                    }, 0) / 1000

                    /*const v_c_t_ringtime_aux = conversationsAllV.reduce((total, conversation) => {
                    const userParticipantIndex = conversation?.details?.participants?.findIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                      let passedThroughACD = false;
                      // Verificar si existe un participant anterior y si su purpose es 'acd'
                      if (userParticipantIndex > 0 && conversation.details.participants[userParticipantIndex - 1].purpose === 'acd') {
                        passedThroughACD = true;
                      }

                      // Proceder solo si la conversación pasó por un segmento de ACD
                      if (passedThroughACD) {

                        const tAlertMetrics = conversation.metrics?.filter(metric => metric?.metric === "tAlert") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTAlertMetrics = tAlertMetrics.filter(metric => metric.correct === true);

                        let sumAlert;
                        if (correctTAlertMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumAlert = correctTAlertMetrics.reduce((total, metric) => total + (metric.aux || 0), 0);
                        } else {
                          // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumAlert = tAlertMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }

                        return total + sumAlert;
                      }
                      return total; // Si no pasó por ACD, retornar el total actual sin cambios
                    }, 0) / 1000*/

                  const v_c_t_holdtime = conversationsAllV?.reduce((total, conversation) => {
                      /*const userParticipantIndex = conversation?.details?.participants?.findIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                      let passedThroughACD = false;
                      // Verificar si existe un participant anterior y si su purpose es 'acd'
                      if (userParticipantIndex > 0 && conversation.details.participants[userParticipantIndex - 1].purpose === 'acd') {
                        passedThroughACD = true;
                      }

                      // Proceder solo si la conversación pasó por un segmento de ACD
                      if (passedThroughACD) {*/
                        const tHoldMetrics = conversation.metrics?.filter(metric => metric?.metric === "tHold") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTHoldMetrics = tHoldMetrics.filter(metric => metric.correct === true);

                        let sumHold;
                        if (correctTHoldMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumHold = correctTHoldMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        } else {
                          // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumHold = tHoldMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }

                        return total + sumHold;
                      //}
                      
                      //return total; // Si no pasó por ACD, retornar el total actual sin cambios
                    }, 0) / 1000
                  
                    const v_c_t_holdtime_onQueue = conversationsAllV?.reduce((total, conversation) => {
                      /*const userParticipantIndex = conversation?.details?.participants?.findIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                      let passedThroughACD = false;
                      // Verificar si existe un participant anterior y si su purpose es 'acd'
                      if (userParticipantIndex > 0 && conversation.details.participants[userParticipantIndex - 1].purpose === 'acd') {
                        passedThroughACD = true;
                      }

                      // Proceder solo si la conversación pasó por un segmento de ACD
                      if (passedThroughACD) {*/
                        const tHoldMetrics = conversation.metrics?.filter(metric => metric?.metric === "tHold") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTHoldMetrics = tHoldMetrics.filter(metric => metric.correct === true);

                        let sumHold;
                        if (correctTHoldMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumHold = correctTHoldMetrics.reduce((total, metric) => total + (metric.onQueue || 0), 0);
                        } else {
                          // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumHold = tHoldMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }

                        return total + sumHold;
                      //}
                      
                      //return total; // Si no pasó por ACD, retornar el total actual sin cambios
                    }, 0) / 1000

                    const v_c_t_holdtime_aux = conversationsAllV?.reduce((total, conversation) => {
                      /*const userParticipantIndex = conversation?.details?.participants?.findIndex(participant => participant.userId === item.group.userId); // Asume que userId está definido en algún lugar

                      let passedThroughACD = false;
                      // Verificar si existe un participant anterior y si su purpose es 'acd'
                      if (userParticipantIndex > 0 && conversation.details.participants[userParticipantIndex - 1].purpose === 'acd') {
                        passedThroughACD = true;
                      }

                      // Proceder solo si la conversación pasó por un segmento de ACD
                      if (passedThroughACD) {*/
                        const tHoldMetrics = conversation.metrics?.filter(metric => metric?.metric === "tHold") || [];
                        // Verificar si existe algún tTalk con correct: true
                        const correctTHoldMetrics = tHoldMetrics.filter(metric => metric.correct === true);

                        let sumHold;
                        if (correctTHoldMetrics.length > 0) {
                          // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                          sumHold = correctTHoldMetrics.reduce((total, metric) => total + (metric.aux || 0), 0);
                        } else {
                          // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                          sumHold = tHoldMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                        }

                        return total + sumHold;
                      //}
                      
                      //return total; // Si no pasó por ACD, retornar el total actual sin cambios
                    }, 0) / 1000

                  ////////////////Sacando IDLE//////////////////////
                  const v_c_t_acdtime_total = conversationsAllTotal.reduce((total, conversation) => {
                      const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "tTalk") || [];
                      // Verificar si existe algún tTalk con correct: true
                      const correctTTalkMetrics = tTalkMetrics.filter(metric => metric.correct === true);

                      let sumACD;
                      if (correctTTalkMetrics.length > 0) {
                        // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                        sumACD = correctTTalkMetrics.reduce((total, metric) => total + (metric.onQueue || 0), 0);
                      } else {
                        // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                        sumACD = tTalkMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                      }

                      return total + sumACD;
                    }, 0) / 1000

                  const v_c_t_acwtime_total = conversationsAllTotal.reduce((total, conversation) => {
                          const sumtAcw = conversation.metrics?.filter(metric => metric?.metric === "tAcw") || [];
                          const sumtAcwF = sumtAcw.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                          return total + sumtAcwF;
                      }, 0)/1000
                  
                  const v_c_t_ringtime_total = conversationsAllTotal.reduce((total, conversation) => {
                          
                          const sumAlert = conversation.metrics?.filter(metric => metric?.metric === "tAlert") || [];
                          const sumAlertF = sumAlert.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                          return total + sumAlertF;
                        }, 0)/1000

                  const v_c_t_holdtime_total = conversationsAllTotal?.reduce((total, conversation) => {
                          const tHeldMetrics = conversation.metrics?.filter(metric => metric?.metric === "tHeld") || [];
                          const sumHeld= tHeldMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                          return total + sumHeld;
                      }, 0)/1000

                  const v_c_t_z_available_negative_time = (v_c_countAllAvailableTime / 1000) - (v_c_t_acdtime + v_c_t_acwtime + v_c_t_ringtime + v_c_t_holdtime)


                  /*const v_c_t_i_auxouttime = conversationsOutTotal.reduce((total, conversation) => {
                      const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "tTalk") || [];
                      const tDialingMetrics = conversation.metrics?.filter(metric => metric?.metric === "tDialing") || [];
                      const tContactingMetrics = conversation.metrics?.filter(metric => metric?.metric === "tContacting") || [];
                      // Verificar si existe algún tTalk con correct: true
                      const correctTTalkMetrics = tTalkMetrics.filter(metric => metric.correct === true);

                      if(tDialingMetrics === undefined && tContactingMetrics === undefined){
                        return total
                      }

                      let sumACD;
                      if (correctTTalkMetrics.length > 0) {
                        // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                        sumACD = correctTTalkMetrics.reduce((total, metric) => total + (metric.aux || 0), 0);
                      } else {
                        // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                        sumACD = tTalkMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                      }

                      return total + sumACD;
                    }, 0) / 1000*/

                  const v_c_t_i_auxoutcalls = conversationsOutV?.reduce((total, conversation) => {
                          //const tAlertMetric = conversation.metrics?.find(metric => metric?.metric === "tContacting");
                          const tAnsweredMetric = conversation.metrics?.find(metric => metric?.metric === "tDialing");
                          const tAcwMetric = conversation.metrics?.find(metric => metric?.metric === "tAcw");
                          //!tAlertMetric && 
                          if (!tAnsweredMetric || !tAcwMetric) {
                              return total;
                          }

                          const count = tAnsweredMetric?.stats.count || 0;
                          return total + (count > 0 ? 1 : 0);
                      }, 0)

                  const v_c_t_i_auxouttime = conversationsOutV?.reduce((total, conversation) => {
                          const tContacting = conversation.metrics?.find(metric => metric?.metric === "tContacting");
                          //const tAlertMetric = conversation.metrics?.find(metric => metric?.metric === "tAlert");
                          const tDialingMetric = conversation.metrics?.find(metric => metric?.metric === "tDialing");
                          const tTalkComplete = conversation.metrics?.find(metric => metric?.metric === "tTalkComplete");

                          if (!tContacting && !tDialingMetric) {
                              return total;
                          }

                          //const count = tDialingMetric?.stats.sum || 0;
                          const count2 = tTalkComplete?.stats.sum || 0;
                          //return total + (count + count2);
                          return total + (count2);

                      }, 0) / 1000

                    const v_c_t_i_acwauxouttime = conversationsOutV?.reduce((total, conversation) => {
                          const tContacting = conversation.metrics?.find(metric => metric?.metric === "tContacting");
                          //const tAlertMetric = conversation.metrics?.find(metric => metric?.metric === "tAlert");
                          const tDialingMetric = conversation.metrics?.find(metric => metric?.metric === "tDialing");
                          const tAcwComplete = conversation.metrics?.find(metric => metric?.metric === "tAcw");

                          if (!tContacting && !tDialingMetric) {
                              return total;
                          }

                          const count = tAcwComplete?.stats.sum || 0;
                          return total + (count);

                      }, 0) / 1000

                      const v_c_t_i_auxouttimecontactingtime = conversationsOutV?.reduce((total, conversation) => {
                          const tContacting = conversation.metrics?.find(metric => metric?.metric === "tContacting");
                          //const tAlertMetric = conversation.metrics?.find(metric => metric?.metric === "tAlert");
                          const tDialingMetric = conversation.metrics?.find(metric => metric?.metric === "tDialing");

                          if (!tContacting && !tDialingMetric) {
                              return total;
                          }

                          const count = tContacting?.stats.sum || 0;
                          return total + (count);

                      }, 0) / 1000

                  const v_c_t_i_auxintime = conversationsInTotal.reduce((total, conversation) => {
                      const tTalkMetrics = conversation.metrics?.filter(metric => metric?.metric === "tTalk") || [];
                      const tDialingMetrics = conversation.metrics?.filter(metric => metric?.metric === "tDialing") || [];
                      const tContactingMetrics = conversation.metrics?.filter(metric => metric?.metric === "tContacting") || [];
                      // Verificar si existe algún tTalk con correct: true
                      const correctTTalkMetrics = tTalkMetrics.filter(metric => metric.correct === true);

                      if(tDialingMetrics === undefined && tContactingMetrics === undefined){
                        return total
                      }

                      let sumACD;
                      if (correctTTalkMetrics.length > 0) {
                        // Si existe al menos un tTalk con correct: true, usar solo esos para la suma
                        sumACD = correctTTalkMetrics.reduce((total, metric) => total + (metric.aux || 0), 0);
                      } else {
                        // Si no existe ninguno con correct: true, proceder con la suma de todos los tTalk
                        sumACD = tTalkMetrics.reduce((total, metric) => total + (metric.stats.sum || 0), 0);
                      }

                      return total + sumACD;
                    }, 0) / 1000

                  /*let v_minAvailableTime = Infinity;

                  itemsForQueues.forEach(activity => {
                      const availableTime = (activity.countAllAvailableTime)-
                      (activity.i_acdtime || 0)-
                      (activity.i_acwtime || 0)-
                      (activity.i_ringtime || 0)-
                      (activity.holdtime || 0)-
                      
                      (activity.i_othertime_acdtime+
                        activity.i_othertime_acwtime+
                        activity.i_othertime_ringtime+
                        activity.i_othertime_holdtime)

                      if (availableTime < v_minAvailableTime) {
                        v_minAvailableTime = availableTime;
                      }
                  });*/

                  const contadorStaffUnicos = this.contarI_StaffUnicos(itemsForQueues)
                  const sumadorStaffUnicos = this.sumarI_StaffUnicos(itemsForQueues)


                  const contadorAvailtimeUnicos = this.contarI_AvailTimeUnicos(itemsForQueues)
                  const contadorAuxtimeUnicos = this.contarIAuxtimeUnicos(itemsForQueues)

                  const sumadorAvailtimeUnicos = this.sumarI_AvailTimeUnicos(itemsForQueues)
                  const sumadorAuxtimeUnicos = this.sumarIAuxtimeunicos(itemsForQueues)
                  
                  const contadorOthertimeUnicos = this.contarI_OtherTimeUnicos(itemsForQueues)
                  const sumadorOthertimeUnicos = this.sumarI_OtherTimeUnicos(itemsForQueues)
   
                  
                  
                  /*const v_c_t_total_availtime = contadorAvailtimeUnicos==1? sumadorAvailtimeUnicos : ((v_c_countAllAvailableTime/1000)-(v_c_t_acdtime+v_c_t_acwtime_onQueue+v_c_t_ringtime_onQueue+v_c_t_holdtime_onQueue))
                  const v_c_t_total_auxtime = contadorAuxtimeUnicos==1? sumadorAuxtimeUnicos: sumadorAuxtimeUnicos>v_c_countAllAuxtime/1000? v_c_countAllAuxtime/1000: (sumadorAuxtimeUnicos<=v_c_countAllAuxtime/1000 && sumadorAuxtimeUnicos>0)? sumadorAuxtimeUnicos:0
                  // || (this.sumarIAuxtimeUnicos(itemsForQueues)>0 && this.sumarI_AvailTimeUnicos(itemsForQueues)>0)
                  const v_c_t_othertime_c = parseFloat(((contadorAvailtimeUnicos==1 && contadorAuxtimeUnicos==1))? ((v_c_countAllStafftime/1000) - (sumadorAvailtimeUnicos+sumadorAuxtimeUnicos)):(v_c_t_acdtime+v_c_t_acdtime_Aux+v_c_t_acwtime_onQueue+v_c_t_acwtime_Aux+v_c_t_ringtime_onQueue+v_c_t_holdtime_onQueue+v_c_t_holdtime_aux)+(v_c_t_i_auxouttime+v_c_t_i_auxintime)).toFixed(3)
                  */

                  const v_c_t_total_availtime = contadorAvailtimeUnicos<=1? sumadorAvailtimeUnicos : sumadorAvailtimeUnicos>(v_c_countAllAvailableTime/1000)?(v_c_countAllAvailableTime/1000): ((v_c_countAllAvailableTime/1000)-(v_c_t_acdtime+v_c_t_acwtime_onQueue+v_c_t_ringtime_onQueue+v_c_t_holdtime_onQueue))?sumadorAvailtimeUnicos:sumadorAvailtimeUnicos
                  const v_c_t_total_auxtime = contadorAuxtimeUnicos<=1? sumadorAuxtimeUnicos: sumadorAuxtimeUnicos>v_c_countAllAuxtime/1000? v_c_countAllAuxtime/1000: (sumadorAuxtimeUnicos<=v_c_countAllAuxtime/1000 && sumadorAuxtimeUnicos>0)? sumadorAuxtimeUnicos:0
                  // || (this.sumarIAuxtimeUnicos(itemsForQueues)>0 && this.sumarI_AvailTimeUnicos(itemsForQueues)>0)
                  const v_c_t_othertime_c = parseFloat(((contadorAvailtimeUnicos<=1 && contadorAuxtimeUnicos<=1))? ((v_c_countAllStafftime/1000) - (sumadorAvailtimeUnicos+sumadorAuxtimeUnicos)):(v_c_t_acdtime+v_c_t_acwtime_onQueue+v_c_t_ringtime_onQueue+v_c_t_holdtime_onQueue)+(v_c_t_i_auxouttime+v_c_t_i_auxintime)).toFixed(3)
                  const v_c_t_othertime_condicion = ((contadorAvailtimeUnicos<=1 && contadorAuxtimeUnicos<=1)) ? 1 : 2


                  const log_v_c_t_acdtime = v_c_t_acdtime
                  const log_v_c_t_acdtime_Aux = v_c_t_acdtime_Aux
                  const log_v_c_t_acwtime_onQueue = v_c_t_acwtime_onQueue
                  const log_v_c_t_acwtime_Aux = v_c_t_acwtime_Aux
                  const log_v_c_t_ringtime_onQueue = v_c_t_ringtime_onQueue
                  const log_v_c_t_holdtime_onQueue = v_c_t_holdtime_onQueue
                  const log_v_c_t_holdtime_aux = v_c_t_holdtime_aux
                  const log_v_c_t_i_auxouttime = v_c_t_i_auxouttime
                  const log_v_c_t_i_auxintime = v_c_t_i_auxintime
                      ////Revisar
                  const v_c_i_availtime_corrector = (v_c_t_total_availtime+v_c_t_total_auxtime+v_c_t_othertime_c)>(v_c_countAllStafftime/1000) ? (v_c_t_total_availtime+v_c_t_total_auxtime+v_c_t_othertime_c)-(v_c_countAllStafftime/1000) :0.000
                  const v_c_i_availtime_corrector_v = (v_c_t_total_availtime+v_c_t_total_auxtime+v_c_t_othertime_c)>(v_c_countAllStafftime/1000)? 'Verdadero': 'Falso'


                  const v_c_t_othertime = (v_c_t_othertime_c - v_c_i_availtime_corrector)>=1? (v_c_t_othertime_c - v_c_i_availtime_corrector):v_c_t_othertime_c

                  
                  const logidA = userInfo?.email?.split('@')[0]
                  const startTimePA = this.formatTime(data.interval.split('/')[0])
                  const intervalUTCA = this.formatDateToUTC(data.interval.split('/')[0])

                  const theStartTimeP = this.formatTime(data.interval.split('/')[0])
                  const theExtension = userInfo?.addresses[0]?.display ?? ''
                  const userInfoName = userInfo?.name
                  const userInfoEmail = userInfo?.email?.split('@')[0]

                  const log_ti_othertime = this.sumarIOthertime(itemsForQueues, (v_c_countAllAvailableTime/1000), (v_c_countAllAuxtime/1000))

                  const additionalItem = {
                      ...item.group,
                      ...data,
                      queue:"",
                      interceptedTimes: interceptedTimesV,
                      z_available_negative_time: v_c_t_z_available_negative_time,
                      ti_column: 'Total',
                      name: userInfoName,
                      logid: userInfoEmail,
                      division: userInfo?.division?.name,
                      row_date: intervalUTCA,

                      countAllStafftime: v_c_countAllStafftime/1000,
                      countAllAvailableTime: v_c_countAllAvailableTime/1000,
                      countAllAuxtime: v_c_countAllAuxtime/1000,
                      //ti_auxtime: this.contarIAuxtimeUnicos(itemsForQueues)<=1? this.sumarIAuxtimeUnicos(itemsForQueues): this.sumarIAuxtimeUnicos(itemsForQueues)>v_c_countAllAuxtime/1000? v_c_countAllAuxtime/1000: (this.sumarIAuxtimeUnicos(itemsForQueues)<=v_c_countAllAuxtime/1000 && this.sumarIAuxtimeUnicos(itemsForQueues)>0)? this.sumarIAuxtimeUnicos(itemsForQueues):0,
                      ti_auxtime: v_c_t_total_auxtime,

                      ti_availtime_IDLE: (v_c_countAllAvailableTime_IDLE/1000) ||0,
                      //ti_availtime: ((v_c_countAllAvailableTime/1000)-(v_c_t_acdtime+v_c_t_acwtime_onQueue+v_c_t_ringtime_onQueue+v_c_t_holdtime_onQueue)),
                      ti_availtime: v_c_t_total_availtime,

                      log_ti_availtime_2: ((v_c_countAllAvailableTime/1000)-(v_c_t_acdtime+v_c_t_acwtime+v_c_t_ringtime+v_c_t_holdtime)) >0? (v_c_countAllAvailableTime/1000)-(v_c_t_acdtime+v_c_t_acwtime+v_c_t_ringtime+v_c_t_holdtime):0.000,
                      log_ti_availtime: (v_c_countAllAvailableTime/1000)-(v_c_t_acdtime_total+v_c_t_acwtime_total+v_c_t_ringtime_total+v_c_t_holdtime_total)||0,
                      
                      ti_othertime:v_c_t_othertime,

                      ti_othertime2: sumadorOthertimeUnicos,
                      
                        
                      log_ti_othertime: log_ti_othertime,
                      log_ti_interacting: v_c_countAllAvailableTime_INTERACTING,
                      
                      v_c_countAllStafftime: v_c_countAllStafftime,
                      v_c_t_acdtime: v_c_t_acdtime,
                      v_c_t_acwtime_onQueue: v_c_t_acwtime_onQueue,
                      v_c_t_ringtime_onQueue: v_c_t_ringtime_onQueue,
                      v_c_t_holdtime_onQueue: v_c_t_holdtime_onQueue,
                      v_c_t_acdtime_Aux: v_c_t_acdtime_Aux,
                      v_c_t_acwtime_Aux: v_c_t_acwtime_Aux,
                      v_c_t_holdtime_aux: v_c_t_holdtime_aux,
                      v_c_i_availtime_corrector: v_c_i_availtime_corrector,
                      v_c_i_availtime_corrector_v : v_c_i_availtime_corrector_v,
                      contadorAvailtimeUnicos:contadorAvailtimeUnicos,
                      contadorAuxtimeUnicos:contadorAuxtimeUnicos,
                      contadorOthertimeUnicos: contadorOthertimeUnicos,
                      contadorStaffUnicos: contadorStaffUnicos,
                      contador_othertime_condicion:v_c_t_othertime_condicion,
                      sumadorAvailtimeUnicos:sumadorAvailtimeUnicos,
                      sumadorAuxtimeUnicos:sumadorAuxtimeUnicos,
                      sumadorOthertimeUnicos:sumadorOthertimeUnicos,
                      sumadorStaffUnicos: sumadorStaffUnicos,
                      
                      //log_ti_availtime: this.sumarI_AvailTimeUnicos(itemsForQueues) || 0,
                      extension: theExtension,
                      //row_date: data.interval.split('T')[0],
                      startTimeP: theStartTimeP,
                      conversations: conversationsAllV,
                      conversationsIn: conversationsInV,
                      conversationsOut: conversationsOutV,
                      //debug: conversationsAllDebug,
                      i_auxouttime: v_c_t_i_auxouttime,
                      i_auxintime:v_c_t_i_auxintime,
                      acdcalls: 0,
                      acdtime: 0,
                      acwtime: 0,
                      o_acdcalls: 0,
                      o_acdtime: 0,
                      o_acwtime: 0,
                      acd_release: 0,
                      transferred: 0,
                      conference: 0,
                      holdcalls: 0,
                      holdtime: 0,
                      i_holdcalls: 0,
                      i_holdtime: 0,
                      holdabncalls: 0,
                      abncalls:0,
                      
                      abntime: 0,
                      auxoutcalls: 0,

                      i_acwouttime:0,
                      i_acwintime:0,

                      auxouttime: 0,

                      ringcalls: 0,

                      i_ringcalls: 0,

                      ringtime: 0,

                      i_ringtime: 0,

                      acwoutcalls: 0,

                      acwouttime: 0,

                      i_acdtime: 0,

                      i_acwtime: 0,

                      c_i_acwauxtime:0,

                      log_allTime: 0,
                      c_i_availtime_corrector: 0,
                      v_c_t_i_auxouttimecontactingtime : v_c_t_i_auxouttimecontactingtime 
                      
                  };
                  
                  


                    /*let presencias = {}
                    for(let au=0; au<this.allPresences.length; au++){
                      //presencias['ti_auxtime_'+this.allPresences[au].languageLabels.en_US] = 0
                      if(data.metrics?.some(metric => metric?.metric === 'tOrganizationPresence' && metric.qualifier === this.allPresences[au].id)){
                        presencias['ti_auxtime_'+this.allPresences[au].languageLabels.en_US] = parseFloat(data.metrics.reduce((total, metric) => {
                            const sumAuxTime = metric.metric === 'tOrganizationPresence' && metric.qualifier === this.allPresences[au].id ? metric.stats?.sum || 0 : 0;
                            return total + sumAuxTime;
                        }, 0) / 1000).toFixed(3)
                      }else{
                        presencias['ti_auxtime_'+this.allPresences[au].languageLabels.en_US] = 0
                      }
                    }*/

                    /*let presencias = {};

                      for (let au = 0; au < this.allPresences.length; au++) {
                        // Obtener la presencia actual
                        let presence = this.allPresences[au];
                        let presenceId = presence.id;
                        let presenceLabel = presence.languageLabels.en_US;
                        let presenceName = presence.systemPresence;  // Asumiendo que el nombre del estado está en systemPresence

                        // Verificar si el estado es "ON_QUEUE" o "On Queue"
                        if (presenceName === "ON_QUEUE" || presenceName === "On Queue") {
                          presencias['ti_auxtime_' + presenceLabel] = this.contarI_AvailTimeUnicos(itemsForQueues)<=1? this.sumarI_AvailTimeUnicos(itemsForQueues) : ((v_c_countAllAvailableTime/1000)-(v_c_t_acdtime+v_c_t_acwtime_onQueue+v_c_t_ringtime_onQueue+v_c_t_holdtime_onQueue));
                          continue;  // Pasar a la siguiente iteración del bucle
                        }

                        // Filtrar las métricas para la presencia actual
                        let totalPresenceTime = data.metrics.reduce((total, metric) => {
                          return metric.metric === 'tOrganizationPresence' && metric.qualifier === presenceId ? total + (metric.stats?.sum || 0) : total;
                        }, 0);

                        // Buscar el tiempo interceptado correspondiente a esta presencia
                        let interceptedTimeObject = interceptedTimesV?.find(item => item.organizationPresenceId === presenceId);
                        let interceptedTime = interceptedTimeObject ? interceptedTimeObject.totalIntersectedTime: 0;

                        // Calcular el tiempo ajustado
                        let adjustedTime = (totalPresenceTime - interceptedTime) / 1000;

                        // Asegurarse de que el tiempo ajustado no sea negativo
                        adjustedTime = Math.max(0, adjustedTime);

                        // Almacenar el tiempo ajustado en el objeto presencias
                        presencias['ti_auxtime_' + presenceLabel] = adjustedTime.toFixed(3);
                      }*/

                      /*
                      let presencias = {};

                      for (let au = 0; au < this.allPresences.length; au++) {
                        // Obtener la presencia actual
                        let presence = this.allPresences[au];
                        let presenceId = presence.id;
                        let presenceLabel = presence.languageLabels.en_US;
                        let presenceName = presence.systemPresence;  // Asumiendo que el nombre del estado está en systemPresence

                        // Verificar si el estado es "ON_QUEUE" o "On Queue"
                        if (presenceName === "ON_QUEUE" || presenceName === "On Queue") {
                          presencias['ti_auxtime_' + presenceLabel] = this.contarI_AvailTimeUnicos(itemsForQueues)<=1? this.sumarI_AvailTimeUnicos(itemsForQueues) : ((v_c_countAllAvailableTime/1000)-(v_c_t_acdtime+v_c_t_acwtime_onQueue+v_c_t_ringtime_onQueue+v_c_t_holdtime_onQueue));
                          continue;  // Pasar a la siguiente iteración del bucle
                        }

                        // Filtrar las métricas para la presencia actual
                        let totalPresenceTime = data.metrics.reduce((total, metric) => {
                          return metric.metric === 'tOrganizationPresence' && metric.qualifier === presenceId ? total + (metric.stats?.sum || 0) : total;
                        }, 0);

                        // Buscar el tiempo interceptado correspondiente a esta presencia
                        let interceptedTimeObject = interceptedTimesV?.find(item => item.organizationPresenceId === presenceId);
                        let interceptedTime = interceptedTimeObject ? interceptedTimeObject.totalIntersectedTime: 0;

                        // Calcular el tiempo ajustado
                        let adjustedTime = (totalPresenceTime - interceptedTime) / 1000;

                        // Asegurarse de que el tiempo ajustado no sea negativo
                        adjustedTime = Math.max(0, adjustedTime);

                        // Almacenar el tiempo ajustado en el objeto presencias
                        presencias['ti_auxtime_' + presenceLabel] = adjustedTime.toFixed(3);
                      }
                      */

                      let presencias = {};
let totalInterceptedTimeSum = 0;
let totalAdjustedTimeSum = 0;
const prevTIauxtime = v_c_t_total_auxtime; // Suma total previa de tiempos ajustados

for (let au = 0; au < this.allPresences.length; au++) {
    let presence = this.allPresences[au];
    let presenceId = presence.id;
    let presenceLabel = presence.languageLabels.en_US;
    let presenceName = presence.systemPresence;

    if (presenceName === "ON_QUEUE" || presenceName === "On Queue" || presenceName === "OFFLINE" || presenceName === "Offline") {
        presencias['ti_auxtime_' + presenceLabel] = (v_c_countAllAvailableTime / 1000) - (v_c_t_acdtime + v_c_t_acwtime_onQueue + v_c_t_ringtime_onQueue + v_c_t_holdtime_onQueue) < 0 
            ? 0.000 
            : ((v_c_countAllAvailableTime / 1000) - (v_c_t_acdtime + v_c_t_acwtime_onQueue + v_c_t_ringtime_onQueue + v_c_t_holdtime_onQueue)).toFixed(3);
        continue;
    }

    let totalPresenceTime = data.metrics.reduce((total, metric) => {
        return metric.metric === 'tOrganizationPresence' && metric.qualifier === presenceId ? total + (metric.stats?.sum || 0) : total;
    }, 0);

    totalPresenceTime = Math.max(0, totalPresenceTime);  // Asegurar que totalPresenceTime no sea negativo

    let interceptedTimeObject = interceptedTimesV?.find(item => item.organizationPresenceId === presenceId);
    let interceptedTime = interceptedTimeObject ? interceptedTimeObject.totalIntersectedTime : 0;

    totalInterceptedTimeSum += interceptedTime;

    let adjustedTime = (totalPresenceTime - interceptedTime) / 1000;
    adjustedTime = Math.max(0, adjustedTime);  // Asegurar que adjustedTime no sea negativo antes de asignarlo

    totalAdjustedTimeSum += adjustedTime;

    presencias['ti_auxtime_' + presenceLabel] = adjustedTime.toFixed(3);
}

let comparisonResult = prevTIauxtime - totalAdjustedTimeSum;
const epsilon = 0.0001;

if ((comparisonResult > 0) || (Math.abs(comparisonResult) > epsilon) || (comparisonResult < 0 && totalInterceptedTimeSum === 0) || (comparisonResult < 0 && totalInterceptedTimeSum > 0)) {
    let filteredPresences = Object.entries(presencias)
        .filter(([key, value]) => 
            parseFloat(value) > 0 && 
            !(key.includes("ON_QUEUE") || key.includes("On Queue") || key.includes("OFFLINE") || key.includes("Offline"))
        )
        .map(([key, value]) => ({ key, value: parseFloat(value) }));

    let totalFilteredValue = filteredPresences.reduce((acc, curr) => acc + curr.value, 0);

    filteredPresences.forEach(presence => {
        let proportion = presence.value / totalFilteredValue;
        let adjustment = proportion * comparisonResult;

        let newValue = parseFloat(presencias[presence.key]) + adjustment;
        presencias[presence.key] = Math.max(0, newValue).toFixed(3); // Asegurar que no sea negativo
    });
}

                      /*if (comparisonResult > 0 || (comparisonResult < 0 && totalInterceptedTimeSum === 0)) {
                          let filteredPresences = Object.entries(presencias)
                              .filter(([key, value]) => parseFloat(value) > 0 && !(key.includes("ON_QUEUE") || key.includes("On Queue")))
                              .map(([key, value]) => ({ key, value: parseFloat(value) }));

                          let totalFilteredValue = filteredPresences.reduce((acc, curr) => acc + curr.value, 0);

                          filteredPresences.forEach(presence => {
                              let proportion = presence.value / totalFilteredValue;
                              let adjustment = proportion * comparisonResult;

                              let newValue = parseFloat(presencias[presence.key]) + (totalAdjustedTimeSum < 0 ? -adjustment : adjustment);
                              presencias[presence.key] = Math.max(0, newValue).toFixed(3); // Asegurar que no sea negativo
                          });
                      }*/
                      
                     
                      // Uso de las variables acumuladas totalInterceptedTimeSum y totalAdjustedTimeSum según necesidad.



                  this.flattenedArrayExport.push({
                      id:0,
                      cms: userInfo?.division?.name,
                      row_date: intervalUTCA,
                      starttime: startTimePA,
                      starttime_utc:0,
                      intrvl:0,
                      acd:0,
                      split_queue_skill:'',
                      extension:0,
                      logid:logidA,
                      loc_id:0,
                      //ti_stafftime:(v_c_countAllAvailableTime/1000)+(v_c_countAllAuxtime/1000),
                      ti_stafftime: sumadorStaffUnicos > 0 ? Math.min(1800, (v_c_countAllStafftime/1000)):0,
                      i_stafftime:0,
                      i_availtime:0,
                      
                      //ti_availtime: ((v_c_countAllAvailableTime/1000)-(v_c_t_acdtime+v_c_t_acwtime_onQueue+v_c_t_ringtime_onQueue+v_c_t_holdtime_onQueue))>0?(v_c_countAllAvailableTime/1000)-(v_c_t_acdtime+v_c_t_acwtime_onQueue+v_c_t_ringtime_onQueue+v_c_t_holdtime_onQueue):0.000,
                      //ti_availtime: ((v_c_countAllAvailableTime/1000)-(v_c_t_acdtime+v_c_t_acwtime_onQueue+v_c_t_ringtime_onQueue+v_c_t_holdtime_onQueue))>0?((v_c_countAllAvailableTime/1000)-(v_c_t_acdtime+v_c_t_acwtime_onQueue+v_c_t_ringtime_onQueue+v_c_t_holdtime_onQueue)):0.000,
                      ///ti_availtime: this.contarI_AvailTimeUnicos(itemsForQueues)<=1? this.sumarI_AvailTimeUnicos(itemsForQueues) : ((v_c_countAllAvailableTime/1000)-(v_c_t_acdtime+v_c_t_acwtime_onQueue+v_c_t_ringtime_onQueue+v_c_t_holdtime_onQueue)),
                      //ti_availtime: v_c_t_total_availtime,
                      ti_availtime: sumadorAvailtimeUnicos > 0 ? sumadorAvailtimeUnicos:0,
                      //ti_availtime_IDLE: (v_c_countAllAvailableTime_IDLE/1000) ||0,
                      //ti_availtime: ((v_c_countAllAvailableTime/1000)-(v_c_t_acdtime+v_c_t_acwtime_onQueue+v_c_t_ringtime_onQueue+v_c_t_holdtime_onQueue)),
                      

                      i_acdtime:0,
                      i_acwtime:0,
                      i_acwouttime:0,
                      i_acwintime:0,
                      
                      //ti_auxtime: this.contarIAuxtimeUnicos(itemsForQueues)<=1? this.sumarIAuxtimeUnicos(itemsForQueues): this.sumarIAuxtimeUnicos(itemsForQueues)>v_c_countAllAuxtime/1000? v_c_countAllAuxtime/1000: (this.sumarIAuxtimeUnicos(itemsForQueues)<=v_c_countAllAuxtime/1000 && this.sumarIAuxtimeUnicos(itemsForQueues)>0)? this.sumarIAuxtimeUnicos(itemsForQueues):0,
                      //ti_auxtime:((v_c_countAllAuxtime/1000)-(v_c_t_acdtime_Aux+v_c_t_acwtime_Aux+v_c_t_holdtime_aux))>0?(v_c_countAllAuxtime/1000)-(v_c_t_acdtime_Aux+v_c_t_acwtime_Aux+v_c_t_holdtime_aux):0.000,
                      //ti_auxtime: ((v_c_countAllAuxtime/1000)-(v_c_t_acdtime_Aux+v_c_t_acwtime_Aux+v_c_t_holdtime_aux))>0?((v_c_countAllAuxtime/1000)-(v_c_t_acdtime_Aux+v_c_t_acwtime_Aux+v_c_t_holdtime_aux)):0.000,
                      //ti_auxtime:this.contarIAuxtimeUnicos(itemsForQueues)<=1? this.sumarIAuxtimeUnicos(itemsForQueues) : (v_c_countAllAuxtime/1000)-(v_c_t_acdtime_Aux+v_c_t_acwtime_Aux+v_c_t_holdtime_aux),
                      //ti_auxtime: v_c_t_total_auxtime,
                      ti_auxtime: sumadorAuxtimeUnicos > 0 ? sumadorAuxtimeUnicos:0,
                      i_auxouttime: v_c_t_i_auxouttime,
                      i_auxtime:0,
                      i_auxintime:v_c_t_i_auxintime,
                    
                      i_othertime:0,
                      
                      ti_othertime: sumadorOthertimeUnicos,  
                      i_acdaux_outtime:0,
                      i_acdauxintime:0,
                      i_ringtime:0,
                      i_ringcalls:0,
                      ringtime:0,
                      ringcalls:0,
                      acwoutcalls:0,
                      acwouttime:0,
                      auxoutcalls: v_c_t_i_auxoutcalls,
                      auxouttime: v_c_t_i_auxouttime,
                      acwauxouttime: v_c_t_i_acwauxouttime,
                      acdcalls:0,
                      acdtime:0,
                      acwtime:0,
                      o_acdcalls:0,
                      o_acdtime:0,
                      o_acwtime:0,
                      holdcalls:0,
                      holdtime:0,
                      holdabncalls:0,
                      transferred:0,
                      transferredtime:0,
                      conference:0,
                      abncalls:0,
                      abntime:0,
                      ansringtime:0,
                      acdauxoutcalls:0,
                      phantomabns:0,
                      holdacdtime:0,
                      acd_release:0,
                      auxoutoffcalls:0,
                      auxoutofftime:0,
                      ...presencias,
                      totalInterceptedTimeSum: totalInterceptedTimeSum,
                      totalInterceptedTimeSumSeg: totalInterceptedTimeSum/1000,
                      totalAdjustedTimeSum: totalAdjustedTimeSum,
                      comparisonResult:comparisonResult,
                      c_i_acdtime_onQueue: 0,
                      c_i_acdtime_aux: 0,
                      c_i_acwtime_onQueue: 0,
                      c_i_acwtime_aux: 0,
                      c_i_holdtime_onQueue: 0,
                      c_i_holdtime_aux: 0,
                      c_i_ringtime: 0,
                      c_i_ringtime_aux: 0,
                      c_i_acwauxtime:0,

                      c_i_othertime_acdtime: 0,
                      c_i_othertime_acdtime_aux: 0,
                      c_i_othertime_acwtime: 0,
                      c_i_othertime_holdtime: 0,
                      c_i_othertime_holdtime_auxtime: 0,
                      c_i_othertime_ringtime: 0,
                      c_i_othertime_ringtime_aux: 0,
                      c_i_othertime_auxouttime: 0,
                      c_i_othertime_auxintime: 0,
                      c_i_othertime_NONacd_aux: 0,
                      c_i_othertime_NONacw_aux:0,
                      c_i_othertime_acwtime_aux:0,
                      log_allTime: 0,
                      c_i_availtime_corrector: v_c_i_availtime_corrector,
                      c_i_availtime_corrector_v: v_c_i_availtime_corrector_v,
                      c_countAllStafftime_log: 0,
                      c_i_auxouttime_onQueue: 0,
                      i_auxtime_log: 0,
                      log_allTimeLog: 0,
                      c_i_auxtime_corrector:0,
                      c_i_auxtime_corrector_v:0,
                      v_c_t_i_acwauxouttime: v_c_t_i_acwauxouttime,
                      v_c_t_i_auxouttimecontactingtime: v_c_t_i_auxouttimecontactingtime,
                      v_c_t_total_auxtime:v_c_t_total_auxtime,
                      v_c_t_total_availtime:v_c_t_total_availtime,
                      v_c_t_othertime_c: v_c_t_othertime_c,
                      v_c_t_othertime:v_c_t_othertime,

                      log_audit_exist_memberAdd: '',
                      log_audit_exist_memberRemove: '',



                      log_v_c_t_acdtime:log_v_c_t_acdtime,
                      log_v_c_t_acdtime_Aux:log_v_c_t_acdtime_Aux,
                      log_v_c_t_acwtime_onQueue:log_v_c_t_acwtime_onQueue,
                      log_v_c_t_acwtime_Aux:log_v_c_t_acwtime_Aux,
                      log_v_c_t_ringtime_onQueue:log_v_c_t_ringtime_onQueue,
                      log_v_c_t_holdtime_onQueue:log_v_c_t_holdtime_onQueue,
                      log_v_c_t_holdtime_aux:log_v_c_t_holdtime_aux,
                      log_v_c_t_i_auxouttime:log_v_c_t_i_auxouttime,
                      log_v_c_t_i_auxintime:log_v_c_t_i_auxintime,

                      v_c_i_availtime_corrector:v_c_i_availtime_corrector,
                      v_c_i_availtime_corrector_v:v_c_i_availtime_corrector_v,
                      log_conversationIds: '',
                      
                      

                    })

                  return [...itemsForQueues, additionalItem];
              });
              return acc.concat(newData);
          }
          return acc;
      }, []);

      /*this.flattenedArray = this.flattenedArray.map((item, index) => {
        return {
          ...item,
          id: index
        };
      });*/

      this.flattenedArrayExport = this.flattenedArrayExport.map((item, index) => {
        return {
          ...item,
          id: index
        };
      });

      console.log('Reporte concluido')

      console.log(this.flattenedArray[2360])
      console.log(this.flattenedArray[4026])
      console.log(this.flattenedArray[6053])
      console.log(this.flattenedArray[6095])
      console.log(this.flattenedArray[12632])
      console.log('//////////////////////////////')

      console.log(this.flattenedArray[1625])
      console.log(this.flattenedArray[1631])
      console.log(this.flattenedArray[1637])
      console.log(this.flattenedArray[6095])


      console.log('//////////////////////////////')

      console.log('//////////////////////////////')
      console.log(this.allQueueWithMembers)






      }catch (error) {
        console.error(error)
      }

      this.maxPages = Math.ceil(this.flattenedArray.length / this.rowsPerPage);
    },

    roundDownToNearestHalfHour(date) {
      const minutes = date.getMinutes();
      const roundedMinutes = minutes < 30 ? 0 : 30;
      date.setMinutes(roundedMinutes, 0, 0);
      return date;
    },

    // Función para agregar 30 minutos a una fecha
    addThirtyMinutes(date) {
      return new Date(date.getTime() + 30 * 60 * 1000);
    },

    // Función para calcular la diferencia en minutos entre dos fechas
    differenceInMinutes(start, end) {
      return (end - start) / (1000 * 60);
    },

    async getAllUserActivityPresence22s() {
      try {
        const response = await axios.get(this.sourceUserPresences)
        //const response = await axios.get('https://connector.esmtcx.solutions/custom/c/9hya7xqpz6mj2t1v/?init=2024-05-16&end=2024-05-16')

        this.allUserPresences = response.data.data[0].data.userDetails

        console.log(this.allUserPresences)

        const results = [];

        this.allUserPresences.forEach(user => {
          user.primaryPresence.forEach(presence => {
            let startTime = new Date(presence.startTime);
            const endTime = new Date(presence.endTime);
            const queues = this.findUserInQueues(user.userId) || ["default_queue"]; // Asumimos que cada presencia tiene un array de colas

            while (startTime < endTime) {
              const segmentStart = this.roundDownToNearestHalfHour(new Date(startTime));
              const segmentEnd = this.addThirtyMinutes(new Date(segmentStart));

              queues.forEach(queue => {
                const segment = {
                  userId: user.userId,
                  queue: queue,
                  startTime: segmentStart.toISOString(),
                  endTime: segmentEnd.toISOString(),
                  systemPresence: presence.systemPresence,
                  organizationPresenceId: presence.organizationPresenceId,
                  subPresence: presence.subPresence,
                  duration: 0
                };

                if (segmentEnd < endTime) {
                  segment.duration = this.differenceInMinutes(startTime, segmentEnd);
                  startTime = segmentEnd;
                } else {
                  segment.duration = this.differenceInMinutes(startTime, endTime);
                  startTime = endTime;
                }

                results.push(segment);
              });
            }
          });
        });
      } catch (error) {
        console.error(error)
      }
    }
    
  },
  mounted() {
    if(this.org==='cc'){
      console.log('Entramos a Cocacola')
      
      this.msgOrg = 'CocaCola'
      this.reports =['2024-06-01','2024-06-02','2024-06-03','2024-06-04','2024-06-05','2024-06-06','2024-06-07','2024-06-08','2024-06-09','2024-06-10','2024-06-11','2024-06-12','2024-06-13','2024-06-14','2024-06-15','2024-06-16','2024-06-17','2024-06-18','2024-06-19','2024-06-20','2024-06-21','2024-06-22','2024-06-23','2024-06-24','2024-06-25','2024-06-26','2024-06-27','2024-06-28','2024-06-29','2024-06-30','2024-07-01','2024-07-02','2024-07-03','2024-07-04','2024-07-05','2024-07-06','2024-07-07','2024-07-08','2024-07-09','2024-07-10','2024-07-11','2024-07-12','2024-07-13','2024-07-14','2024-07-15','2024-07-16','2024-07-17','2024-07-18','2024-07-19','2024-07-20','2024-07-21','2024-07-22','2024-07-23','2024-07-24','2024-07-25','2024-07-26','2024-07-27','2024-07-28','2024-07-29','2024-07-30','2024-07-31','2024-08-01','2024-08-02','2024-08-03','2024-08-04','2024-08-05','2024-08-06','2024-08-07','2024-08-08','2024-08-09','2024-08-10','2024-08-11','2024-08-12','2024-08-13','2024-08-14','2024-08-15','2024-08-16','2024-08-17','2024-08-18','2024-08-19','2024-08-20','2024-08-21','2024-08-22','2024-08-23','2024-08-24','2024-08-25','2024-08-26','2024-08-27','2024-08-28','2024-08-29','2024-08-30','2024-08-31']
    }else if(this.org==='ac'){
      console.log('Entramos a Arcos Dorados')
      
      this.msgOrg = 'ArcosDorados'
      this.reports =['2024-06-01','2024-06-02','2024-06-03','2024-06-04','2024-06-05','2024-06-06','2024-06-07','2024-06-08','2024-06-09','2024-06-10','2024-06-11','2024-06-12','2024-06-13','2024-06-14','2024-06-15','2024-06-16','2024-06-17','2024-06-18','2024-06-19','2024-06-20','2024-06-21','2024-06-22','2024-06-23','2024-06-24','2024-06-25','2024-06-26','2024-06-27','2024-06-28','2024-06-29','2024-06-30','2024-07-01','2024-07-02','2024-07-03','2024-07-04','2024-07-05','2024-07-06','2024-07-07','2024-07-08','2024-07-09','2024-07-10','2024-07-11','2024-07-12','2024-07-13','2024-07-14','2024-07-15','2024-07-16','2024-07-17','2024-07-18','2024-07-19','2024-07-20','2024-07-21','2024-07-22','2024-07-23','2024-07-24','2024-07-25','2024-07-26','2024-07-27','2024-07-28','2024-07-29','2024-07-30','2024-07-31','2024-08-01','2024-08-02','2024-08-03','2024-08-04','2024-08-05','2024-08-06','2024-08-07','2024-08-08','2024-08-09','2024-08-10','2024-08-11','2024-08-12','2024-08-13','2024-08-14','2024-08-15','2024-08-16','2024-08-17','2024-08-18','2024-08-19','2024-08-20','2024-08-21','2024-08-22','2024-08-23','2024-08-24','2024-08-25','2024-08-26','2024-08-27','2024-08-28','2024-08-29','2024-08-30','2024-08-31']
    }else{
      this.msg = 'Sin Datos'
      return false
    }
    
    //this.getAllGenerals()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.table-fixed-header thead {
  position: sticky;
  top: 0;
  background-color: #fff; /* Asegúrate de que el encabezado no sea transparente */
  z-index: 10; /* Asegúrate de que el encabezado esté en la parte superior */
}

.table-fixed-header {
  height: 95%;
}
</style>
