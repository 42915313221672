import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'
import MultiSkill from '../views/MultiskillView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: MultiSkill,
    props: (route) => ({ org: route.query.org })
  },
  {
    path: '/multiskill/org/:org',
    component: MultiSkill,
    props: true,
    children: [
        {
            path: '',  // Ruta por defecto sin parámetro adicional
            name: 'multiskill',
            component: MultiSkill
        },
        {
            path: 'range/:range',  // Subruta que incluye el parámetro 'range'
            name: 'multiskillWithRange',
            component: MultiSkill,
            props: true
        }
    ]
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
